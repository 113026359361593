import React from "react"
import { useTranslation } from "react-i18next"
import { generatePath } from "react-router"
import { RoutePaths } from "../../../../app/routes/Routes"
import NameUser from "../../../../shared/components/names/NameUser"
import TextLink from "../../../../shared/components/text/TextLink"
import { IconLock } from "../../../../svg/icons"
import { ITaskCase } from "../../../tasks/state/tasksTypes"
import { ICase } from "../../state/caseTypes"

interface IProps {
  case: ICase | ITaskCase
  hasLink?: boolean
}

const defaultProps = {
  hasLink: true
}

const PatientNameWithLock: React.FC<IProps> = (props: IProps): JSX.Element => {
  if (!props.case) return null

  const { t } = useTranslation(["case"])

  const caseDetailsUrl = generatePath(RoutePaths.CASE, {
    caseId: props.case.id
  })

  const patientNameText = (
    <NameUser
      firstName={props.case.firstName}
      lastName={props.case.lastName}
      title={props.case.title}
      gender={props.case.gender}
    />
  )

  const renderText = (): JSX.Element => {
    if(!props.hasLink) return patientNameText
    return(
      <TextLink href={caseDetailsUrl}>
        {patientNameText}
      </TextLink>
    )
  }

  const renderLockIcon = (): JSX.Element => {
    if(!props.case.locked) return null
    return(
      <div className="text-ch-purple-500 ml-2">
        <IconLock className="w-4 h-4" title={t("case:lockedIconTitle")} />
      </div>
    )
  }

  return (
    <li>
      <h4 className="font-semibold">
        { renderText() }
      </h4>
      {renderLockIcon()}
    </li>
  )
}

PatientNameWithLock.defaultProps = defaultProps

export default PatientNameWithLock
