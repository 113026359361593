import { createContext } from "react"
import { ITenantConfigContextState } from "../state/tenantConfigTypes"

export const tenantConfigContextInitialState: ITenantConfigContextState = {
  tenantName: null,
  tenantLogoPath: null,
  healthcareIdLabel: "NHS Number",
  careProviderIdLabel: "Hospital Number",
  healthcareIdRequired: false,
  careProviderIdRequired: false,
  signedOffByRequired: false,
  messagingEnabled: false,
  messagingNotificationsEnabled: false,
  changeTrackingEnabled: false,
  pathwaysEnabled: false,
  analyticsEnabled: false,
  radiologyEnabled: false,
  mdtRoles: [],
  hideMenuItemUsers: true
}

export const TenantConfigContext = createContext(tenantConfigContextInitialState)
