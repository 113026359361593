import React, { RefObject } from "react"
import { useTranslation } from "react-i18next"
import { IUser } from "../../../user/state/userTypes"
import { MockStore } from "redux-mock-store"
import { IconFullscreen } from "../../../../svg/icons"

interface IPropsFromParent {
  store?: MockStore
  presenter: IUser
  fullScreenRef: RefObject<HTMLDivElement>
}

type IProps = IPropsFromParent

const LiveMeetingSharedViewBar: React.FC<IProps> = ({
  presenter, fullScreenRef
}: IProps): JSX.Element => {
  const { t } = useTranslation(["liveMeeting"])

  const renderFullScreenButton = () => {
    if (!fullScreenRef?.current) return null

    return (
      <button
        className="flex gap-3 uppercase items-center mx-3 my-auto px-3 rounded-full text-ch-gray-300 h-7 leading-none text-white bg-ch-blue-500"
        onClick={() => fullScreenRef.current.requestFullscreen()}>

        {t("liveMeeting:fullscreen")}<IconFullscreen />
      </button >
    )
  }

  const presentingUser = () => {
    if (!presenter) return null

    return (
      <span className="text-sm font-thin">
        &nbsp;|&nbsp;{t("liveMeeting:presenting")} {presenter.firstName}{" "}
        {presenter.lastName}{" "}
      </span>
    )
  }

  return (
    <div className="bg-ch-blue-200 flex w-full place-content-between">
      <h2 className=" p-2">
        {t("liveMeeting:sharedView")}
        {presentingUser()}
      </h2>
      {renderFullScreenButton()}
    </div>
  )
}

export default LiveMeetingSharedViewBar
