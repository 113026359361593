import { takeEvery, put, call } from "redux-saga/effects"
import { WebRTCActionTypes } from "../webRTCTypes"
import { subscribeToStream } from "../../../../apis/webRTC/webRTC"
import { handleError } from "../../../../shared/helpers/sagaHelpers"

export function* subscribeToWebRTCStreamAsync(action) {
  try {
    const subscriber: OT.Subscriber = yield call(subscribeToStream, action.payload.session, action.payload.stream, action.payload.containerRef, action.payload.options)

    yield put({
      type: WebRTCActionTypes.SUBSCRIBE_TO_WEB_RTC_STREAM_ASYNC_SUCCESS,
      payload: subscriber
    })
  } catch (error) {
    yield handleError(WebRTCActionTypes.SUBSCRIBE_TO_WEB_RTC_STREAM_ASYNC_ERROR, error, "subscribeToWebRTCStreamError")
  }
}

export function* watchSubscribeToWebRTCStreamAsync() {
  yield takeEvery(WebRTCActionTypes.SUBSCRIBE_TO_WEB_RTC_STREAM_ASYNC_PENDING, subscribeToWebRTCStreamAsync)
}
