import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../app/appTypes"
import { fetchUsersAsync } from "../../features/users/state/usersActions"
import { Trans, useTranslation } from "react-i18next"
import Pagination from "../../shared/components/search/Pagination"
import UserList from "../../features/users/components/list/UserList"
import { RoutePaths } from "../../app/routes/Routes"
import { getUsersUI } from "../../shared/selectors/ui"
import { getCurrentUser } from "../../shared/selectors/user"
import IUsersUIState, { UserOrderByOptions } from "../../features/users/state/usersTypes"
import PageTitle, { TitleButtonFactory } from "../../shared/components/ui/PageTitle"
import SearchInput from "../../shared/components/search/SearchInput"
import ResultsPerPage from "../../shared/components/search/ResultsPerPage"
import OrderBy from "../../shared/components/search/OrderBy"
import useSearch, { ISearchQuery } from "../../shared/hooks/useSearch"
import { IUser } from "../../features/user/state/userTypes"
import UserFilter from "../../features/users/components/search/UserFilter"
import { getTenantConfig } from "../../shared/selectors/tenantConfig"

const UsersPage = (): JSX.Element => {
  const { t } = useTranslation(["shared", "user"])
  const currentUser: IUser = useSelector((state: IAppState) => getCurrentUser(state))
  const UI: IUsersUIState = useSelector((state: IAppState) => getUsersUI(state))
  const { guestUsersEnabled } = useSelector(getTenantConfig)
  const initialSearchQuery: ISearchQuery = {
    page: 1,
    searchTerm: UI.searchTerm,
    resultsPerPage: UI.resultsPerPage,
    orderBy: UI.orderBy,
    appliedFilters: UI.appliedFilters
  }
  const [searchQuery, setSearchQuery] = useSearch(initialSearchQuery, fetchUsersAsync)
  const guestUserButton = () => {
    if (!guestUsersEnabled) return null
    return TitleButtonFactory(RoutePaths.USER_NEW_GUEST, t("user:addANewGuestUser"), 2)
  }

  return (
    <div>
      <PageTitle
        pageTitle={t("shared:users")}
        buttonText={currentUser.abilities?.user.canCreate ? t("user:addANewUser") : ""}
        buttonHref={RoutePaths.USER_NEW}
        hasButtonPermission={currentUser.abilities?.user.canCreate}
    secondaryButton={guestUserButton()}
      />
      <div className="grid grid-rows-2 md:grid-rows-1 flex items-stretch">
        <div className="row-start-2 md:row-start-1 self-center">
          <Trans
            i18nKey="user:searchBy"
            components={{
              strong: <strong />
            }}
          />
        </div>
        <div className="row-end-2 md:row-start-1 flex justify-end">
          <ResultsPerPage
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
      </div>
      <div className="mb-2">
        <SearchInput
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          buttonText={t("user:searchUser")}
        />
      </div>
      <h2 className="text-lg mb-2">
        {UI.totalUsersCount} {t("shared:users")}
      </h2>
      <div className="grid grid-flow-row md:grid-flow-col">
        <div>
          <UserFilter
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
        <div className="flex justify-end">
          <OrderBy
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            orderByOptions={UserOrderByOptions}
            useTranslation={"user"}
          />
        </div>
      </div>
      <UserList />
      <Pagination
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        totalItems={UI.totalUsersCount}
        totalPages={UI.totalPages}
      />
    </div>
  )
}

export default UsersPage
