import React, { ReactNode } from "react"
import classNames from "classnames"
import IconCircleContainer from "../icons/IconCircleContainer"
import { IconUser } from "../../../svg/icons"
import UserDetails from "../../../features/user/components/details/UserDetails"
import { IUser } from "../../../features/user/state/userTypes"

interface IProps {
  user: IUser
  iconTitle?: string
  nameLabel?: ReactNode
  label?: ReactNode
}

const UserDetailsCard: React.FC<IProps> = (props: IProps): JSX.Element => {
  return (
    <div className={classNames("flex flex-1 items-center", { "text-ch-gray-500": !props.user.activeInCurrentTenant })}>
      <IconCircleContainer className="mr-4" colour={props.user.activeInCurrentTenant ? null : "text-ch-gray-500"} >
        <IconUser className="fill-current w-6 h-6" />
      </IconCircleContainer>

      <div className="flex-1">
        <UserDetails userId={props.user.id} nameLabel={props.nameLabel} label={props.label} />
      </div>
    </div>
  )
}

export default UserDetailsCard
