import { takeLeading, put, call, Effect } from "redux-saga/effects"
import { normalize } from "normalizr"
import { AssetsActionTypes } from "../assetsTypes"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import API from "../../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { assetsSchema } from "../../../../apis/contextmeeting/schema"
import { fetchMeetingCaseAction } from "../../../meetingCase/state/meetingCaseActions"

export function* createRecommendationAsync(action: Effect): Generator {
  try {
    const caseId = action.payload.caseId
    const data = {
      recommendation: action.payload.recommendation
    }

    const response: any = yield call(API.post, `/cases/${caseId}/recommendations`, data, withAuthHeader())

    const normalizedResponse = normalize(response.data.all, [assetsSchema])

    yield put({
      type: AssetsActionTypes.CREATE_CASE_RECOMMENDATION_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        caseId: caseId
      }
    })

    if (action.payload.meetingCaseId) {
      yield put(fetchMeetingCaseAction(action.payload.meetingCaseId))
    }

    yield put(displayUserMessageAction({
      messageKey: "createRecommendationSuccess",
      type: UserMessageTypes.SUCCESS
    }))
  } catch (error) {
    yield put({
      type: AssetsActionTypes.CREATE_CASE_RECOMMENDATION_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "createRecommendationFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchCreateRecommendationAsync(): Generator {
  yield takeLeading(AssetsActionTypes.CREATE_CASE_RECOMMENDATION_ASYNC_PENDING, createRecommendationAsync)
}

export function* saveCaseRecommendationAsync(action: Effect): Generator {
  try {
    const recommendationId = action.payload.recommendationId

    const response: any = yield call(API.put, `/recommendations/${recommendationId}/save`, null, withAuthHeader())

    const normalizedResponse = normalize(response.data.all, [assetsSchema])

    yield put({
      type: AssetsActionTypes.SAVE_CASE_RECOMMENDATION_ASYNC_SUCCESS,
      payload: {
        caseId: action.payload.caseId,
        all: normalizedResponse,
      }
    })

    yield put(displayUserMessageAction({
      messageKey: "saveRecommendationSuccess",
      type: UserMessageTypes.SUCCESS
    }))
  } catch (error) {
    yield put({
      type: AssetsActionTypes.SAVE_CASE_RECOMMENDATION_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "createRecommendationFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchSaveCaseRecommendationAsync(): Generator {
  yield takeLeading(AssetsActionTypes.SAVE_CASE_RECOMMENDATION_ASYNC_PENDING, saveCaseRecommendationAsync)
}
