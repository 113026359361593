import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { MockStore } from "redux-mock-store"
import classNames from "classnames"
import { IAppState } from "../../../../app/appTypes"
import { getAssetById } from "../../../../shared/selectors/asset"
import { getComponentTypeForAsset, componentLookup, isAssetFiltered, clickableComponentAction } from "../../../../shared/helpers/assetHelpers"
import { IUser } from "../../../user/state/userTypes"
import { getMeetingById } from "../../../../shared/selectors/meeting"
import { IHaveMeetingId, MeetingStatuses } from "../../../meeting/state/meetingTypes"
import IAssetTypeFilters from "../../../../shared/types/AssetTypeFilters"
import { IMeetingCaseAsset } from "../../state/meetingCaseTypes"

interface Props {
  store?: MockStore
  meetingCaseAsset: IMeetingCaseAsset
  assetId: string
  meetingId: string
  meetingCaseId: string
  action?: () => void
  deleteAction?: (assetId: string) => void
  isSelected?: boolean
  caseId: string
  mentionableUsers: { [id: string]: IUser }
  assetTypeFilters: IAssetTypeFilters
}

const MeetingCaseAssetListItem: React.FC<Props> = (props: Props): JSX.Element => {
  const asset = useSelector((state: IAppState) => getAssetById(state, props))
  const meeting = useSelector((state: IAppState) => getMeetingById(state, props as IHaveMeetingId))

  const shouldRender = () => {
    if (!asset) return false
    if (!props.meetingId) return true
    if (!meeting) return true
    if (!(meeting.status == MeetingStatuses.Ended || meeting.status == MeetingStatuses.Cancelled)) return true
    if (asset.asset.meetingId == props.meetingId) return true
    if (new Date(asset.createdAt) < new Date(meeting.endDateActual)) return true
    if (new Date(asset.createdAt) < new Date(meeting.cancelledDate)) return true
    return false
  }

  const [returnEarly, setReturnEarly] = useState(true)
  useEffect(() => {
    setReturnEarly(!shouldRender())
  }, [asset])

  if (returnEarly) return null
  if (!asset) return null

  const assetComponentType = getComponentTypeForAsset(asset)
  const Asset = componentLookup[assetComponentType]
  const action = clickableComponentAction(props.action, assetComponentType)
  // we're hiding the component, not returning null, because this way the
  // image components don't need to reload their images.
  const hidden = isAssetFiltered(Asset, props.assetTypeFilters)

  return (
    <li
      key={props.meetingCaseAsset.id}
      className={classNames("mb-1", { hidden })}>
      <Asset
        assetId={props.assetId}
        action={action}
        isSelected={props.isSelected}
        caseId={props.caseId}
        meetingId={props.meetingId}
        mentionableUsers={props.mentionableUsers}
        meetingCaseAsset={props.meetingCaseAsset}
      />
    </li>
  )
}

export default MeetingCaseAssetListItem
