import React from "react"
import classNames from "classnames"
import { StyleVariants } from "./../../../shared/state/sharedTypes"

interface IProps {
  className?: string
  text1: string
  text2: string
  value: number
  onClick: (value: number) => void
  variant?: string
}

const PillSwitch: React.FC<IProps> = (props: IProps): JSX.Element => {
  let variant = props.variant
  if (!variant) {
    variant = StyleVariants.BLUE
  }

  const defaultClasses = [
    "flex",
    "justify-center",
    "font-thin"
  ]

  const propClasses = props.className ? props.className.split(" ") : []
  const allClasses = [...defaultClasses, ...propClasses].join(" ")

  return (
    <div className={allClasses}>
      <a
        href="#"
        onClick={() => props.onClick(0)}
        className={classNames(
          "text-center",
          "text-white",
          "px-4",
          "py-1",
          "rounded-l-full",
          {
            [`bg-${variant}-500`]: props.value === 0,
            "bg-ch-gray-500": props.value !== 0
          }
        )
        }>
        {props.text1}
      </a>
      <a
        href="#"
        onClick={() => props.onClick(1)}
        className={classNames(
          "text-center",
          "text-white",
          "px-4",
          "py-1",
          "rounded-r-full",
          {
            [`bg-${variant}-500`]: props.value === 1,
            "bg-ch-gray-500": props.value !== 1
          }
        )
        }>
        {props.text2}
      </a>
    </div>
  )
}

export default PillSwitch
