import React, { useContext } from "react"
import { utcDateToDaysFromNow } from "../../helpers/timeHelpers"
import { UserConfigContext } from "../../../features/userConfig/contexts/UserConfigContext"
import { useTranslation } from "react-i18next"

interface IProps {
  utcTime: string
  className?: string
}

const TimeDaysFromNow: React.FC<IProps> = ({
  utcTime,
  className,
}): JSX.Element => {
  const { timezone } = useContext(UserConfigContext)
  const { t } = useTranslation(["shared"])

  if (!utcTime) return null

  const daysFromNow = utcDateToDaysFromNow(utcTime, timezone)

  if (daysFromNow >= 0)
    return (
      <span className={className}>
        ({daysFromNow} {t("shared:daysFromNow")})
      </span>
    )
  return (
    <span className={className}>
      ({-daysFromNow} {t("shared:daysAgo")})
    </span>
  )
}

export default TimeDaysFromNow
