import { Reducer, AnyAction, combineReducers } from "redux"
import _ from "lodash"
import { WebRTCActionTypes, TWebRTCStreamByUserIdState, IWebRTCErrorState } from "../webRTCTypes"

export const byUserIdInitialState: TWebRTCStreamByUserIdState = {}

export const mediaError: Reducer<string> = (state = "", action: AnyAction): string => {
  switch(action.type) {
    case WebRTCActionTypes.PUBLISH_TO_WEB_RTC_SESSION_ASYNC_ERROR:
      return action.payload.name

    default:
      return state
  }
}

export const webRTCErrorReducer: Reducer<IWebRTCErrorState> = combineReducers({
  mediaError
})
