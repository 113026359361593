import React, { useEffect, useState } from "react"
import { Form, Formik } from "formik"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import Button from "../../../../shared/components/buttons/Button"
import { TSelectFieldUserOptions } from "../../../../shared/components/forms/StandardSelectField"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import { buildUserOptions } from "../../../../shared/components/label/UserOption"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import ModalContainer from "../../../../shared/components/modals/ModalContainer"
import { useAppDispatch, useAppSelector } from "../../../../shared/hooks"
import { getTeamById, getTeamUsersByTeamId } from "../../../../shared/selectors/team"
import { getTenantConfig } from "../../../../shared/selectors/tenantConfig"
import { getAllInternalActiveUsersSorted } from "../../../../shared/selectors/user"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { IconTeam } from "../../../../svg/icons"
import MdtTeamUsersFieldArray from "../../../teamNew/components/MdtTeamUsersFieldArray"
import TeamDetails from "../../../teams/components/details/TeamDetails"
import { ITeamUser } from "../../../teamUser/state/teamUserTypes"
import { updateTeamUsersAction } from "../../state/teamActions"
import { IMdtTeamUserData, ITeam } from "../../state/teamTypes"

interface IProps {
  teamId: string
  closeModal: VoidFunction
  createTeamUsers: (values: { users: string[] }, { setSubmitting }: { setSubmitting: VoidFunction }) => void
}

const MdtManageUsersModalContent: React.FC<IProps> = (props) => {
  const { t } = useTranslation(["shared"])
  const team = useAppSelector((state): ITeam => getTeamById(state, props.teamId))
  const { mdtRoles } = useAppSelector(getTenantConfig)
  const teamUsers: ITeamUser[] = useAppSelector((state) => getTeamUsersByTeamId(state, team.id))
  const [userOptions, setUserOptions] = useState<TSelectFieldUserOptions>([])
  const users = useAppSelector(getAllInternalActiveUsersSorted)
  const [teamUsersData, setTeamUsersData] = useState<IMdtTeamUserData[]>([])
  const dispatch = useAppDispatch()
  const updateMdtTeamUsers = (teamUsers: IMdtTeamUserData[]) => dispatch(updateTeamUsersAction(team.id, { teamUsers }))

  useEffect(() => {
    const options: TSelectFieldUserOptions = buildUserOptions(users)
    setUserOptions(options)
  }, [users])


  useEffect(() => {
    if (!team || !teamUsers) return
    const sortedTeamUserData = _.sortBy(teamUsers, (user) => mdtRoles.indexOf(user.role))
    setTeamUsersData(sortedTeamUserData)
  }, [team.teamUsers])

  if (teamUsersData.length == 0) return

  return (
    <ModalContainer handleClose={props.closeModal}>
      <h2 className="text-lg mb-3">{t("team:inviteMembersIntro")}</h2>

      <DetailsCard>
        <DetailsCardInner>
          <IconCircleContainer className="sm:mr-4">
            <IconTeam className="fill-current w-6 h-6" />
          </IconCircleContainer>
          <TeamDetails teamId={team.id} userId={team.owner} />
        </DetailsCardInner>
      </DetailsCard>

      <div className="mb-3">
        <p className="text-ch-blue-600">{t("team:inviteMembersDesc1")}</p>
        <p className="text-ch-blue-600">{t("team:inviteMembersDesc2")}</p>
      </div>

      <div className="w-full">
        <Formik
          initialValues={{ teamUsers: teamUsersData }}
          onSubmit={(values: { teamUsers: IMdtTeamUserData[] }) => {
            updateMdtTeamUsers(values.teamUsers)
            props.closeModal()
          }} >
          {({
            dirty, isSubmitting
          }) => {
            return (
              <Form>
                <MdtTeamUsersFieldArray userOptions={userOptions} />
                <div className="flex justify-center">
                  <Button
                    isDisabled={!dirty || isSubmitting}
                    variant={StyleVariants.ORANGE}
                    isSubmit={true}
                  >
                    {t("shared:save")}
                  </Button>
                </div>
              </Form>
            )
          }
          }
        </Formik>
      </div>
    </ModalContainer>
  )
}

export default MdtManageUsersModalContent
