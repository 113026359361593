import { Reducer, AnyAction, combineReducers } from "redux"
import _ from "lodash"
import { AssetsActionTypes, IAsset } from "../assetsTypes"
import { INormalizedEntities } from "./../../../../app/appTypes"

type TByIdState = { [id: string]: IAsset }
type TAllIdsState = string[]

export const byIdInitialState: TByIdState = {}
export const allIdsInitialState: TAllIdsState = []

export const byId: Reducer<TByIdState> = (state: TByIdState = byIdInitialState, action: AnyAction): TByIdState => {
  switch (action.type) {
    case AssetsActionTypes.FETCH_PATIENT_RADIOLOGY_LINKS_ASYNC_SUCCESS:
      return  action.payload.entities?.patientRadiologyLink || {}

    default:
      return state
  }
}

export const allIds: Reducer<TAllIdsState> = (state: TAllIdsState = allIdsInitialState, action: AnyAction): TAllIdsState => {
  switch (action.type) {
    case AssetsActionTypes.FETCH_PATIENT_RADIOLOGY_LINKS_ASYNC_SUCCESS:
      return action.payload.result || []

    default:
      return state
  }
}

export const patientRadiologyLinkReducer: Reducer<INormalizedEntities<IAsset>> = combineReducers({
  byId,
  allIds
})
