import { AnyAction, Reducer, combineReducers } from "redux"
import { AssetsActionTypes, IPatientRadiologyLinkUIState } from "../assetsTypes"

export const initialState: IPatientRadiologyLinkUIState = {
  fetching: false,
}

export const fetching: Reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case AssetsActionTypes.FETCH_PATIENT_RADIOLOGY_LINKS_ASYNC_PENDING:
      return true
    case AssetsActionTypes.FETCH_PATIENT_RADIOLOGY_LINKS_ASYNC_SUCCESS:
    case AssetsActionTypes.FETCH_PATIENT_RADIOLOGY_LINKS_ASYNC_ERROR:
      return false

    default:
      return state
  }
}

export const patientRadiologyLinkUIReducer: Reducer<IPatientRadiologyLinkUIState> = combineReducers({
  fetching
})
