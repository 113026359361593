import React from "react"

interface IPropsFromParent {
  openText: string
  closedText?: string
}

type Props = IPropsFromParent

const LiveMeetingAssetSelectHeading: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <h3 className="flex items-center">
      {props.openText}
    </h3>
  )
}

export default LiveMeetingAssetSelectHeading
