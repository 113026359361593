// State
export interface IUserConfigContextState {
  locale: string
  timezone: string
  uiSettings: {
    showQuickStartGuide?: boolean
  }
}

export interface IUserConfigEditData {
  locale: string
  timezone: string
}

export type IUISettingData = {
  [key in "showQuickStartGuide" | "calenderListView"]?: boolean
}

// Actions
export interface IUserConfigFetchAsyncAction {
  type: string
  payload: string
}

export interface IUpdateUserConfigAsyncAction {
  type: string
  payload: {
    id: string
    user: IUserConfigEditData
  }
}

export interface IUpdateUISettingAction {
  type: string
  payload: {
    id: string
    data: IUISettingData
  }
}

// languages supported by translations
export enum UserLanguages {
  Chinese = "zh",
  Dutch = "nl",
  English = "en",
  French = "fr",
  German = "de",
  Italian = "it",
  Japanese = "ja",
  Spanish = "es",
}

// selection of valid timezones to display - more can be added if needed
export enum UserTimezones {
  Default = "default",
  "Pacific/Honolulu: Hawaii" = "Pacific/Honolulu",
  "America/Juneau: Alaska" = "America/Juneau",
  "America/Chihuahua: Chihuahua, La Paz, Mazatlan" = "America/Chihuahua",
  "America/Phoenix: Arizona" = "America/Phoenix",
  "America/Chicago: Central Time" = "America/Chicago",
  "America/Regina: Saskatchewan" = "America/Regina",
  "America/Mexico City: Guadalajara, Mexico City, Monterrey" = "America/Mexico_City",
  "America/Guatemala: Central America" = "America/Guatemala",
  "America/New York: Eastern Time" = "America/New_York",
  "America/Bogota: Bogota, Lima, Quito" = "America/Bogota",
  "America/Caracas: Caracas, La Paz" = "America/Caracas",
  "America/Santiago: Santiago" = "America/Santiago",
  "America/Sao Paulo: Brasilia" = "America/Sao_Paulo",
  "America/Tijuana: Tijuana, Pacific Time" = "America/Tijuana",
  "America/Argentina/Buenos Aires: Buenos Aires, Georgetown" = "America/Argentina/Buenos_Aires",
  "Atlantic/Cape Verde: Cape Verde Islands" = "Atlantic/Cape_Verde",
  "Europe/London: Edinburgh, London" = "Europe/London",
  "Europe/Dublin: Dublin" = "Europe/Dublin",
  "Europe/Lisbon: Lisbon" = "Europe/Lisbon",
  "Africa/Casablanca: Casablanca, Monrovia" = "Africa/Casablanca",
  "Atlantic/Canary: Canary Islands" = "Atlantic/Canary",
  "Europe/Belgrade: Belgrade, Bratislava, Budapest, Ljubljana, Prague" = "Europe/Belgrade",
  "Europe/Sarajevo: Sarajevo, Skopje, Warsaw, Zagreb" = "Europe/Sarajevo",
  "Europe/Paris: Brussels, Copenhagen, Madrid, Paris" = "Europe/Paris",
  "Europe/Amsterdam: Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" = "Europe/Amsterdam",
  "Africa/Algiers: West Central Africa" = "Africa/Algiers",
  "Europe/Bucharest: Bucharest" = "Europe/Bucharest",
  "Africa/Cairo: Cairo" = "Africa/Cairo",
  "Europe/Helsinki: Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius" = "Europe/Helsinki",
  "Europe/Athens: Athens, Istanbul, Minsk" = "Europe/Athens",
  "Asia/Jerusalem: Jerusalem" = "Asia/Jerusalem",
  "Africa/Harare: Harare, Pretoria, Windhoek" = "Africa/Harare",
  "Europe/Moscow: Moscow, St. Petersburg, Volgograd" = "Europe/Moscow",
  "Asia/Kuwait: Kuwait, Riyadh" = "Asia/Kuwait",
  "Africa/Nairobi: Nairobi" = "Africa/Nairobi",
  "Asia/Baghdad: Baghdad" = "Asia/Baghdad",
  "Asia/Tehran: Tehran" = "Asia/Tehran",
  "Asia/Muscat: Abu Dhabi, Dubai, Muscat" = "Asia/Muscat",
  "Asia/Baku: Baku, Tbilisi, Yerevan" = "Asia/Baku",
  "Asia/Kabul: Kabul" = "Asia/Kabul",
  "Asia/Yekaterinburg: Ekaterinburg" = "Asia/Yekaterinburg",
  "Asia/Karachi: Islamabad, Karachi, Tashkent" = "Asia/Karachi",
  "Asia/Kolkata: Chennai, Kolkata, Mumbai, New Delhi" = "Asia/Kolkata",
  "Asia/Kathmandu: Kathmandu" = "Asia/Kathmandu",
  "Asia/Dhaka: Dhaka, Nur-Sultan" = "Asia/Dhaka",
  "Asia/Colombo: Sri Jayawardenepura" = "Asia/Colombo",
  "Asia/Almaty: Almaty, Novosibirsk" = "Asia/Almaty",
  "Asia/Rangoon: Yangon Rangoon" = "Asia/Rangoon",
  "Asia/Bangkok: Bangkok, Hanoi, Jakarta" = "Asia/Bangkok",
  "Asia/Krasnoyarsk: Krasnoyarsk" = "Asia/Krasnoyarsk",
  "Asia/Shanghai: Beijing, Chongqing, Hong Kong SAR, Urumqi" = "Asia/Shanghai",
  "Asia/Kuala Lumpur: Kuala Lumpur, Singapore" = "Asia/Kuala_Lumpur",
  "Asia/Taipei: Taipei" = "Asia/Taipei",
  "Australia/Perth: Perth" = "Australia/Perth",
  "Asia/Ulaanbaatar: Irkutsk, Ulaanbaatar" = "Asia/Ulaanbaatar",
  "Asia/Seoul: Seoul" = "Asia/Seoul",
  "Asia/Tokyo: Osaka, Sapporo, Tokyo" = "Asia/Tokyo",
  "Asia/Yakutsk: Yakutsk" = "Asia/Yakutsk",
  "Australia/Darwin: Darwin" = "Australia/Darwin",
  "Australia/Adelaide: Adelaide" = "Australia/Adelaide",
  "Australia/Sydney: Canberra, Melbourne, Sydney" = "Australia/Sydney",
  "Australia/Brisbane: Brisbane" = "Australia/Brisbane",
  "Australia/Hobart: Hobart" = "Australia/Hobart",
  "Asia/Vladivostok: Vladivostok" = "Asia/Vladivostok",
  "Pacific/Auckland: Auckland, Wellington" = "Pacific/Auckland",
}

// Action Types
export enum UserConfigActionTypes {
  FETCH_USER_CONFIG_ASYNC_PENDING = "@@userConfig/FETCH_USER_CONFIG_ASYNC_PENDING",
  FETCH_USER_CONFIG_ASYNC_SUCCESS = "@@userConfig/FETCH_USER_CONFIG_ASYNC_SUCCESS",
  FETCH_USER_CONFIG_ASYNC_ERROR = "@@userConfig/FETCH_USER_CONFIG_ASYNC_ERROR",

  UPDATE_USER_CONFIG_ASYNC_PENDING = "@@userConfig/UPDATE_USER_CONFIG_ASYNC_PENDING",
  UPDATE_USER_CONFIG_ASYNC_SUCCESS = "@@userConfig/UPDATE_USER_CONFIG_ASYNC_SUCCESS",
  UPDATE_USER_CONFIG_ASYNC_ERROR = "@@userConfig/UPDATE_USER_CONFIG_ASYNC_ERROR",

  UPDATE_UI_SETTING_ASYNC_PENDING = "@@userConfig/UPDATE_UI_SETTING_ASYNC_PENDING",
  UPDATE_UI_SETTING_ASYNC_SUCCESS = "@@userConfig/UPDATE_UI_SETTING_ASYNC_SUCCESS",
  UPDATE_UI_SETTING_ASYNC_ERROR = "@@userConfig/UPDATE_UI_SETTING_ASYNC_ERROR",
}
