import { Formik, Form } from "formik"
import _ from "lodash"
import SelectField from "../forms/SelectField"
import React, { useEffect } from "react"
import Label from "../forms/Label"
import { TSelectFieldOptions } from "../forms/StandardSelectField"
import { IUser } from "../../../features/user/state/userTypes"
import { ITeam } from "../../../features/team/state/teamTypes"
import { getAllActiveUsersSortedFirstNameLastName } from "../../selectors/user"
import { getAllTeams } from "../../selectors/team"
import { IAppState } from "../../../app/appTypes"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import Button from "../buttons/Button"
import { StyleVariants } from "../../state/sharedTypes"
import FieldError from "../forms/FieldError"
import { fetchAllTeamsAsync } from "../../../features/teams/state/teamsActions"
import { fetchAllUsersAsync } from "../../../features/users/state/usersActions"
import TeamMemberList from "./TeamMemberList"
import { buildUserOptions, filterOptionsBySearchValue } from "../label/UserOption"
import { isGuest } from "../../helpers/userHelpers"

interface IPropsFromParent {
  submitHandler: (values: { users: string[], teams: string[] }, options) => void
  excludedUserIds: string[]
  userLabel: string
  buttonText: string | JSX.Element
  teamUserListHeader?: string
  disableTeams?: boolean
  includeGuests?: boolean
}

const AddUsersForm: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["shared"])
  const users = useSelector((state: IAppState): IUser[] => getAllActiveUsersSortedFirstNameLastName(state))
  const teams = useSelector((state: IAppState) => (getAllTeams(state)))
  const dispatch = useDispatch()
  const [userOptions, setUserOptions] = React.useState([])
  const [teamOptions, setTeamOptions] = React.useState([])

  useEffect(() => {
    dispatch(fetchAllUsersAsync())
    if (!props.disableTeams) dispatch(fetchAllTeamsAsync())
  }, [])

  useEffect(() => {
    let availableUsers = users.filter((user) => !props.excludedUserIds.map((uid) => uid).includes(user.id))
    if (!props.includeGuests) {
      availableUsers = availableUsers.filter((user) => !isGuest(user))
    }
    const options: TSelectFieldOptions = buildUserOptions(Object.values(availableUsers))
    setUserOptions(options)
  }, [users])

  useEffect(() => {
    if (props.disableTeams) { return }
    const options: TSelectFieldOptions = Object.values(teams).map((team: ITeam) => {
      return {
        value: team.id,
        label: team.name
      }
    })
    setTeamOptions(options)
  }, [teams])

  const initialValues = {
    users: [],
    teams: []
  }

  return <Formik
    initialValues={initialValues}
    onSubmit={props.submitHandler}
  >
    {({
      values,
      isSubmitting,
      handleChange,
      handleBlur,
      errors,
      touched,
      dirty
    }) => {
      return (
        <Form>
          <div className="mb-6 w-full">
            <Label name="users">
              <div>
                {props.userLabel}
              </div>
            </Label>
            <SelectField
              name="users"
              options={userOptions}
              isMulti={true}
              onChange={handleChange}
              onBlur={handleBlur}
              filterOption={filterOptionsBySearchValue}
            />
          </div>
          {
            props.disableTeams ? null :
              <>
                <div className="mb-6 w-full">
                  <Label name="teams">
                    {t("shared:chooseTeams")}
                  </Label>
                  <SelectField
                    name="teams"
                    options={teamOptions}
                    isMulti={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isDisabled={teamOptions.length == 0}
                    placeholder={teamOptions.length == 0 ? "No teams available" : null}
                  />
                  <FieldError errorMessage={errors.users as string} isVisible={(errors.users && touched.users) as boolean} />
                </div>
                <div>
                  <TeamMemberList teamIds={values.teams} header={props.teamUserListHeader} />
                </div>
              </>
          }
          <div className="flex justify-center">
            <Button
              isDisabled={!dirty || isSubmitting}
              variant={StyleVariants.ORANGE}
              isSubmit={true}
            >
              {props.buttonText}
            </Button>
          </div>
        </Form>
      )
    }}
  </Formik >
}

export default AddUsersForm
