import { IUser } from "../../user/state/userTypes"
import IAssetTypeFilters from "../../../shared/types/AssetTypeFilters"
import { IBaseAction, IBaseCableAction } from "../../../shared/types/baseActionTypes"

// Cable
export const LiveMeetingChannelName = "LiveMeetingConfigChannel"

// State
export interface ILiveMeetingUserConfig {
  id: string
  isPresent: boolean
  isOnline: boolean
  isSharingAudio: boolean
  isSharingScreen: boolean
  isSharingVideo: boolean
  isCoordinator?: boolean
  isPresenter?: boolean
  isScribe?: boolean
}

export interface ISharedImageZoomState {
  zoom: number,
  posX: number,
  posY: number
}

export interface IConsentDisplay {
  user: IUser
  consentBy: IUser
  consentComment?: string
  response: ConsentResponse
}

export interface IConsent {
  consentById: string
  response: ConsentResponse,
  comment?: string
}

export interface ISharedPdfState {
  rotation: number
}

export interface ILiveMeetingConfigState {
  userConfigs: { [userId: string]: ILiveMeetingUserConfig }
  selectedMeetingCaseId: string
  sharedViewHoveredAssetId: string
  sharedViewSelectedAssetId: string
  sharedViewSelectedAssetTypeFilters: IAssetTypeFilters
  sharedImageZoomState: ISharedImageZoomState
  sharedPdfState: ISharedPdfState
  coordinatorId: string
  scribeId: string
  presenterId: string
  transferPresenterPending?: { userId: string, initiatedById: string }
  transferScribePending?: { userId: string, initiatedById: string }
  recommendations?: { [caseId: string]: string }
  caseStage: CaseStage,
  consents: {
    [userId: string]: IConsent
  },
  recommendationsSavedAt?: { [caseId: string]: string },
  speaking?: { [userId: string]: boolean }
}

export enum ConsentResponse {
  APPROVE = "approve",
  REJECT = "reject",
  NA = "na",
  PENDING = "pending",
}

export enum RecommendationStatus {
  PENDING = "pending",
  final = "final"
}

export enum RecommendationType {
  CASE = "case",
  MEETING = "meeting"
}

export enum CaseStage {
  PRESENTING = 1,
  DEFERRING = 2,
  RECOMMENDING = 3,
  APPROVING = 4
}

export enum YourViewTabs {
  YOUR_VIEW = 1,
  USERS_VIEW = 2
}

export enum UsersViewTabs {
  LIST = 1,
  GRID = 2
}

export interface ILiveMeetingUIState {
  isSplitScreen: boolean
  selectedYourViewTab: number
  selectedUsersViewTab: number
  isYourViewAssetListOpen: boolean
  yourViewSelectedAssetId: string
}

// Actions
export interface ILiveMeetingFetchLiveMeetingConfigAsyncAction extends IBaseAction {
  payload: string
}

export interface ILiveMeetingToggleSplitScreenAction extends IBaseAction {
  payload: boolean
}

export interface ILiveMeetingToggleIsSharedViewAssetListOpenAction extends IBaseAction {
  payload: boolean
}

export interface ILiveMeetingToggleIsYourViewAssetListOpenAction extends IBaseAction {
  payload: boolean
}

export interface ILiveMeetingChangeYourViewTabAction extends IBaseAction {
  payload: number
}

export interface ILiveMeetingChangeUsersViewTabAction extends IBaseAction {
  payload: number
}

export interface ILiveMeetingSelectCaseAction extends IBaseCableAction {
  payload: string
}

export interface ILiveMeetingToggleIsOnlineAction extends IBaseCableAction {
  payload: {
    userId: string,
    isOnline: boolean
  }
}

export interface ILiveMeetingToggleIsPresentAction extends IBaseCableAction {
  payload: {
    userId: string,
    isPresent: boolean
  }
}

export interface ILiveMeetingToggleIsSharingAudioAction extends IBaseCableAction {
  payload: {
    userId: string,
    isSharingAudio: boolean
  }
}

export interface ILiveMeetingToggleIsSharingVideoAction extends IBaseCableAction {
  payload: {
    userId: string,
    isSharingVideo: boolean
  }
}

export interface ILiveMeetingToggleIsSharingScreenAction extends IBaseCableAction {
  payload: {
    userId: string,
    isSharingScreen: boolean
  }
}

export interface ILiveMeetingUpdateSharedViewHoveredAssetAction extends IBaseCableAction {
  payload: { assetId: string }
}

export interface ILiveMeetingUpdateSharedViewSelectedAssetAction extends IBaseCableAction {
  payload: { assetId: string, assetTypeFilters: IAssetTypeFilters }
}

export interface ILiveMeetingUpdateYourViewSelectedAssetAction extends IBaseAction {
  payload: string
}

export interface ILiveMeetingUpdateSharedImageZoomStateAction extends IBaseCableAction {
  payload: ISharedImageZoomState
}

export interface ITransferRole extends IBaseAction {
  payload: string
}

export interface ITransferRoleInitiated extends IBaseAction {
  payload: { userId: string, initiatedById: string }
}

export interface ILiveMeetingUpdateSharedPdfState extends IBaseCableAction {
  payload: ISharedPdfState
}

export interface IUpdateRecommendation extends IBaseAction {
  payload: { text: string, caseId: string }
}

export interface IGiveConsent extends IBaseAction {
  payload: { response: ConsentResponse, comment?: string }
}

export interface IGiveConsentOnBehalf extends IBaseAction {
  payload: { response: ConsentResponse, userId: string }
}

// Action Types
export enum LiveMeetingActionTypes {
  FETCH_LIVE_MEETING_CONFIG_ASYNC_PENDING = "@@liveMeeting/FETCH_LIVE_MEETING_CONFIG_ASYNC_PENDING",
  FETCH_LIVE_MEETING_CONFIG_ASYNC_SUCCESS = "@@liveMeeting/FETCH_LIVE_MEETING_CONFIG_ASYNC_SUCCESS",
  FETCH_LIVE_MEETING_CONFIG_ASYNC_ERROR = "@@liveMeeting/FETCH_LIVE_MEETING_CONFIG_ASYNC_ERROR",

  TOGGLE_SPLIT_SCREEN = "@@liveMeeting/TOGGLE_SPLIT_SCREEN",
  CHANGE_YOUR_VIEW_TAB = "@@liveMeeting/CHANGE_YOUR_VIEW_TAB",
  CHANGE_USERS_VIEW_TAB = "@@liveMeeting/CHANGE_USERS_VIEW_TAB",
  TOGGLE_YOUR_VIEW_ASSET_LIST_OPEN = "@@liveMeeting/TOGGLE_YOUR_VIEW_ASSET_LIST_OPEN",
  UPDATE_YOUR_VIEW_SELECTED_ASSET = "@@liveMeeting/UPDATE_YOUR_VIEW_SELECTED_ASSET",
  USER_SPEAKING = "@@liveMeeting/USER_SPEAKING",

  MEDIA_ERROR = "@@liveMeeint/MEDIA_ERROR"
}

export const liveMeetingCablePrefix = "@@liveMeetingCable/"

export enum LiveMeetingCableActionTypes {
  // INBOUND
  UPDATE_CONFIG = "@@liveMeetingCable/UPDATE_CONFIG",
  UPDATE_MEETING_CASES = "@@liveMeetingCable/UPDATE_MEETING_CASES",
  UPDATE_MEETING = "@@liveMeetingCable/UPDATE_MEETING",
  UPDATE_CASE_ASSETS = "@@liveMeetingCable/UPDATE_CASE_ASSETS",

  // OUTBOUND
  SELECT_MEETING_CASE = "@@liveMeetingCable/SELECT_MEETING_CASE",
  TOGGLE_IS_ONLINE = "@@liveMeetingCable/TOGGLE_IS_ONLINE",
  TOGGLE_IS_PRESENT = "@@liveMeetingCable/TOGGLE_IS_PRESENT",
  TOGGLE_IS_SHARING_AUDIO = "@@liveMeetingCable/TOGGLE_IS_SHARING_AUDIO",
  TOGGLE_IS_SHARING_VIDEO = "@@liveMeetingCable/TOGGLE_IS_SHARING_VIDEO",
  TOGGLE_IS_SHARING_SCREEN = "@@liveMeetingCable/TOGGLE_IS_SHARING_SCREEN",
  UPDATE_SHARED_VIEW_SELECTED_ASSET = "@@liveMeetingCable/UPDATE_SHARED_VIEW_SELECTED_ASSET",
  UPDATE_SHARED_VIEW_HOVERED_ASSET = "@@liveMeetingCable/UPDATE_SHARED_VIEW_HOVERED_ASSET",
  UPDATE_SHARED_IMAGE_ZOOM_STATE = "@@liveMeetingCable/UPDATE_SHARED_IMAGE_ZOOM_STATE",
  UPDATE_SHARED_PDF_STATE = "@@liveMeetingCable/UPDATE_SHARED_PDF_STATE",
  TRANSFER_PRESENTER = "@@liveMeetingCable/TRANSFER_PRESENTER",
  TRANSFER_SCRIBE = "@@liveMeetingCable/TRANSFER_SCRIBE",
  UPDATE_RECOMMENDATION = "@@liveMeetingCable/UPDATE_RECOMMENDATION",
  SHARE_RECOMMENDATION = "@@liveMeetingCable/SHARE_RECOMMENDATION",
  REQUEST_APPROVAL_RECOMMENDATION = "@@liveMeetingCable/REQUEST_APPROVAL_RECOMMENDATION",
  DEFER_RECOMMENDATION = "@@liveMeetingCable/DEFER_RECOMMENDATION",
  SAVE_DEFER_RECOMMENDATION = "@@liveMeetingCable/SAVE_DEFER_RECOMMENDATION",
  CANCEL_RECOMMENDATION = "@@liveMeetingCable/CANCEL_RECOMMENDATION",
  SAVE_RECOMMENDATION = "@@liveMeetingCable/SAVE_RECOMMENDATION",
  ADD_CONSENT = "@@liveMeetingCable/ADD_CONSENT",
  ADD_CONSENT_ON_BEHALF = "@@liveMeetingCable/ADD_CONSENT_ON_BEHALF"
}

export interface ILiveMeetingAssetViewerProps {
  assetId: string
  onSelectAsset: (assetId: string) => void
  assetTypeFilters?: IAssetTypeFilters
}
