import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { useTranslation } from "react-i18next"
import { getLiveMeetingSharedViewSelectedAssetId, getLiveMeetingSharedViewSelectedAssetTypeFilters } from "../../../../shared/selectors/liveMeetingConfig/asset"
import LiveMeetingAssetsContainer from "../assets/LiveMeetingAssetsContainer"
import LiveMeetingSelectedAssetHeading from "../assets/viewer/LiveMeetingSelectedAssetHeading"
import LiveMeetingSharedViewAssetObserverViewer from "./LiveMeetingSharedViewAssetObserverViewer"
import RecommendationArea from "../shared/RecommendationArea"
import { CaseStage } from "../../state/liveMeetingTypes"
import UserConsentList from "./consent/UserConsentList"
import { getMeetingUserIds } from "../../../../shared/selectors/meeting"
import { getLiveMeetingConfig } from "../../../../shared/selectors/liveMeetingConfig"
import { getUserWithConfigById } from "../../../../shared/selectors/liveMeetingConfig/userConfig"
import LiveMeetingAssetListObserver from "../assets/LiveMeetingAssetListObserver"
import { getAssetById } from "../../../../shared/selectors/asset"
import { doesAssetUseViewer } from "../../../../shared/helpers/assetHelpers"

interface IProps {
  meetingId: string
}

const LiveMeetingSharedViewAssetsObserver: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["liveMeeting", "shared"])
  const selectedAssetId = useSelector(getLiveMeetingSharedViewSelectedAssetId)
  const selectedAsset = useSelector((state: IAppState) => getAssetById(state, { assetId: selectedAssetId }))
  const selectedAssetTypeFilters = useSelector(getLiveMeetingSharedViewSelectedAssetTypeFilters)
  const caseStage = useSelector(getLiveMeetingConfig).caseStage
  const users = useSelector((state: IAppState) => getMeetingUserIds(state, props).map(
    (userId: string) => (getUserWithConfigById(state, { userId })))
  )


  const renderAssetList = (): JSX.Element => (
    <LiveMeetingAssetsContainer>
      <div className="flex mb-2">
        <h3 className="pt-1">
          {t("liveMeeting:sharedViewAssetsObserverInstructions")}
        </h3>
      </div>
      <div className="flex-1 overflow-y-scroll pr-4">
        <LiveMeetingAssetListObserver assetTypeFilters={selectedAssetTypeFilters} />
      </div>
    </LiveMeetingAssetsContainer>
  )

  const selectedAssetUsesViewer = doesAssetUseViewer(selectedAsset)

  const assetViewer = (): JSX.Element => {
    if (!selectedAssetId) return null

    return (
      <LiveMeetingAssetsContainer>
        <div className="flex mb-2">
          <LiveMeetingSelectedAssetHeading assetId={selectedAssetId} />
        </div>
        <div className="flex-1 overflow-y-scroll pr-4">
          <LiveMeetingSharedViewAssetObserverViewer assetId={selectedAssetId} assetTypeFilters={selectedAssetTypeFilters} />
        </div>
      </LiveMeetingAssetsContainer>
    )
  }

  const renderConsentList = (): JSX.Element => {
    if (caseStage == CaseStage.DEFERRING) return null

    return (<UserConsentList users={users} readOnly />)
  }

  const renderRecommendationArea = (): JSX.Element => {
    // TODO: this should be moved to a separate componnent, a parent of this component, which switches based on stage
    // I'm leaving it for now though, as the PR for this change is big enough as it is.
    // But if you come across this and wonder why it's here, you know that I agree that it shouldn't be.
    return (
      <div className="flex flex-col flex-1 p-2 overflow-y-auto">
        <div className="h-full p-2 rounded-md mt-2">
          <RecommendationArea meetingId={props.meetingId} readOnly={true} />
          {renderConsentList()}
        </div>
      </div>
    )
  }

  const decideWhatToRender = (): JSX.Element => {
    if (caseStage != CaseStage.PRESENTING) return renderRecommendationArea()
    if (selectedAssetUsesViewer) return assetViewer()
    return renderAssetList()

  }

  return (
    <div className="flex flex-1 overflow-hidden">
      {decideWhatToRender()}
    </div>
  )
}


export default LiveMeetingSharedViewAssetsObserver
