import { createSelector } from "reselect"
import { IAppState } from "../../../app/appTypes"
import { IHaveUserId } from "../../../features/user/state/userTypes"
import { IHaveDeviceId, IWebRTCErrorState, TWebRTCDeviceAllIdsState, TWebRTCDeviceByIdState } from "../../../features/webRTC/state/webRTCTypes"
import { getCurrentUser } from "../user"

export const getWebRTCSession = (state: IAppState): OT.Session => state.webRTC.session

export const getWebRTCPublisher = (state: IAppState): OT.Publisher => state.webRTC.publisher

export const getWebRTCErrors = (state: IAppState): IWebRTCErrorState => state.webRTC.errors

export const getWebRTCScreenPublisher = (state: IAppState): OT.Publisher => state.webRTC.screenShare.publisher

export const getWebRTCScreenShareIsOn = (state: IAppState): boolean => state.webRTC.screenShare.isOn

export const getWebRTCScreenSharePreferCurrentTab = (state: IAppState): boolean => state.webRTC.screenShare.preferCurrentTab

export const getWebRTCScreenShareStream = (state: IAppState): OT.Stream => state.webRTC.screenShare.stream

export const getAllWebRTCStreamByUserId = (state: IAppState): { [userId: string]: OT.Stream } => state.webRTC.streams.byUserId

export const getWebRTCStreamByUserId = (state: IAppState, ownProps: IHaveUserId): OT.Stream => state.webRTC.streams.byUserId[ownProps.userId]

export const getAllWebRTCAudioDevices = (state: IAppState): TWebRTCDeviceByIdState => state.webRTC.audioDevices.byId

export const getAllWebRTCAudioDeviceIds = (state: IAppState): TWebRTCDeviceAllIdsState => state.webRTC.audioDevices.allIds

export const getWebRTCAudioDeviceById = (state: IAppState, ownProps: IHaveDeviceId): OT.Device => state.webRTC.audioDevices.byId[ownProps.deviceId]

export const getSelectedAudioDeviceId = (state: IAppState): string => state.webRTC.audioDevices.selectedId

export const getCurrentUserWebRTCStream = createSelector(
  getCurrentUser,
  getAllWebRTCStreamByUserId,
  (currentUser, allWebRTCStreamsByUserId): OT.Stream => {
    if (!currentUser) return null
    return allWebRTCStreamsByUserId[currentUser.id]
  }
)
