import React from "react"
import { useTranslation } from "react-i18next"

const DenotesRequiredMessage: React.FC = (_props): JSX.Element => {
  const { t } = useTranslation(["shared"])

  return (
    <div className="mb-2">
      <p className="text-ch-blue-alt-300">
        <span className="text-ch-purple-500">*</span>&nbsp;
        {t("shared:denotedRequired")}
      </p>
    </div>
  )
}

export default DenotesRequiredMessage
