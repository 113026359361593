import { Reducer, AnyAction } from "redux"
import _ from "lodash"
import {
  LiveMeetingActionTypes,
  CaseStage,
  LiveMeetingCableActionTypes,
  ILiveMeetingConfigState,
} from "./../liveMeetingTypes"
import { SharedActionTypes } from "./../../../../shared/state/sharedTypes"

export const initialState: ILiveMeetingConfigState = {
  userConfigs: {},
  selectedMeetingCaseId: null,
  sharedViewHoveredAssetId: null,
  sharedViewSelectedAssetId: null,
  sharedViewSelectedAssetTypeFilters: null,
  sharedPdfState: { rotation: 0 },
  sharedImageZoomState: {
    zoom: 1,
    posX: 0,
    posY: 0
  },
  coordinatorId: null,
  presenterId: null,
  scribeId: null,
  recommendations: {},
  caseStage: CaseStage.PRESENTING,
  consents: {},
  speaking: {},
}

export const liveMeetingConfigReducer: Reducer<ILiveMeetingConfigState> = (state: ILiveMeetingConfigState = initialState, action: AnyAction): ILiveMeetingConfigState => {
  let liveMeetingConfig: ILiveMeetingConfigState

  switch (action.type) {
    case SharedActionTypes.REHYDRATE:
      if (!action.payload?.liveMeetingConfig) return state
      if (action.payload.liveMeetingConfig.consents == undefined) {
        action.payload.liveMeetingConfig.consents = {}
      }
      return {
        ...state,
        ...action.payload.liveMeetingConfig
      }

    case LiveMeetingActionTypes.FETCH_LIVE_MEETING_CONFIG_ASYNC_SUCCESS: {
      liveMeetingConfig = addUserMeetingRoleProperties(action.payload)
      return {
        ...state,
        ...liveMeetingConfig
      }
    }

    case LiveMeetingCableActionTypes.SELECT_MEETING_CASE:
      return {
        ...state,
        selectedMeetingCaseId: action.payload,
        caseStage: CaseStage.PRESENTING
      }

    case LiveMeetingCableActionTypes.UPDATE_SHARED_VIEW_SELECTED_ASSET:
      return {
        ...state,
        sharedViewSelectedAssetId: action.payload.assetId
      }

    case LiveMeetingCableActionTypes.UPDATE_SHARED_IMAGE_ZOOM_STATE:
      return {
        ...state,
        sharedImageZoomState: action.payload
      }

    case LiveMeetingCableActionTypes.TOGGLE_IS_ONLINE:
      return updateUserConfig(state, action.payload, "isOnline")

    case LiveMeetingCableActionTypes.TOGGLE_IS_SHARING_AUDIO:
      return updateUserConfig(state, action.payload, "isSharingAudio")

    case LiveMeetingCableActionTypes.TOGGLE_IS_SHARING_VIDEO:
      return updateUserConfig(state, action.payload, "isSharingVideo")

    case LiveMeetingCableActionTypes.TOGGLE_IS_SHARING_SCREEN:
      return updateUserConfig(state, action.payload, "isSharingScreen")

    case LiveMeetingActionTypes.USER_SPEAKING: {
      const speakingNow = action.payload.speakingNow
      const speakerId = action.payload.userId

      return {
        ...state,
        speaking: {
          ...state.speaking,
          [speakerId]: speakingNow
        }
      }
    }

    case LiveMeetingCableActionTypes.UPDATE_CONFIG: {
      liveMeetingConfig = action.payload

      liveMeetingConfig.userConfigs = _.mapKeys(
        liveMeetingConfig.userConfigs,
        "id"
      )

      liveMeetingConfig = addUserMeetingRoleProperties(liveMeetingConfig)
      if (liveMeetingConfig.consents == undefined) {
        liveMeetingConfig.consents = {}
      }
      return {
        ...state,
        ...liveMeetingConfig
      }
    }

    default:
      return state
  }
}

const updateUserConfig = (state: ILiveMeetingConfigState, payload: { userId: string }, property: string) => {
  return {
    ...state,
    userConfigs: {
      ...state.userConfigs,
      [payload.userId]: {
        ...state.userConfigs[payload.userId],
        [property]: payload[property],
      },
    },
  }
}

const addUserMeetingRoleProperties = (liveMeetingConfig: ILiveMeetingConfigState) => {
  const userConfigs = liveMeetingConfig.userConfigs
  let userId: keyof typeof userConfigs
  for (userId in userConfigs) {
    userConfigs[userId].isCoordinator = userId === liveMeetingConfig.coordinatorId
    userConfigs[userId].isPresenter = userId === liveMeetingConfig.presenterId
    userConfigs[userId].isScribe = userId === liveMeetingConfig.scribeId
  }
  return liveMeetingConfig
}
