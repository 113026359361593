import React from "react"
import ImageZoom from "../../../../shared/components/ui/imageZoom/ImageZoom"
import LoadingSpinner from "../../../../shared/components/ui/LoadingSpinner"
import { IMessageAsset, IMessageDocumentAsset } from "../../../message/state/messageTypes"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getMessageAssetById } from "../../../../shared/selectors/message"

interface Props {
  messageAssetId: string
}

const MessageAssetImageViewer: React.FC<Props> = (props: Props): JSX.Element => {
  const messageAsset: IMessageAsset = useSelector((state: IAppState) => getMessageAssetById(state, props))

  if (!messageAsset) return <LoadingSpinner />

  const image = messageAsset.asset as IMessageDocumentAsset

  return (
    <ImageZoom
      imageSrc={image.path}
    />
  )
}

export default MessageAssetImageViewer

