import React, { useContext } from "react"
import { IconChangeTracking } from "../../../svg/icons"
import { StyleVariants } from "./../../../shared/state/sharedTypes"
import { useTranslation } from "react-i18next"
import { TenantConfigContext } from "../../../features/tenantConfig/contexts/TenantConfigContext"

interface Props {
  className?: string
  variant?: string
  action: () => void
  hasPermission: boolean
}

const defaultProps = {
  variant: StyleVariants.BLUE_ALT
}

const ButtonChangeTracking: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared"])

  const {
    changeTrackingEnabled
  } = useContext(TenantConfigContext)

  const defaultClasses = [
    "flex",
    "items-center",
    "justify-center",
    `bg-${props.variant}-500`,
    `hover:bg-${props.variant}-600`,
    "text-white",
    "uppercase",
    "w-8",
    "h-8",
    "font-light",
    "rounded-full",
    "md:px-4",
    "md:py-1",
    "md:w-auto",
    "md:h-auto",
    "self-start"
  ]

  const propClasses = props.className ? props.className.split(" ") : []
  const allClasses = [...defaultClasses, ...propClasses].join(" ")

  if (!changeTrackingEnabled || !props.hasPermission) return null

  return (
    <button onClick={props.action} className={allClasses}>
      <div className="hidden md:block">
        {t("shared:changeTracking")}
      </div>
      <IconChangeTracking className="fill-current w-6 h-6 md:ml-2" title={t("shared:changeTracking")} />
    </button>
  )
}

ButtonChangeTracking.defaultProps = defaultProps

export default ButtonChangeTracking
