import { call, put, takeLeading } from "@redux-saga/core/effects"
import API from "../../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { RoutePaths } from "../../../../app/routes/Routes"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { AccountActionTypes } from "../accountTypes"
import history from "../../../../app/routes/history"

export function* fetchTermsAsync(): Generator {
  try {
    const response: any = yield call(API.get, "/terms", withAuthHeader())

    yield put({
      type: AccountActionTypes.FETCH_TERMS_ASYNC_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    yield put({
      type: AccountActionTypes.FETCH_TERMS_ASYNC_ERROR,
      payload: error
    })
  }
}

export function* watchFetchTermsAsync(): Generator {
  yield takeLeading(AccountActionTypes.FETCH_TERMS_ASYNC_PENDING, fetchTermsAsync)
}

export function* acceptTermsAsync(action): Generator {
  try {
    const body = {
      version: action.payload.version
    }

    yield call(API.patch, "/terms/", body, withAuthHeader())

    yield put({
      type: AccountActionTypes.ACCEPT_TERMS_ASYNC_SUCCESS
    })

    yield history.push(RoutePaths.ROOT)
    yield window.location.reload()

  } catch (error) {
    yield put({
      type: AccountActionTypes.ACCEPT_TERMS_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "acceptTermsFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchAcceptTermsAsync(): Generator {
  yield takeLeading(AccountActionTypes.ACCEPT_TERMS_ASYNC_PENDING, acceptTermsAsync)
}
