import React from "react"
import { LogoContextBadge } from "../../../svg/branding"
import { useTranslation } from "react-i18next"

interface Props {
  className?: string
  isHidden?: boolean;
}

const defaultProps = {
  isHidden: false
}

const ListBackground = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared"])
  const defaultClasses = [
    "flex",
    "flex-col",
    "justify-center",
    "opacity-30",
    "h-full",
    "mt-2"
  ]

  const propClasses = props.className ? props.className.split(" ") : []

  const allClasses = [...defaultClasses, ...propClasses].join(" ")

  if (props.isHidden) return null

  return (
    <div className={allClasses}>
      <div>
        <div className="w-full flex justify-center"><LogoContextBadge className="w-1/4 opacity-25 mb-2" /></div>
        <div className="w-full flex justify-center text-xl font-thin text-ch-gray-500">{t("poweredBy")} CONTEXT Meeting&trade;</div>
      </div>
    </div>
  )
}

ListBackground.defaultProps = defaultProps

export default ListBackground
