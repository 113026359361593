import OT from "@opentok/client"
import {
  WebRTCActionTypes,
  IConnectToWebRTCSessionAsyncAction,
  IPublishToWebRTCSessionAction,
  ISubscribeToWebRTCStreamAction,
  IAddWebRTCStreamAction,
  sessionEventHandlers,
  publisherEventHandlers,
  IRemoveWebRTCStreamAction,
  IToggleWebRTCPublishAudioAction,
  IToggleWebRTCPublishVideoAction,
  IToggleWebRTCScreenShareIsOnAction,
  IUpdateWebRTCScreenStreamAction,
  IStopPublishToWebRTCSessionAction,
  IGetAudioDevicesAction,
  ICycleVideoSourceAction,
  IGetAudioSourceAction,
  ISetAudioSourceAction,
  IDisconnectFromWebRTCSessionAsyncAction,
  IUpdateWebRTCSessionAction,
  IUpdateWebRTCPublisherAction,
  IUnpublishFromWebRTCSessionAction
} from "./webRTCTypes"
import { RefObject } from "react"

export const connectToWebRTCSessionAction = (apiKey: string, sessionId: string, meetingToken: string, eventHandlers: sessionEventHandlers): IConnectToWebRTCSessionAsyncAction => {
  return {
    type: WebRTCActionTypes.CONNECT_TO_WEB_RTC_SESSION_ASYNC_PENDING,
    payload: {
      apiKey,
      sessionId,
      meetingToken,
      eventHandlers
    }
  }
}

export const disconnectFromWebRTCSessionAction = (session: OT.Session): IDisconnectFromWebRTCSessionAsyncAction => {
  return {
    type: WebRTCActionTypes.DISCONNECT_FROM_WEB_RTC_SESSION_ASYNC_PENDING,
    payload: {
      session
    }
  }
}

export const updateWebRTCSessionAction = (session: OT.Session): IUpdateWebRTCSessionAction => {
  return {
    type: WebRTCActionTypes.UPDATE_WEB_RTC_SESSION,
    payload: {
      session
    }
  }
}

export const publishToWebRTCSessionAction = (
  session: OT.Session,
  containerRef: RefObject<HTMLDivElement>,
  options: OT.PublisherProperties,
  eventHandlers: publisherEventHandlers
): IPublishToWebRTCSessionAction => {
  return {
    type: WebRTCActionTypes.PUBLISH_TO_WEB_RTC_SESSION_ASYNC_PENDING,
    payload: {
      session,
      containerRef,
      options,
      eventHandlers
    }
  }
}

export const publishScreenToWebRTCSessionAction = (
  session: OT.Session,
  containerRef: RefObject<HTMLDivElement>,
  options: OT.PublisherProperties,
  eventHandlers: publisherEventHandlers
): IPublishToWebRTCSessionAction => {
  return {
    type: WebRTCActionTypes.PUBLISH_SCREEN_TO_WEB_RTC_SESSION_ASYNC_PENDING,
    payload: {
      session,
      containerRef,
      options,
      eventHandlers
    }
  }
}

export const stopPublishScreenToWebRTCSessionAction = (
  session: OT.Session,
  publisher: OT.Publisher
): IStopPublishToWebRTCSessionAction => {
  return {
    type: WebRTCActionTypes.STOP_PUBLISH_SCREEN_TO_WEB_RTC_SESSION_ASYNC_PENDING,
    payload: {
      session,
      publisher
    }
  }
}

export const unpublishFromWebRTCSessionAction = (session: OT.Session, publisher: OT.Publisher): IUnpublishFromWebRTCSessionAction => {
  return {
    type: WebRTCActionTypes.UNPUBLISH_FROM_WEB_RTC_SESSION_PENDING,
    payload: {
      session,
      publisher
    }
  }
}

export const updateWebRTCPublisherAction = (publisher: OT.Publisher): IUpdateWebRTCPublisherAction => {
  return {
    type: WebRTCActionTypes.UPDATE_WEB_RTC_PUBLISHER,
    payload: {
      publisher
    }
  }
}

export const subscribeToWebRTCStreamAction = (
  session: OT.Session,
  stream: OT.Stream,
  containerRef: RefObject<HTMLDivElement>,
  options: OT.SubscriberProperties
): ISubscribeToWebRTCStreamAction => {
  return {
    type: WebRTCActionTypes.SUBSCRIBE_TO_WEB_RTC_STREAM_ASYNC_PENDING,
    payload: {
      session,
      stream,
      containerRef,
      options
    }
  }
}

export const toggleWebRTCPublishAudioAction = (
  userId: string,
  publisher: OT.Publisher,
  isSharingAudio: boolean
): IToggleWebRTCPublishAudioAction => {
  return {
    type: WebRTCActionTypes.TOGGLE_WEB_RTC_PUBLISHER_AUDIO_PENDING,
    payload: {
      userId,
      publisher,
      isSharingAudio
    }
  }
}

export const toggleWebRTCPublishVideoAction = (
  userId: string,
  publisher: OT.Publisher,
  isSharingVideo: boolean
): IToggleWebRTCPublishVideoAction => {
  return {
    type: WebRTCActionTypes.TOGGLE_WEB_RTC_PUBLISHER_VIDEO_PENDING,
    payload: {
      userId,
      publisher,
      isSharingVideo
    }
  }
}

export const toggleWebRTCScreenShareIsOnAction = (
  isSharingScreen: boolean
): IToggleWebRTCScreenShareIsOnAction => {
  return {
    type: WebRTCActionTypes.TOGGLE_WEB_RTC_SCREEN_SHARING_IS_ON,
    payload: { isSharingScreen }
  }
}

// specific action for stating to share the current tab. Stopping can be done with
// `toggleWebRTCScreenShareIsOnAction`
export const webRTCScreenShareCurrentAction = (
): IToggleWebRTCScreenShareIsOnAction => {
  return {
    type: WebRTCActionTypes.TOGGLE_WEB_RTC_SCREEN_SHARING_IS_ON,
    payload: {
      isSharingScreen: true,
      preferCurrentTab: true
    }
  }
}


export const addWebRTCStreamAction = (
  userId: string,
  stream: OT.Stream
): IAddWebRTCStreamAction => {
  return {
    type: WebRTCActionTypes.ADD_WEB_RTC_STREAM,
    payload: {
      userId,
      stream
    }
  }
}

export const removeWebRTCStreamAction = (
  userId: string
): IRemoveWebRTCStreamAction => {
  return {
    type: WebRTCActionTypes.REMOVE_WEB_RTC_STREAM,
    payload: {
      userId
    }
  }
}

export const updateWebRTCStreamAction = (
  userId: string,
  stream: OT.Stream
): IAddWebRTCStreamAction => {
  return {
    type: WebRTCActionTypes.UPDATE_WEB_RTC_STREAM,
    payload: {
      userId,
      stream
    }
  }
}

export const updateWebRTCScreenStreamAction = (
  stream: OT.Stream
): IUpdateWebRTCScreenStreamAction => {
  return {
    type: WebRTCActionTypes.UPDATE_WEB_RTC_SCREEN_STREAM,
    payload: stream
  }
}

export const getAudioDevicesAction = (): IGetAudioDevicesAction => {
  return {
    type: WebRTCActionTypes.GET_WEB_RTC_AUDIO_DEVICES_PENDING
  }
}

export const getAudioSourceAction = (
  publisher: OT.Publisher
): IGetAudioSourceAction => {
  return {
    type: WebRTCActionTypes.GET_WEB_RTC_AUDIO_SOURCE_PENDING,
    payload: {
      publisher: publisher
    }
  }
}

export const setAudioSourceAction = (
  publisher: OT.Publisher,
  deviceId: string
): ISetAudioSourceAction => {
  return {
    type: WebRTCActionTypes.SET_WEB_RTC_AUDIO_SOURCE_PENDING,
    payload: {
      deviceId,
      publisher
    }
  }
}

export const cycleVideoSourceAction = (
  publisher: OT.Publisher
): ICycleVideoSourceAction => {
  return {
    type: WebRTCActionTypes.CYCLE_WEB_RTC_VIDEO_SOURCE_PENDING,
    payload: {
      publisher: publisher
    }
  }
}
