import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import LoadingSpinner from "../../../../shared/components/ui/LoadingSpinner"
import PdfViewer from "../../../../shared/components/ui/PdfViewer"
import fetchBlob from "../../../../shared/helpers/fetchBlob"
import { getMessageAssetById } from "../../../../shared/selectors/message"
import { IMessageAsset, IMessageDocumentAsset } from "../../../message/state/messageTypes"

interface Props {
  messageAssetId: string
}

const MessageAssetPdfViewer: React.FC<Props> = (props: Props): JSX.Element => {
  const messageAsset: IMessageAsset = useSelector((state: IAppState) => getMessageAssetById(state, props))

  if (!messageAsset) return <LoadingSpinner />

  const pdf = messageAsset.asset as IMessageDocumentAsset
  const [blobUrl, setBlobUrl] = useState(null)

  useEffect(() => {
    fetchBlob(pdf.path, setBlobUrl)
  }, [pdf.path])

  if (!blobUrl) return <LoadingSpinner />

  return <PdfViewer
    url={blobUrl}
    filename={`${pdf.name}`}
  />
}

export default MessageAssetPdfViewer
