import React, { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import AppHeader from "../features/nav/components/Header"
import AppMenu from "../features/nav/components/Menu"
import { getScrollUI } from "../shared/selectors/ui"
import { ScrollActionTypes } from "../features/scroll/state/scrollTypes"
import { scrollUpdatePosition } from "../features/scroll/state/scrollActions"
import MessageNotification from "../features/message/components/MessageNotification"
import { ErrorBoundary } from "../app/ErrorBoundary"
import { useAppSelector } from "../shared/hooks"
import QuickStartGuide from "./QuickStartGuide"

const AppLayout: React.FC = (props): JSX.Element => {
  const scrollUIState = useAppSelector(getScrollUI)
  const mainScrollToPosition = scrollUIState?.mainScrollToPosition
  const scrollElementRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const resetScrollPosition = () => dispatch(scrollUpdatePosition(ScrollActionTypes.UPDATE_MAIN_SCROLL_TO_POSITION, null))

  const [showHelpModal, setShowHelpModal] = useState(false)
  const openHelpModal = () => setShowHelpModal(true)

  useEffect(() => {
    scrollElementRef.current.scrollTo({
      top: mainScrollToPosition,
      behavior: "smooth"
    })
    resetScrollPosition()
  }, [mainScrollToPosition])

  const defaultClasses = "bg-ch-blue-alt-200 h-screen flex flex-col overflow-hidden relative"

  return (
    <ErrorBoundary>
      <div className={defaultClasses}>
        <QuickStartGuide open={showHelpModal} setOpen={setShowHelpModal} />
        <AppHeader openHelpModal={openHelpModal} />
        <MessageNotification />
        <div className="flex flex-1 overflow-hidden relative">
          <AppMenu openHelpModal={openHelpModal} />
          <div ref={scrollElementRef} className="w-full h-full p-3 md:pb-12 overflow-y-scroll">
            <ErrorBoundary>
              {props.children}
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default AppLayout
