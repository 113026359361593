import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { RoutePaths } from "../../../../app/routes/Routes"
import Button from "../../../../shared/components/buttons/Button"
import ButtonChangeTracking from "../../../../shared/components/buttons/ButtonChangeTracking"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import Breadcrumbs from "../../../../shared/components/ui/Breadcrumbs"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import { getCaseById } from "../../../../shared/selectors/case"
import { defaultModalStyle, StyleVariants } from "../../../../shared/state/sharedTypes"
import { IconFolder } from "../../../../svg/icons"
import { ICase } from "../../state/caseTypes"
import CaseDetails from "../details/CaseDetails"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import DetailsCardRight from "../../../../shared/components/layout/detailsCard/DetailsCardRight"
import DetailsCardButtons from "../../../../shared/components/layout/detailsCard/DetailsCardButtons"
import CaseWatchButton from "../details/CaseWatchButton"
import { generatePath } from "react-router"
import { getAllMeetingsAsArray } from "../../../../shared/selectors/meeting"
import _ from "lodash"
import Modal from "react-modal"
import ModalContainer from "../../../../shared/components/modals/ModalContainer"
import MeetingListItemContainer from "../MeetingListItemContainer"
import { MeetingStatuses } from "../../../meeting/state/meetingTypes"
import AddMeetingToCaseButton from "../../../addMeetingToCase/components/AddMeetingToCaseButton"
import LockCaseButton from "./LockCaseButton"
import { fetchAuditEventsAsync } from "../../../audit/state/auditEventActions"
import { toggleIsQuickViewOpen } from "../../../nav/state/navActions"
import QuickView from "../../../../shared/components/ui/quickView/QuickView"
import { fetchCaseClassificationAction } from "../../../caseClassification/state/caseClassificationActions"
import CaseChangeTracking from "./CaseChangeTracking"

interface IProps {
  caseId: string
}


const CaseHeader: React.FC<IProps> = (props): JSX.Element => {
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const [meetingOptions, setMeetingOptions] = React.useState([])
  const { t } = useTranslation(["case"])
  const dispatch = useDispatch()

  const theCase = useSelector((state: IAppState): ICase => getCaseById(state, props))
  const meetings = useSelector(getAllMeetingsAsArray)

  const openModal = (): void => {
    setIsOpen(true)
  }

  const closeModal = (): void => {
    setIsOpen(false)
  }

  useEffect(() => {
    // TODO: remove filtering here and only return meeting options from
    // the api that should be visible
    const filteredMeetingOptions = _.filter(meetings, (meeting) => {
      return meeting.status !== MeetingStatuses.Ended
    })
    setMeetingOptions(filteredMeetingOptions.map(meeting => meeting.id))
  }, [meetings, theCase.meetings])

  if (!theCase?.id || !theCase?.permissions) return null

  const renderEditButton = (): JSX.Element => {
    if (!theCase.permissions.canEdit || theCase.locked) return null
    const editCaseUrl = generatePath(RoutePaths.CASE_EDIT, {
      caseId: props.caseId
    })

    return (
      <Button
        href={editCaseUrl}
        variant={StyleVariants.PURPLE}
        key="2"
      >
        {t("case:editCase")}
      </Button>
    )
  }

  const renderAddToMeetingButton = (): JSX.Element => {
    if (theCase.permissions.canManage && !theCase.locked) {
      return (
        <Button
          action={() => openModal()}
          variant={StyleVariants.PURPLE}
          key="3"
        >
          {t("case:addToMeeting")}
        </Button>
      )
    }
    else return null
  }

  const renderMeetingsToAdd = (): JSX.Element => {
    // TODO: set up addMeetingToCase modal to mirror addCaseToMeeting modal
    // Bring this code out of the header and into top feature/redux level
    if (meetingOptions.length == 0) return (
      <div>
        <h3 className="text-lg">
          {t("case:noCaseMeetings")}
        </h3>
      </div>
    )
    return (
      <div className="w-full">
        <ul className="p-2">
          {_.map(meetingOptions, (meetingId) => {
            return (
              <MeetingListItemContainer meetingId={meetingId} key={meetingId}>
                <DetailsCardButtons buttons={[
                  <AddMeetingToCaseButton caseId={props.caseId} meetingId={meetingId} key="4" />
                ]} />
              </MeetingListItemContainer>
            )
          })}
        </ul>
      </div>
    )
  }

  if (!theCase?.id || !theCase?.permissions) return null
  return (
    <div>
      <div className="flex justify-between">
        <Breadcrumbs
          breadcrumbs={[
            {
              pageTitle: t("shared:cases"),
              href: RoutePaths.CASES
            },
            {
              pageTitle: t("case:caseDetails")
            }
          ]}
        />
        <ButtonChangeTracking
          hasPermission={theCase?.permissions?.canTrackChanges}
          action={() => {
            dispatch(fetchAuditEventsAsync("Case", props.caseId))
            dispatch(fetchCaseClassificationAction())
            dispatch(toggleIsQuickViewOpen(props.caseId, true))
          }}
        />
      </div>

      <DetailsCard>
        <DetailsCardInner>
          <IconCircleContainer className="sm:mr-4">
            <IconFolder className="fill-current w-6 h-6" title={t("shared:case")} />
          </IconCircleContainer>
          <CaseDetails caseId={props.caseId} hasLink={false} />
        </DetailsCardInner>

        <DetailsCardRight>
          <DetailsCardButtons buttons={[
            <LockCaseButton key="4" caseId={theCase.id} />,
            renderEditButton(),
            renderAddToMeetingButton()
          ]} />
          <div className="text-center lg:text-right mb-2 hidden">
            <CaseWatchButton className="uppercase inline-flex" caseId={props.caseId} />
          </div>
        </DetailsCardRight>
      </DetailsCard>

      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={defaultModalStyle}>
        <ModalContainer handleClose={closeModal}>
          <h2 className="text-lg mb-3">
            {t("case:addToMeetingIntro")}
          </h2>

          <div className="mb-3">
            <p className="text-ch-blue-600">{t("case:addToMeetingDesc1")}</p>
          </div>

          {renderMeetingsToAdd()}

        </ModalContainer>
      </Modal>
      <QuickView>
        <CaseChangeTracking caseId={props.caseId} />
      </QuickView>
    </div>
  )
}

export default CaseHeader
