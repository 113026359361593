import { Form, Formik, Field, FormikProps } from "formik"
import _ from "lodash"
import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import Button from "../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import { IUser, IUserEditData, UserTitles } from "../../user/state/userTypes"
import Label from "../../../shared/components/forms/Label"
import InputField from "../../../shared/components/forms/InputField"
import FieldError from "../../../shared/components/forms/FieldError"
import { updateUserAction } from "../../user/state/userActions"
import { IAppState } from "../../../app/appTypes"
import { TSelectFieldOptions } from "../../../shared/components/forms/StandardSelectField"
import { userEditValidationSchema } from "../state/userEditValidation"
import { ISpecialty } from "../../specialty/state/specialtyTypes"
import { getAllSpecialties } from "../../../shared/selectors/specialties"
import DenotesRequiredMessage from "../../../shared/components/forms/DenotesRequiredMessage"
import SelectField from "../../../shared/components/forms/SelectField"
import { getUserByHasUserId, getCurrentUser, getAllUsers } from "../../../shared/selectors/user"
import ResendInviteButton from "./ResendInviteButton"
import ClearMfaButton from "./ClearMfaButton"
import Buttons from "../../../shared/components/layout/Buttons"
import EmailConfirmationModal from "./EmailConfirmationModal"
import { generateSpecialtyOptions } from "../../../shared/helpers/selectOptionHelpers"
import { isGuest, isAdmin } from "../../../shared/helpers/userHelpers"
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input"
import flags from "react-phone-number-input/flags"
import UnlockButton from "./UnlockButton"

interface IPropsFromParent {
  userId: string
}

const baseRoles = {
  caseAdmin: "case_admin",
  meetingAdmin: "meeting_admin",
  teamAdmin: "team_admin",
  userEditor: "user_editor"
}

const adminAssignableRoles = {
  radiologyAdmin: "radiology_admin",
  userAdmin: "user_admin",
  fullAdmin: "admin"
}

const UserEditForm: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["shared", "user"])
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const formikFormRef = useRef<FormikProps<IUserEditData>>(null)
  const specialties = useSelector((state: IAppState): { [id: string]: ISpecialty } => getAllSpecialties(state))
  const [specialtiesOptions, setSpecialtiesOptions] = React.useState([])
  const user = useSelector((state: IAppState): IUser => getUserByHasUserId(state, props))
  const currentUser = useSelector((state: IAppState): IUser => getCurrentUser(state))
  const allUsers = useSelector((state: IAppState) => getAllUsers(state))

  const dispatch = useDispatch()
  const closeModal = () => {
    setIsOpen(false)
  }

  if (!user) return null

  const initialValues: IUserEditData = {
    title: user.title,
    professionalTitle: user.professionalTitle,
    firstName: user.firstName,
    lastName: user.lastName,
    specialtyIds: user.specialties?.map(specialty => specialty.id) || [],
    admin_roles: user.tenantRoles?.filter((r) => r != "participant")?.concat(user.roles) || [],
    active: user.activeInCurrentTenant,
    mfaEnabled: user.mfaEnabled,
    email: user.email,
    organisation: user.organisation,
    phoneNumber: user.phoneNumber ? `+${user.phoneCountryCode || "44"}${user.phoneNumber}` : "",
  }

  const titleOptions: TSelectFieldOptions = Object.keys(UserTitles).map((key) => {
    return {
      value: UserTitles[key],
      label: key
    }
  })

  useEffect(() => {
    const options: TSelectFieldOptions = generateSpecialtyOptions(specialties)
    setSpecialtiesOptions(options)
  }, [specialties])

  const renderRoleFields = () => {
    let roles: { readonly [index: string]: string }
    if (isGuest(user)) {
      return (
        <div className="mb-2">
          <Label name="admin_roles"> {t("user:roles")} </Label>
          <div>{t("user:isGuest")}</div>
        </div>
      )
    }

    if (user.permissions.canAssignAdminRole) {
      roles = {
        ...baseRoles,
        ...adminAssignableRoles
      }
    } else if (user.permissions.canManage) {
      roles = baseRoles
    } else {
      return null
    }

    if (user.id == currentUser.id) {
      return (
        <div className="mb-2">
          {t("user:adminCannotChangeOwnRole")}
        </div>
      )
    }

    return (
      <div className="mb-2">
        <Label name="admin_roles"> {t("user:roles")} </Label>
        <div role="group" aria-labelledby="checkbox-group">

          {Object.entries(roles).map(([key, role]) =>
            <div className="flex items-center" key={role}>
              <Field type="checkbox" name="admin_roles" value={role} className="mx-1" />
              <Label name="admin_roles"> {t(`user:roleNames:${key}`)} </Label>
            </div>
          )}

        </div>
      </div>
    )
  }

  const renderActiveFields = () => {
    if (!user.permissions?.canManage) { return null }

    if (user.id == currentUser.id) {
      return (
        <div className="mb-2">
          {t("user:adminCannotDeactivateSelf")}
        </div>
      )
    }

    return (
      <div className="mb-2">
        <Label name="admin_roles"> {t("user:activeInactive")} </Label>

        <div className="flex items-center">
          <Field type="checkbox" name="active" className="mx-1" />
          <Label name="active"> {t("user:active")} </Label>
        </div>

      </div>
    )
  }

  const renderMfaFields = () => {
    if (!user.permissions?.canManage) { return null }

    return (
      <div className="mb-2">
        <Label name="admin_roles"> {t("user:enableMfa")} </Label>
        <div className="flex items-center">
          <Field type="checkbox" name="mfaEnabled" className="mx-1" />
          <Label name="mfaEnabled"> {t("user:mfaEnabled")} </Label>
        </div>
      </div>
    )
  }

  const renderAdminButtons = () => {
    if (!user.permissions?.canEdit) { return null }

    return (
      <div className="md:absolute top-3 right-3 flex float-right items-end ">
        <Buttons buttons={

          [<ResendInviteButton key="1" user={user} />,
          <ClearMfaButton key="2" user={user} />,
          <UnlockButton key="2" user={user} />,
          ]
        } />
      </div>
    )
  }

  const openModal = (): void => {
    setIsOpen(true)
  }

  const dispatchFormValues = (values: IUserEditData) => {
    const data = { ...values }
    const phoneNumberData = parsePhoneNumber(data.phoneNumber)
    if (phoneNumberData) {
      data.phoneNumber = phoneNumberData.nationalNumber
      data.phoneCountryCode = phoneNumberData.countryCallingCode
    }
    dispatch(updateUserAction(props.userId, data))
  }

  const handleUserUpdate = (): void => {
    closeModal()
    dispatchFormValues(formikFormRef.current.values)
  }

  const userEmails = (_.map(allUsers, "email")).filter(e => e !== initialValues.email)

  return (
    <div className="flex flex-col md:relative">
      {renderAdminButtons()}
      <div className="flex justify-left pl-4">
        <Formik
          initialValues={initialValues}
          validationSchema={userEditValidationSchema(userEmails)}
          enableReinitialize={true}
          onSubmit={async (values, { setSubmitting }) => {
            if (values.email === initialValues.email) {
              dispatchFormValues(values)
            } else {
              openModal()
            }
            setSubmitting(false)
          }}
          innerRef={formikFormRef}
        >
          {({
            isSubmitting,
            errors,
            values,
            touched,
            handleChange,
            handleBlur,
            dirty,
            setFieldValue
          }) => {
            return (
              <Form className="w-full lg:w-2/3">
                <DenotesRequiredMessage />

                <div className="mb-2">
                  <Label name="title">
                    {t("user:title")}
                  </Label>
                  <SelectField
                    name="title"
                    options={titleOptions}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FieldError errorMessage={errors.title as string} isVisible={(errors.title && touched.title) as boolean} />
                </div>

                <div className="mb-2">
                  <Label name="professionalTitle">
                    {t("user:professionalTitle")}
                  </Label>
                  <InputField
                    type="text"
                    name="professionalTitle"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.professionalTitle}
                    autoComplete="off"
                  />
                  <FieldError errorMessage={errors.professionalTitle as string} isVisible={(errors.professionalTitle && touched.professionalTitle) as boolean} />
                </div>

                <div className="mb-2">
                  <Label name="firstName" required={true}>
                    {t("user:firstName")}
                  </Label>
                  <InputField
                    type="text"
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    autoComplete="off"
                  />
                  <FieldError errorMessage={errors.firstName as string} isVisible={(errors.firstName && touched.firstName) as boolean} />
                </div>

                <div className="mb-2">
                  <Label name="lastName" required={true}>
                    {t("user:lastName")}
                  </Label>
                  <InputField
                    type="text"
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    autoComplete="off"
                  />
                  <FieldError errorMessage={errors.lastName as string} isVisible={(errors.lastName && touched.lastName) as boolean} />
                </div>

                {isAdmin(currentUser) ?
                  <div className="mb-2">
                    <Label name="email" required={true}>
                      {t("user:email")}
                    </Label>
                    <InputField
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      autoComplete="off"
                    />
                    <FieldError errorMessage={errors.email as string} isVisible={(errors.email && touched.email) as boolean} />
                  </div> : null
                }

                {isAdmin(currentUser) ?
                  <div className="mb-2">
                    <Label name="phoneNumber">
                      {t("user:phoneNumber")}
                    </Label>
                    <PhoneInput
                      value={values.phoneNumber}
                      onChange={(value) => setFieldValue("phoneNumber", value)}
                      defaultCountry="GB"
                      // TODO: Fix flags not showing in prod when loaded with flagUrl
                      // flagUrl={`${location.origin}/images/svg/flags/{xx}.svg`}
                      flags={flags}
                    />
                    <FieldError errorMessage={errors.phoneNumber as string} isVisible={(errors.phoneNumber && touched.phoneNumber) as boolean} />
                  </div> : null
                }

                {isGuest(user) ?
                  <div className="mb-2">
                    <Label name="organisation">
                      {t("user:organisation")}
                    </Label>
                    <InputField
                      type="text"
                      name="organisation"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.organisation}
                      autoComplete="off"
                    />
                  </div> : null
                }

                <div className="mb-6">
                  <Label name="specialtyIds">
                    {t("user:specialties")}
                  </Label>
                  <SelectField
                    name="specialtyIds"
                    options={specialtiesOptions}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isMulti
                    isGrouped
                    closeMenuOnSelect={false}
                  />
                  <FieldError errorMessage={errors.specialtyIds as string} isVisible={(errors.specialtyIds && touched.specialtyIds) as boolean} />
                </div>

                {renderRoleFields()}
                {renderActiveFields()}
                {renderMfaFields()}

                <div className="flex justify-center mb-6">
                  <Button
                    isDisabled={!dirty || isSubmitting}
                    variant={StyleVariants.PURPLE}
                    isSubmit={true}
                  >
                    {t("user:updateUser")}
                  </Button>
                </div>
                <EmailConfirmationModal modalIsOpen={modalIsOpen} closeModal={closeModal} handleUserUpdate={handleUserUpdate} oldEmail={user.email} newEmail={values.email} />
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default UserEditForm
