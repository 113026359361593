import React, { Key, ReactNode } from "react"
import { StyleVariants } from "../../state/sharedTypes"
import Button from "../buttons/Button"

interface IPropsFromParent {
  pageTitle: string
  buttonText?: string
  buttonHref?: string
  hasButtonPermission?: boolean
  secondaryButton?: ReactNode
}

type Props = IPropsFromParent

const defaultProps = {
  hasButtonPermission: false
}

export const TitleButtonFactory = (action: string | VoidFunction, text: ReactNode, key: Key): JSX.Element => {
  if (typeof action == "string")
    return (
      <Button
        href={action as string}
        className="ml-5"
        variant={StyleVariants.ORANGE}
        key={key}
      >
        {text}
      </Button>
    )
  return (
    <Button
      action={action as VoidFunction}
      className="ml-5"
      variant={StyleVariants.ORANGE}
      key={key}
    >
      {text}
    </Button>
  )
}


const PageTitle: React.FC<Props> = (props): JSX.Element => {
  const renderButtons = (): JSX.Element => {
    if (!props.buttonText || !props.buttonHref || !props.hasButtonPermission) return null
    if (!props.secondaryButton) {
      return TitleButtonFactory(props.buttonHref, props.buttonText, "1")
    } else {
      return (
        <ul className="flex flex-wrap flex-1 justify-left">
          <li>{TitleButtonFactory(props.buttonHref, props.buttonText, "1")}</li>
          <li>{props.secondaryButton}</li>
        </ul>
      )
    }
  }

  return (
    <div className="flex items-center mb-4">
      <h2 className="text-lg py-1">
        {props.pageTitle}
      </h2>
      {renderButtons()}
    </div>
  )
}

PageTitle.defaultProps = defaultProps

export default PageTitle
