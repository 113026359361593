import React from "react"
import { useTranslation } from "react-i18next"
import { IconPlus } from "../../../svg/icons"
import { StyleVariants } from "../../state/sharedTypes"
import ButtonLink from "../buttons/ButtonLink"

interface IProps {
  className?: string;
  variant?: string;
  action: () => void;
}

const AddGroup: React.FC<IProps> = ({ className, action, variant }): JSX.Element => {
  const { t } = useTranslation(["shared"])

  if (!variant) {
    variant = StyleVariants.BLUE
  }

  const defaultClasses = [
    `text-${variant}-500`,
    `hover:text-${variant}-600`,
    "uppercase",
    "font-semibold",
    "bg-white"
  ]

  const renderButton = (): JSX.Element => {
    const propClasses = className ? className.split(" ") : []
    const allClasses = [...defaultClasses, ...propClasses].join(" ")

    return (
      <ButtonLink className={allClasses} variant={StyleVariants.BLUE} action={action}>
        {t("shared:addGroup")} <IconPlus className="w-6 h-6 ml-2" title={t("shared:addGroup")} />
      </ButtonLink>
    )
  }

  return (
    <div className="flex justify-center items-center relative hidden">
      <div className="flex w-full justify-center items-center absolute">
        <div className="absolute">
          {renderButton()}
        </div>

        <div className="border-t border-ch-blue-500 w-full"></div>
      </div>

      <div className="invisible">
        {renderButton()}
      </div>
    </div>
  )
}

export default AddGroup
