import React from "react"
import NameUser from "../../../../shared/components/names/NameUser"
import { IUser } from "../../../user/state/userTypes"
import { IconReject, IconAccept, IconFolder, IconNa } from "../../../../svg/icons"
import { ConsentResponse, RecommendationStatus } from "../../../liveMeeting/state/liveMeetingTypes"
import { useTranslation } from "react-i18next"
import { IRecommendationAsset, IRecommendationConsent } from "../../state/assetsTypes"
import classNames from "classnames"
import MentionFieldReadOnly from "../../../../shared/components/forms/MentionFieldReadOnly"
import { getAllUsers, getCurrentUser } from "../../../../shared/selectors/user"
import Button from "../../../../shared/components/buttons/Button"
import { defaultModalStyle, StyleVariants } from "../../../../shared/state/sharedTypes"
import Modal from "react-modal"
import ModalContainer from "../../../../shared/components/modals/ModalContainer"
import CaseDetailsSimple from "../../../case/components/details/CaseDetailsSimple"
import { Form, Formik } from "formik"
import YourConsentValidation from "../../state/YourConsentValidation"
import Label from "../../../../shared/components/forms/Label"
import MentionField from "../../../../shared/components/forms/MentionField"
import FieldError from "../../../../shared/components/forms/FieldError"
import Buttons from "../../../../shared/components/layout/Buttons"
import { addConsentAction } from "../../state/assetsActions"
import { useDispatch } from "react-redux"
import { useAppSelector as useSelector } from "../../../../shared/hooks"

interface IProps {
  consent: IRecommendationConsent
  recommendation: IRecommendationAsset
  caseId: string
  mentionableUsers: { [id: string]: IUser }
}

const RecommendationConsentItem: React.FC<IProps> = (props: IProps) => {
  const currentUser = useSelector(getCurrentUser)
  const users = useSelector(getAllUsers)
  const dispatch = useDispatch()
  const addResponse = (recommendationId: string, userId: string) => {
    return (response: ConsentResponse) =>
      () =>
        dispatch(addConsentAction(recommendationId, userId, response, null))
  }
  const addComment = (recommendationId: string, userId: string) =>
    (comment: string) =>
      dispatch(addConsentAction(recommendationId, userId, null, comment))
  const { t } = useTranslation(["asset", "consentComment"])
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const closeModal = () => setIsOpen(false)
  const openModal = () => setIsOpen(true)
  const consentByUser = props.consent.consentBy
  const user = props.consent.user

  // const recommendation = props.asset.asset as IRecommendationAsset
  const recommendationPending = props.recommendation.recommendationStatus == RecommendationStatus.PENDING
  const consentPending = props.consent.response == ConsentResponse.PENDING

  if (!recommendationPending && props.consent.response == ConsentResponse.NA) return null

  const handleResponseForUser = (userId: string) => (addResponse(props.recommendation.id, userId))
  const handleResponse = addResponse(props.recommendation.id, currentUser.id)
  const handleComment = addComment(props.recommendation.id, currentUser.id)

  const consentOnBehalf = props.consent.consentBy && props.consent.user.id != props.consent.consentBy.id
  const canConsentOnBehalf = props.consent.permissions.canUpdate

  const isCurrentUser = currentUser.id == user.id
  const isCursorPointerEnabled = recommendationPending && isCurrentUser ? true : canConsentOnBehalf

  // TODO: refactor this into some helper function that help us pick the right
  // colour. This is also used in the sharedView/consentListItem
  const pendingColor = !canConsentOnBehalf ? "hidden" : consentPending ? "text-ch-blue-500" : "text-ch-blue-300"

  const approved = props.consent.response == ConsentResponse.APPROVE
  const rejected = props.consent.response == ConsentResponse.REJECT
  const na = props.consent.response == ConsentResponse.NA

  const approveColor = approved ? "text-green-500" : pendingColor
  const rejectColor = rejected ? "text-red-500" : pendingColor
  const naColor = na ? "text-ch-gray-600" : pendingColor
  const yourConsentComment = props.consent.consentComment

  const wrapperClasses = classNames(
    "text-sm p-2 rounded-md my-2 bg-ch-gray-200 flex justify-between items-center",
    { "border-2 border-ch-blue-400": isCurrentUser }
  )

  const renderCurrentUser = () => {
    return (<>
      {props.consent.response == ConsentResponse.PENDING ? <div className="flex-1 mt-1 text-ch-orange-500 font-bold">
        {t("consentRequired")}
      </div> : null}

      {recommendationPending ? <Button variant={StyleVariants.BLUE} className="ml-2" action={openModal}>
        {yourConsentComment != null ? t("asset:edit") : t("asset:addComment")}
      </Button>
        : null}
    </>)
  }

  const renderUserIcon = () => {
    return (
      <>
        <IconAccept
          className={classNames("fill-current", "w-6", "h-6", "ml-3", approveColor, { "cursor-pointer": isCursorPointerEnabled })}
          onClick={recommendationPending && isCurrentUser ? handleResponse(ConsentResponse.APPROVE) : actualHandler(ConsentResponse.APPROVE)} />
        <IconReject
          className={classNames("fill-current", "w-6", "h-6", "ml-3", rejectColor, { "cursor-pointer": isCursorPointerEnabled })}
          onClick={recommendationPending && isCurrentUser ? handleResponse(ConsentResponse.REJECT) : actualHandler(ConsentResponse.REJECT)} />
        <IconNa
          className={classNames("fill-current", "w-6", "h-6", "ml-3", naColor, { "cursor-pointer": isCursorPointerEnabled })}
          onClick={recommendationPending && isCurrentUser ? handleResponse(ConsentResponse.NA) : actualHandler(ConsentResponse.NA)} />
      </>
    )
  }

  const actualHandler = canConsentOnBehalf
    ? handleResponseForUser(user.id)
    : (_: ConsentResponse) => () => { /*this is a dummy handler*/ }

  const renderConsentOnBehalf = (): JSX.Element => {
    if (!consentOnBehalf) return null

    return (
      <div className="mx-1 font-small text-ch-gray-500"> {t("asset:consentBy")} <NameUser firstName={consentByUser.firstName} lastName={consentByUser.lastName} /></div>
    )
  }

  const renderComment = (): JSX.Element => {
    const comment = yourConsentComment

    if (!comment) return null

    return (
      <div>
        <h2 className="font-semibold">
          {t("shared:consentComment")}
        </h2>
        <div>
          <MentionFieldReadOnly
            value={comment}
            users={users}
          />
        </div>
      </div>
    )
  }

  return (
    <li>
      <div className={wrapperClasses}>
        <div>
          <div className="flex flex-1">
            <NameUser
              firstName={user.firstName}
              lastName={user.lastName}
              isPending={user.pending}
            />
            {renderConsentOnBehalf()}
          </div>
          {renderComment()}
        </div>
        <div className="flex justify-between items-center">
          {isCurrentUser ? <div className="flex" > {renderCurrentUser()} </div> : null}
          <div className="flex">
            {renderUserIcon()}
          </div>
        </div>
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={defaultModalStyle}>
          <ModalContainer handleClose={closeModal}>
            <div>
              <div>
                {t("asset:addConsentCommentHeader")}
              </div>
              <div>
                <div className="flex my-2">
                  <IconFolder className="fill-current w-6 h-6 mr-3 text-ch-purple-500" />
                  <CaseDetailsSimple caseId={props.caseId} />
                </div>
                <Formik
                  initialValues={{ body: yourConsentComment || "" }}
                  validationSchema={YourConsentValidation}
                  onSubmit={(values, { setSubmitting }) => {
                    handleComment(values.body)
                    setSubmitting(false)
                    setIsOpen(false)
                  }}
                >
                  {({
                    isSubmitting,
                    setFieldValue,
                    errors,
                    values,
                    touched,
                    handleBlur,
                    isValid,
                    dirty
                  }) => {
                    return (
                      <Form className="w-full">
                        <div className="mb-3">
                          <Label name="body" required={true}>
                            {t("asset:comment")}
                          </Label>
                          <MentionField
                            name="body"
                            onBlur={handleBlur}
                            users={props.mentionableUsers}
                            value={values.body}
                            setValue={(body) => setFieldValue("body", body)}
                          />

                          <FieldError errorMessage={errors.body as string} isVisible={errors.body && touched.body} />
                        </div>

                        <Buttons
                          className="mb-2"
                          buttons={[
                            <Button
                              action={closeModal}
                              variant={StyleVariants.BLUE_ALT}
                              key="1"
                            >
                              {t("shared:cancel")}
                            </Button>,
                            <Button
                              isDisabled={isSubmitting || !isValid || !dirty}
                              variant={StyleVariants.PURPLE}
                              isSubmit={true}
                              key="2"
                            >
                              {t("asset:saveComment")}
                            </Button>
                          ]}
                        />
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </div>
          </ModalContainer>
        </Modal>
      </div>
    </li >
  )
}

export default RecommendationConsentItem
