import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../app/appTypes"
import { fetchMeetingsAsync, toggleListView } from "../../features/meetings/state/meetingsActions"
import { IMeetingsUIState } from "../../features/meetings/state/meetingsTypes"
import { Trans, useTranslation } from "react-i18next"
import Pagination from "../../shared/components/search/Pagination"
import MeetingList from "../../features/meetings/components/list/MeetingList"
import { StyleVariants } from "../../shared/state/sharedTypes"
import MeetingFilter from "../../features/meetings/components/search/MeetingFilter"
import QuickView from "../../shared/components/ui/quickView/QuickView"
import MeetingQuickView from "../../features/meetings/components/quickView/MeetingQuickView"
import { RoutePaths } from "../../app/routes/Routes"
import { getMeetingsUI, getQuickViewResourceId } from "../../shared/selectors/ui"
import PageTitle from "../../shared/components/ui/PageTitle"
import SearchInput from "../../shared/components/search/SearchInput"
import ResultsPerPage from "../../shared/components/search/ResultsPerPage"
import { MeetingsOrderByOptions } from "../../features/meetings/state/meetingsTypes"
import OrderBy from "../../shared/components/search/OrderBy"
import Button from "../../shared/components/buttons/Button"
import classNames from "classnames"
import MeetingCalendar from "../../features/meetings/components/calendar/MeetingCalendar"
import MeetingCalendarKey from "../../features/meetings/components/calendar/MeetingCalendarKey"
import { IconAssetMenu, IconCalendar } from "../../svg/icons"
import { getCurrentUser } from "../../shared/selectors/user"
import useSearch, { ISearchQuery } from "../../shared/hooks/useSearch"
import { IUser } from "../../features/user/state/userTypes"
import history from "../../app/routes/history"
import { isGuest } from "../../shared/helpers/userHelpers"
import useWindowSize from "../../shared/hooks/useWindowSize"

const MeetingsPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["shared", "meeting", "case", "user"])
  const dispatch = useDispatch()
  const currentUser: IUser = useSelector((state: IAppState) => getCurrentUser(state))
  const UI: IMeetingsUIState = useSelector((state: IAppState) => getMeetingsUI(state))
  const quickViewResourceId = useSelector((state: IAppState) => getQuickViewResourceId(state))
  const onToggleListView = (isListView: boolean) => dispatch(toggleListView(isListView))
  const initialSearchQuery: ISearchQuery = {
    page: 1,
    appliedFilters: UI.appliedFilters,
    searchTerm: UI.searchTerm,
    resultsPerPage: UI.resultsPerPage,
    orderBy: UI.orderBy
  }
  const [searchQuery, setSearchQuery] = useSearch(initialSearchQuery, fetchMeetingsAsync)
  const { width } = useWindowSize()

  const hideCalendar = width == undefined || width < 768 || UI.isListView
  const hideMeetingList = !hideCalendar

  useEffect(() => {
    if (currentUser && isGuest(currentUser)) history.replace("/")
  }, [currentUser]
  )

  return (
    <div className="h-full">
      <div className={classNames(
        "h-full",
        "flex",
        "flex-col",
        {
          "hidden": hideCalendar
        }
      )}>
        <div className="flex justify-between">
          <PageTitle
            pageTitle={t("shared:meetings")}
            buttonText={t("meeting:addNewMeeting")}
            buttonHref={RoutePaths.MEETING_NEW}
            hasButtonPermission={currentUser.abilities?.meeting.canCreate}
          />
          <div className="hidden md:block">
            <Button
              action={() => onToggleListView(!UI.isListView)}
              variant={StyleVariants.BLUE}
            >
              <div className="flex flex-row">
                <IconAssetMenu className="text-white self-center mx-2 w-5 h-5" />
                <span className="hidden sm:inline">
                  {t("meeting:listView")}
                </span>
              </div>
            </Button>
          </div>
        </div>
        <div className="flex flex-1 flex-col-reverse lg:flex-row">
          <div className="flex-col flex-grow">
            <MeetingCalendar isVisible={!UI.isListView} />
          </div>
          <div className="flex-col">
            <MeetingCalendarKey />
          </div>
        </div>
      </div>

      <div className={classNames("", {
        "hidden": hideMeetingList
      })}>
        <div className="flex justify-between">
          <PageTitle
            pageTitle={t("shared:meetings")}
            buttonText={t("meeting:addNewMeeting")}
            buttonHref={RoutePaths.MEETING_NEW}
            hasButtonPermission={currentUser.abilities?.meeting.canCreate}
          />
          <div className="hidden md:block">
            <Button
              action={() => onToggleListView(!UI.isListView)}
              variant={StyleVariants.BLUE}
            >
              <div className="flex flex-row">
                <IconCalendar className="text-white self-center mx-2 w-5 h-5" />
                <span className="hidden sm:inline">
                  {t("meeting:calendarView")}
                </span>
              </div>
            </Button>
          </div>
        </div>

        <div className="grid grid-rows-2 md:grid-rows-1 flex items-stretch">
          <div className="row-start-2 md:row-start-1 self-end md:self-center">
            <Trans
              i18nKey="meeting:searchBy"
              components={{
                strong: <strong />
              }}
            />
          </div>
          <div className="row-end-2 md:row-start-1 flex justify-end">
            <ResultsPerPage
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
        <div className="mb-2">
          <SearchInput
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            buttonText={t("meeting:searchMeetings")}
          />
        </div>

        <h2 className="text-lg mb-2 text-center md:text-left">
          {UI.totalMeetingsCount} {t("shared:meetings")}
        </h2>
        <div className="grid grid-flow-row md:grid-flow-col">
          <div>
            <MeetingFilter
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
          <div className="flex justify-end">
            <OrderBy
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              orderByOptions={MeetingsOrderByOptions}
              useTranslation={"meeting"}
            />
          </div>
        </div>
        <MeetingList />
        <Pagination
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          totalItems={UI.totalMeetingsCount}
          totalPages={UI.totalPages}
        />
      </div>
      <QuickView>
        <MeetingQuickView meetingId={quickViewResourceId} />
      </QuickView>
    </div>
  )
}

export default MeetingsPage
