import { takeLeading, put, call } from "redux-saga/effects"
import _ from "lodash"
import { WebRTCActionTypes } from "../webRTCTypes"
import { getAudioDevices, getAudioSource, setAudioSource, togglePublisherAudio } from "../../../../apis/webRTC/webRTC"
import { handleError, logError } from "../../../../shared/helpers/sagaHelpers"

export function* toggleWebRTCPublishAudioAsync(action) {
  try {
    yield call(togglePublisherAudio, action.payload.publisher, action.payload.isSharingAudio)

    yield put({
      type: WebRTCActionTypes.TOGGLE_WEB_RTC_PUBLISHER_AUDIO_SUCCESS
    })
  } catch (error) {
    yield logError("error on toggling audio", error)
    yield handleError(WebRTCActionTypes.TOGGLE_WEB_RTC_PUBLISHER_AUDIO_ERROR, error, "publishWebRTCAudioError")
  }
}

export function* watchToggleWebRTCPublishAudioAsync() {
  yield takeLeading(WebRTCActionTypes.TOGGLE_WEB_RTC_PUBLISHER_AUDIO_PENDING, toggleWebRTCPublishAudioAsync)
}

export function* getWebRTCAudioDevicesAsync(_action) {
  try {
    const devices = yield call(getAudioDevices)

    yield put({
      type: WebRTCActionTypes.GET_WEB_RTC_AUDIO_DEVICES_SUCCESS,
      payload: {
        devices
      }
    })
  } catch (error) {
    yield logError("error getting audio devices", error)
    yield handleError(WebRTCActionTypes.GET_WEB_RTC_AUDIO_DEVICES_ERROR, error, "getWebRTCAudioDevicesError")
  }
}

export function* watchGetWebRTCAudioDevicesAsync() {
  yield takeLeading(WebRTCActionTypes.GET_WEB_RTC_AUDIO_DEVICES_PENDING, getWebRTCAudioDevicesAsync)
}

export function* setWebRTCAudioSourceAsync(action) {
  try {
    yield call(setAudioSource, action.payload.publisher, action.payload.deviceId)

    yield put({
      type: WebRTCActionTypes.SET_WEB_RTC_AUDIO_SOURCE_SUCCESS,
      payload: {
        deviceId: action.payload.deviceId
      }
    })
  } catch (error) {
    yield logError("error setting audio source", error)
    yield handleError(WebRTCActionTypes.SET_WEB_RTC_AUDIO_SOURCE_ERROR, error, "setWebRTCAudioSourceError")
  }
}

export function* watchSetWebRTCAudioSourceAsync() {
  yield takeLeading(WebRTCActionTypes.SET_WEB_RTC_AUDIO_SOURCE_PENDING, setWebRTCAudioSourceAsync)
}

export function* getWebRTCAudioSourceAsync(action) {
  try {
    const device: OT.Device = yield call(getAudioSource, action.payload.publisher)

    yield put({
      type: WebRTCActionTypes.GET_WEB_RTC_AUDIO_SOURCE_SUCCESS,
      payload: {
        device: device
      }
    })
  } catch (error) {

    yield logError("error getting audio source", error)
    yield handleError(WebRTCActionTypes.GET_WEB_RTC_AUDIO_SOURCE_ERROR, error, "getWebRTCAudioSourceError")
  }
}

export function* watchGetWebRTCAudioSourceAsync() {
  yield takeLeading(WebRTCActionTypes.GET_WEB_RTC_AUDIO_SOURCE_PENDING, getWebRTCAudioSourceAsync)
}
