/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field } from "formik"
import React from "react"

interface IProps {
  className?: string
  name: string
  value?: string
  type?: string
  autoFocus?: boolean
  autoComplete?: string
  innerRef?: React.RefObject<HTMLInputElement>
  onChange: (e: React.ChangeEvent<any>) => void
  onBlur: (e: React.FocusEvent<any>) => void
  onFocus?: (e: React.FocusEvent<any>) => void
  as?: string
  skipClasses?: string
  maxLength?: number
  isDisabled?: boolean
}

const defaultProps = {
  isDisabled: false
}

const InputField: React.FC<IProps> = (props: IProps): JSX.Element => {
  const defaultClasses = ["flex", "w-full", "text-ch-gray-600", "py-2", "px-3", "rounded", "border", "border-ch-blue-alt-300", "leading-tight", "focus:outline-none", "focus:shadow-outline", "appearance-none", "focus:ring-2", "ring-ch-blue-alt-300"]
  const propClasses = props.className ? props.className.split(" ") : []
  const skipClasses = props.skipClasses ? props.skipClasses.split(" ") : []
  const allClasses = [...defaultClasses, ...propClasses]
  const filteredClasses = allClasses.filter((cls) => !skipClasses.includes(cls)).join(" ")
  const _type = props.type ? props.type : "text"
  return (
    <Field
      innerRef={props.innerRef}
      autoFocus={props.autoFocus}
      type={_type}
      name={props.name}
      id={props.name}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      value={props.value}
      autoComplete={props.autoComplete}
      className={filteredClasses}
      maxLength={props.maxLength}
      as={props.as}
      disabled={props.isDisabled}
    />
  )
}

InputField.defaultProps = defaultProps

export default InputField
