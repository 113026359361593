import { useFormikContext } from "formik"
import React, { useState } from "react"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import Button from "../../../shared/components/buttons/Button"
import FieldError from "../../../shared/components/forms/FieldError"
import InputField from "../../../shared/components/forms/InputField"
import Label from "../../../shared/components/forms/Label"
import SelectField from "../../../shared/components/forms/SelectField"
import { TSelectFieldOptions } from "../../../shared/components/forms/StandardSelectField"
import { buildUserOptions, filterOptionsBySearchValue } from "../../../shared/components/label/UserOption"
import { generateSpecialtyOptions } from "../../../shared/helpers/selectOptionHelpers"
import { getAllSpecialties } from "../../../shared/selectors/specialties"
import { getAllInternalActiveUsersSorted } from "../../../shared/selectors/user"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import { ITeamNewData } from "../../team/state/teamTypes"

const TeamNewFormContent: React.FC = () => {
  const {
    isSubmitting,
    errors,
    values,
    touched,
    handleChange,
    handleBlur
  } = useFormikContext<ITeamNewData>()

  const [userOptions, setUserOptions] = useState([])
  const [membersOptions, setMembersOptions] = useState([])
  const [ownerOptions, setOwnerOptions] = useState([])
  const [isUsersPopulated, setIsUsersPopulated] = useState(false)
  const [specialtiesOptions, setSpecialtiesOptions] = useState([])
  const users = useSelector(getAllInternalActiveUsersSorted)
  const specialties = useSelector(getAllSpecialties)
  const { t } = useTranslation(["team"])

  useEffect(() => {
    const options: TSelectFieldOptions = buildUserOptions(users)
    setUserOptions(options)
    if (!isUsersPopulated) {
      setMembersOptions(options)
      setOwnerOptions(options)
    }
  }, [users])

  useEffect(() => {
    const options: TSelectFieldOptions = generateSpecialtyOptions(
      specialties
    )
    setSpecialtiesOptions(options)
  }, [specialties])

  return (<><div className="mb-2">
    <Label name="name" required={true}>
      {t("team:title")}
    </Label>
    <InputField
      type="text"
      name="name"
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.name}
      autoComplete="off"
      autoFocus={true}
    />
    <FieldError
      errorMessage={errors.name as string}
      isVisible={(errors.name && touched.name) as boolean}
    />
  </div>

    <div className="mb-2">
      <Label name="specialtyId" required={true}>
        {t("team:specialty")}
      </Label>
      <SelectField
        name="specialtyId"
        options={specialtiesOptions}
        onChange={handleChange}
        onBlur={handleBlur}
        isGrouped={true}
      />
      <FieldError
        errorMessage={errors.specialtyId as string}
        isVisible={
          (errors.specialtyId && touched.specialtyId) as boolean
        }
      />
    </div>

    <div className="mb-2">
      <Label name="ownerId" required={true}>
        {t("team:owner")}
      </Label>
      <SelectField
        name="ownerId"
        options={ownerOptions}
        onChange={(e) => {
          handleChange(e)
          const selectedValue = e.currentTarget.value
          const newMemberOptions = userOptions.filter(
            (item) => item.value !== selectedValue
          )
          setMembersOptions(newMemberOptions)
          setIsUsersPopulated(true)
        }}
        filterOption={filterOptionsBySearchValue}
        onBlur={handleBlur}
      />
      <FieldError
        errorMessage={errors.ownerId as string}
        isVisible={(errors.ownerId && touched.ownerId) as boolean}
      />
    </div>

    <div className="mb-2">
      <Label name="teamUsers" required={true}>
        {t("team:members")}
      </Label>
      <SelectField
        name="teamUsers"
        options={membersOptions}
        onChange={(e) => {
          handleChange(e)
          const selectedValue = e.currentTarget.value
          const newOwnerOptions = userOptions.filter(
            (item) => !selectedValue.includes(item.value)
          )
          setOwnerOptions(newOwnerOptions)
          setIsUsersPopulated(true)
        }}
        onBlur={handleBlur}
        isMulti={true}
        closeMenuOnSelect={false}
        filterOption={filterOptionsBySearchValue}
      />
      <FieldError
        errorMessage={errors.teamUsers as string}
        isVisible={(errors.teamUsers && touched.teamUsers) as boolean}
      />
    </div>

    <div className="flex justify-center">
      <Button
        isDisabled={isSubmitting}
        variant={StyleVariants.PURPLE}
        isSubmit={true}
      >
        {t("Create Team")}
      </Button>
    </div>

  </>
  )
}

export default TeamNewFormContent
