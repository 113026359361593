import { takeLeading, put, Effect } from "redux-saga/effects"
import { AssetsActionTypes } from "../assetsTypes"
import { assetsSchema, patientRadiologyLinkSchema } from "../../../../apis/contextmeeting/schema"
import { fetchMeetingCaseAction } from "../../../meetingCase/state/meetingCaseActions"
import { getNormalisedSingle, handleError, handleSuccess, postNormalisedSingle } from "../../../../shared/helpers/sagaHelpers"

export function* watchCreateLinkAsync(): Generator {
  yield takeLeading(AssetsActionTypes.CREATE_CASE_LINK_ASYNC_PENDING, createLinkAsync)
  yield takeLeading(AssetsActionTypes.CREATE_CASE_RADIOLOGY_LINK_ASYNC_PENDING, createRadiologyLinkAsync)
  yield takeLeading(AssetsActionTypes.FETCH_PATIENT_RADIOLOGY_LINKS_ASYNC_PENDING, fetchPatientRadiologyLinksAsync)
}

export function* createLinkAsync(action: Effect): Generator {
  try {
    const caseId = action.payload.caseId
    const data = {
      link: action.payload.link,
      meetingCaseId: action.payload.meetingCaseId
    }

    const normalizedResponse = yield postNormalisedSingle(`/cases/${caseId}/links`, data, assetsSchema)
    const payload = {
      all: normalizedResponse,
      caseId: caseId
    }
    yield handleSuccess(AssetsActionTypes.CREATE_CASE_LINK_ASYNC_SUCCESS, payload, "createLinkSuccess")

    if (action.payload.meetingCaseId) {
      yield put(fetchMeetingCaseAction(action.payload.meetingCaseId))
    }

  } catch (error) {
    yield handleError(AssetsActionTypes.CREATE_CASE_LINK_ASYNC_ERROR, error, "createLinkFail")
  }
}

export function* fetchPatientRadiologyLinksAsync(action: Effect): Generator {
  try {
    const { caseId } = action.payload
    const normalizedResponse = yield getNormalisedSingle(`/radiology/by_case/${caseId}`, [patientRadiologyLinkSchema])
    yield handleSuccess(AssetsActionTypes.FETCH_PATIENT_RADIOLOGY_LINKS_ASYNC_SUCCESS, normalizedResponse)

  } catch (error) {
    yield handleError(AssetsActionTypes.FETCH_PATIENT_RADIOLOGY_LINKS_ASYNC_ERROR, error, "fetchPatientLinksFail")
  }
}

export function* createRadiologyLinkAsync(action: Effect): Generator {
  try {
    const caseId = action.payload.caseId
    const data = {
      radiologyLink: action.payload.radiologyLink
    }

    const normalizedResponse = yield postNormalisedSingle(`/cases/${caseId}/radiology_links`, data, assetsSchema)
    const payload = {
      all: normalizedResponse,
      caseId: caseId
    }
    yield handleSuccess(AssetsActionTypes.CREATE_CASE_LINK_ASYNC_SUCCESS, payload, "createLinkSuccess")

    if (action.payload.meetingCaseId) {
      yield put(fetchMeetingCaseAction(action.payload.meetingCaseId))
    }

  } catch (error) {
    yield handleError(AssetsActionTypes.CREATE_CASE_LINK_ASYNC_ERROR, error, "createLinkFail")
  }
}
