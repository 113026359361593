import { schema } from "normalizr"

// we ran into some issues with the schemas, so we (David and I) decided on the
// following rule: we define a schema for each endpoint, where we only recycle
// simple schemas, with no nested entities. This avoids issues with the various
// schemas being different in different contexts (eg a meeting is different when
// fetched from the /meetings endpoint from when received through a
// /meeting_cases/:id request )

export const patientRadiologyLinkSchema = new schema.Entity("patientRadiologyLink", {}, { idAttribute: "fileUuid" })
export const userSchema = new schema.Entity("users", {})
export const meetingUserSchema = new schema.Entity("meetingUsers", {})
export const patientsSchema = new schema.Entity("patients", {
  cases: [new schema.Entity("cases", {
    leadCareUser: userSchema,
  })]
})
export const assetsSchema = new schema.Entity("assets", {})
export const teamUserSchema = new schema.Entity("teamUsers", {})
export const auditEventsSchema = new schema.Entity("auditEvents", {})
export const pathwaysSchema = new schema.Entity("pathways", {
  users: [userSchema]
})
export const messageAssetsSchema = new schema.Entity("messageAssets", {})

export const caseClassificationsSchema = new schema.Entity("caseClassifications", {
  children: [new schema.Entity("caseClassifications", {})]
})

export const specialtySchema = new schema.Entity("specialties", {
  children: [new schema.Entity("specialties", {})]
})

export const taskResponsesSchema = new schema.Entity("taskResponses", {
  asset: assetsSchema
})

export const tasksSchema = new schema.Entity("tasks", {
  users: [userSchema],
  taskResponses: [taskResponsesSchema]
})

export const casePathwaySchema = new schema.Entity("casePathways", {
  tasks: [tasksSchema]
})

export const notificationsSchema = new schema.Entity("notifications", {
  users: [userSchema]
})

export const fetchCaseSchema = new schema.Entity("cases", {
  leadCareUser: userSchema,
  meetings: [new schema.Entity("meetings", {
    users: [userSchema],
    meetingUsers: [meetingUserSchema],
  })],
  members: [userSchema],
  meetingCases: [new schema.Entity("meetingCases", {
    case: new schema.Entity("cases", {}),
    meeting: new schema.Entity("meetings", {})
  })]
})

export const fetchMeetingsSchema = new schema.Entity("meetings", {
  users: [userSchema],
  meetingCases: [new schema.Entity("meetingCases")]
})

export const fetchMeetingSchema = new schema.Entity("meetings", {
  meetingCases: [new schema.Entity("meetingCases", {
    case: new schema.Entity("cases", {
      leadCareUser: userSchema,
      meetings: [new schema.Entity("meetings", {})]
    }),
    meeting: new schema.Entity("meetings", {})
  })],
  meetingUsers: [meetingUserSchema],
  users: [userSchema]
})

export const fetchMeetingCaseSchema = new schema.Entity("meetingCases", {
  meeting: new schema.Entity("meetings", {
    meetingCases: [new schema.Entity("meetingCases", {})],
    users: [userSchema]
  }),

  case: new schema.Entity("cases", {
    leadCareUser: userSchema,
    meetings: [new schema.Entity("meetings", {})]
  }),

  // meetingCaseAssets included as array of objects
})

export const fetchTeamSchema = new schema.Entity("teams", {
  teamUsers: [teamUserSchema],
  users: [userSchema],
  owner: userSchema
})

export const fetchChatsSchema = new schema.Entity("chats", {
  users: [userSchema],
  case: new schema.Entity("cases", {
    leadCareUser: userSchema
  }),
  team: new schema.Entity("teams", {
    teamUsers: [teamUserSchema]
  })
})

export const fetchMessagesSchema = new schema.Entity("messages", {
  messageAssets: [messageAssetsSchema]
})

export const caseSourcesSchema = new schema.Entity("caseSources", {
  caseSources: {}
})
