import { takeLeading, put, call } from "redux-saga/effects"
import _ from "lodash"
import { WebRTCActionTypes } from "../webRTCTypes"
import { cycleVideoSource, togglePublisherVideo } from "../../../../apis/webRTC/webRTC"
import { handleError, logError } from "../../../../shared/helpers/sagaHelpers"

export function* toggleWebRTCPublishVideoAsync(action) {
  try {
    yield call(togglePublisherVideo, action.payload.publisher, action.payload.isSharingVideo)

    yield put({
      type: WebRTCActionTypes.TOGGLE_WEB_RTC_PUBLISHER_VIDEO_SUCCESS
    })
  } catch (error) {
    yield logError("error publishing video", error as Error)
    yield handleError(WebRTCActionTypes.TOGGLE_WEB_RTC_PUBLISHER_VIDEO_ERROR, error, "publishWebRTCVideoError")

  }
}

export function* watchToggleWebRTCPublishVideoAsync() {
  yield takeLeading(WebRTCActionTypes.TOGGLE_WEB_RTC_PUBLISHER_VIDEO_PENDING, toggleWebRTCPublishVideoAsync)
}

// Publisher Video Source
export function* cycleWebRTCVideoSourceAsync(action) {
  try {
    yield call(cycleVideoSource, action.payload.publisher)

    yield put({
      type: WebRTCActionTypes.CYCLE_WEB_RTC_VIDEO_SOURCE_SUCCESS
    })
  } catch (error) {
    yield logError("error cycling video media", error as Error)
    yield handleError(WebRTCActionTypes.CYCLE_WEB_RTC_VIDEO_SOURCE_ERROR, error, "cycleWebRTCVideoSourceError")
  }
}

export function* watchCycleWebRTCVideoSourceAsync() {
  yield takeLeading(WebRTCActionTypes.CYCLE_WEB_RTC_VIDEO_SOURCE_PENDING, cycleWebRTCVideoSourceAsync)
}
