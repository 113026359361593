import React, { ReactNode, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { RoutePaths } from "../../app/routes/Routes"
import Breadcrumbs from "../../shared/components/ui/Breadcrumbs"
import { IAppState } from "../../app/appTypes"
import Wizard from "../../shared/components/ui/wizard/Wizard"
import { IconAcceptThin, IconExclamation, IconPatient } from "../../svg/icons"
import PatientDetailsForm from "../../features/patientNew/components/patientDetails/PatientDetailsForm"
import { getCurrentUser } from "../../shared/selectors/user"
import { IPatientEditData } from "../../features/patientEdit/state/patientEditTypes"
import { AmendmentTypes, IHavePatientId, IHavePatientIdParam, IPatient } from "../../features/patient/state/patientTypes"
import { getPatientByParamId } from "../../shared/selectors/patients"
import ReasonForChangeForm from "../../features/patientEdit/components/reasonForChange/ReasonForChangeForm"
import { useParams } from "react-router"
import { fetchPatientAsync } from "../../features/patient/state/patientActions"
import LoadingSpinner from "../../shared/components/ui/LoadingSpinner"
import ReviewAndSaveForm from "../../features/patientEdit/components/reasonForChange/ReviewAndSaveForm"

interface IPropsFromParent {
  children?: ReactNode
}

type Props = IPropsFromParent & IHavePatientIdParam

const PatientEditDetailsPage = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "patient"])
  const dispatch = useDispatch()
  const currentUser = useSelector((state: IAppState) => getCurrentUser(state))
  const { patientId } = useParams<IHavePatientId>()
  const patient: IPatient = useSelector((state: IAppState) => getPatientByParamId(state, props))
  const [patientEditDetailsData, setPatientEditDetailsData] = useState<IPatientEditData>(null)

  useEffect(() => {
    dispatch(fetchPatientAsync(patientId))
  }, [])

  useEffect(() => {
    if (!patient) return
    setPatientEditDetailsData({
      title: patient.title,
      firstName: patient.firstName,
      lastName: patient.lastName,
      gender: patient.gender,
      dateOfBirth: patient.dateOfBirth,
      healthcareId: patient.healthcareId,
      careProviderId: patient.careProviderId || "",
      patientAmendmentsAttributes: [{
        reason: "",
        amendmentType: AmendmentTypes.ErrorCorrection
      }]
    })
  }, [patient])

  const renderWizard = (): JSX.Element => {
    if (!currentUser.abilities?.patient.canCreate) return <h3 className="text-lg">{t("shared:notPermitted")}</h3>

    if (!patientEditDetailsData) return <LoadingSpinner />

    return <Wizard
      data={patientEditDetailsData}
      setData={setPatientEditDetailsData}
      steps={[
        {
          name: t("patient:editPatientDetails:sectionTitle"),
          icon: IconPatient,
          component: PatientDetailsForm,
          nextButtonText: t("shared:next"),
          previousButtonText: t("shared:previous"),
          updateRequiredBeforeNext: true
        },
        {
          name: t("patient:reasonForChange:sectionTitle"),
          icon: IconExclamation,
          component: ReasonForChangeForm,
          nextButtonText: t("shared:next"),
          previousButtonText: t("shared:previous")
        },
        {
          name: t("patient:reviewAndSave:sectionTitle"),
          icon: IconAcceptThin,
          component: ReviewAndSaveForm,
          nextButtonText: t("patient:reviewAndSave:buttons:savePatient"),
          previousButtonText: t("shared:previous")
        }
      ]}
    />
  }

  const renderContent = (): JSX.Element => {
    if (!patient) return <h3 className="text-lg">{t("patient:messages:patientNotFound")}</h3>
    return renderWizard()
  }

  return (
    <div className="h-full flex flex-col">
      <Breadcrumbs
        breadcrumbs={[
          {
            pageTitle: t("shared:patients"),
            href: RoutePaths.PATIENTS
          },
          {
            pageTitle: t("patient:editPatientDetails:sectionTitle")
          }
        ]}
      />
      {renderContent()}
    </div>
  )
}

export default PatientEditDetailsPage
