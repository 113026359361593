import React from "react"
import classNames from "classnames"
import { Link, useLocation } from "react-router-dom"
import { toggleIsMenuOpen } from "../state/navActions"
import { MockStore } from "redux-mock-store"
import { useAppDispatch } from "../../../shared/hooks"

interface IPropsFromParent {
  className?: string;
  to?: string
  action?: () => void
  children: React.ReactNode
  store?: MockStore
}

interface IPropsFromDispatch {
  onClickLink: () => void
}

type Props = IPropsFromParent & IPropsFromDispatch

const MenuLink: React.FC<Props> = (props: Props): JSX.Element => {
  const location = useLocation()
  const isSelected = (props.to === location.pathname)
  const dispatch = useAppDispatch()
  const clickHandler = () => dispatch(toggleIsMenuOpen(false))

  const defaultClasses = [
    "py-3",
    "px-4",
    "rounded-md",
    "flex",
    "w-full"
  ]
  const propClasses = props.className ? props.className.split(" ") : []
  const allClasses = [...defaultClasses, ...propClasses].join(" ")

  const allClassesWithConditions = classNames(allClasses, {
    "text-ch-gray-500": !isSelected,
    "text-white": isSelected,
    "bg-ch-blue-700": isSelected,
    "hover:bg-ch-blue-700": !isSelected,
    "hover:text-white": !isSelected,
  })

  if (props.action) {
    return (
      <button
        onClick={() => {
          props.onClickLink()
          props.action()
        }}
        className={allClassesWithConditions}
      >
        {props.children}
      </button>
    )
  }
  return (
    <Link
      to={props.to}
      className={allClassesWithConditions}
      onClick={clickHandler}
    >
      {props.children}
    </Link>
  )
}

export default MenuLink
