import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { RoutePaths } from "../../app/routes/Routes"
import Breadcrumbs from "../../shared/components/ui/Breadcrumbs"
import { IAppState } from "../../app/appTypes"
import Wizard from "../../shared/components/ui/wizard/Wizard"
import PatientCheckForm from "../../features/patientNew/components/patientCheck/PatientCheckForm"
import { IconAcceptThin, IconFolder, IconFurtherDetails, IconPatient, IconQuestion } from "../../svg/icons"
import PatientDetailsForm from "../../features/patientNew/components/patientDetails/PatientDetailsForm"
import FurtherDetailsForm from "../../features/patientNew/components/furtherDetails/FurtherDetailsForm"
import { IPatientNewData } from "../../features/patientNew/state/patientNewTypes"
import CaseDetailsForm from "../../features/patientNew/components/caseDetails/CaseDetailsForm"
import ReviewAndSaveForm from "../../features/patientNew/components/reviewAndSave/ReviewAndSaveForm"
import { getCurrentUserAbilities } from "../../shared/selectors/user"

const PatientNewPage = (): JSX.Element => {
  const { t } = useTranslation(["shared", "patient"])
  const currentAbilities = useSelector((state: IAppState) => getCurrentUserAbilities(state))

  const [patientNewData, setPatientNewData] = useState<IPatientNewData>({
    title: "",
    firstName: "",
    lastName: "",
    gender: "",
    dateOfBirth: "",
    healthcareId: "",
    careProviderId: "",
    furtherDetailsAttributes: {
      id: null,
      postcode: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      county: "",
      country: "",
      email: "",
      phoneNumber: "",
      phoneNumberAlt: "",
      gpPostcode: "",
      gpPracticeName: "",
      gpAddressLine1: "",
      gpAddressLine2: "",
      gpCity: "",
      gpCounty: "",
      gpCountry: "",
      gpEmail: "",
      gpPhoneNumber: "",
      gpName: "",
    },
    casesAttributes: [{
      caseType: "",
      caseClassificationId: "",
      leadCareUserId: "",
      signedOffBy: "",
      targetDate: "",
      sourceId: ""
    }]
  })

  const renderWizard = (): JSX.Element => {
    if(!currentAbilities?.patient.canCreate) return <h3 className="text-lg">{ t("shared:notPermitted") }</h3>

    return <Wizard
      data={patientNewData}
      setData={setPatientNewData}
      steps={[
        {
          name: t("patient:checkDetails:sectionTitle"),
          icon: IconQuestion,
          component: PatientCheckForm,
          nextButtonText: t("shared:next")
        },
        {
          name: t("patient:details:newPatientTitle"),
          icon: IconPatient,
          component: PatientDetailsForm,
          nextButtonText: t("shared:next"),
          previousButtonText: t("shared:previous")
        },
        {
          name: t("patient:furtherDetails:sectionTitle"),
          icon: IconFurtherDetails,
          component: FurtherDetailsForm,
          nextButtonText: t("shared:next"),
          previousButtonText: t("shared:previous")
        },
        {
          name: t("patient:caseDetails:sectionTitle"),
          icon: IconFolder,
          component: CaseDetailsForm,
          nextButtonText: t("patient:caseDetails:buttons:createCase"),
          previousButtonText: t("shared:previous"),
          hasSkip: true
        },
        {
          name: t("patient:reviewAndSave:sectionTitle"),
          icon: IconAcceptThin,
          component: ReviewAndSaveForm,
          nextButtonText: t("patient:reviewAndSave:buttons:savePatient"),
          previousButtonText: t("shared:previous")
        }
      ]}
    />
  }

  return (
    <div className="h-full flex flex-col">
      <Breadcrumbs
        breadcrumbs={[
          {
            pageTitle: t("shared:patients"),
            href: RoutePaths.PATIENTS
          },
          {
            pageTitle: t("patient:addNew:sectionTitle")
          }
        ]}
      />
      { renderWizard() }
    </div>
  )
}

export default PatientNewPage
