import React from "react"

interface IPropsFromParent {
  children: React.ReactNode
  className?: string
  width?: string
}

const defaultProps = {
  width: "w-2/3"
}

type IProps = IPropsFromParent

const DetailsCardInner: React.FC<IProps> = (props: IProps): JSX.Element => {
  const defaultClasses = [
    "flex-1",
    "flex",
    "flex-col",
    "sm:flex-row",
    "mb-2",
    "text-center",
    "sm:text-left",
    "sm:w-full",
    `md:${props.width}`,
    "md:mb-0",
    "md:mr-4",
    "items-center"
  ]

  const propClasses = props.className ? props.className.split(" ") : []
  const allClasses = [...defaultClasses, ...propClasses].join(" ")

  return (
    <div className={allClasses}>
      {props.children}
    </div>
  )
}

DetailsCardInner.defaultProps = defaultProps

export default DetailsCardInner
