import { takeLeading, call, Effect } from "redux-saga/effects"
import { UserConfigActionTypes } from "./userConfigTypes"
import API from "../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../apis/contextmeeting/withAuthHeader"
import history from "../../../app/routes/history"
import { RoutePaths } from "../../../app/routes/Routes"
import { handleError, handleSuccess } from "../../../shared/helpers/sagaHelpers"

export function* watchUserConfigActions(): Generator {
  yield takeLeading(UserConfigActionTypes.FETCH_USER_CONFIG_ASYNC_PENDING, fetchUserConfigAsync)
  yield takeLeading(UserConfigActionTypes.UPDATE_USER_CONFIG_ASYNC_PENDING, updateUserConfigAsync)
  yield takeLeading(UserConfigActionTypes.UPDATE_UI_SETTING_ASYNC_PENDING, updateUISettingAsync)
}

export function* fetchUserConfigAsync(action: Effect): Generator {
  try {
    const userId = action.payload
    const response: any = yield call(API.get, `/users/${userId}/user_config`, withAuthHeader())
    yield handleSuccess(UserConfigActionTypes.FETCH_USER_CONFIG_ASYNC_SUCCESS, response.data)
  } catch (error) {
    yield handleError(UserConfigActionTypes.FETCH_USER_CONFIG_ASYNC_ERROR, error)
  }
}

export function* updateUserConfigAsync(action: Effect): Generator {
  const user = {
    user: action.payload.user
  }

  try {
    const response: any = yield call(API.patch, `/users/${action.payload.id}/user_config`, user, withAuthHeader())
    yield handleSuccess(UserConfigActionTypes.UPDATE_USER_CONFIG_ASYNC_SUCCESS, response.data, "updateUserSuccess")

    yield call(history.push, RoutePaths.MEETINGS)
  } catch (error) {
    yield handleError(UserConfigActionTypes.UPDATE_USER_CONFIG_ASYNC_ERROR, error, "updateUserFail")
  }
}

export function* updateUISettingAsync(action: Effect): Generator {
  const data = {uiSettings: action.payload.data}
  try {
    const response: any = yield call(API.patch, `/users/${action.payload.id}/user_config/update_ui_setting`, data , withAuthHeader())
    yield handleSuccess(UserConfigActionTypes.UPDATE_UI_SETTING_ASYNC_SUCCESS, response.data)
  } catch (error) {
    yield handleError(UserConfigActionTypes.UPDATE_UI_SETTING_ASYNC_ERROR, error)
  }
}
