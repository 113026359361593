import React, { ReactNode, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { MockStore } from "redux-mock-store"
import { connectToWebRTCSessionAction, addWebRTCStreamAction, removeWebRTCStreamAction, updateWebRTCStreamAction, updateWebRTCScreenStreamAction, disconnectFromWebRTCSessionAction, updateWebRTCSessionAction } from "../state/webRTCActions"
import { sessionEventHandlers, webRTCEvent, webRTCStreamPropertyChangedEvent } from "../state/webRTCTypes"
import { getStreamUserId } from "../../../apis/webRTC/webRTC"
import { displayUserMessageAction } from "../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../userMessage/state/userMessageTypes"
import { toggleIsOnlineAction, toggleIsPresentAction, toggleIsSharingAudioAction, toggleIsSharingVideoAction } from "../../liveMeeting/state/liveMeetingActions"
import { getCurrentUser } from "../../../shared/selectors/user"
import { getWebRTCSession } from "../../../shared/selectors/webRTC"

interface IProps {
  store?: MockStore
  webRtcApiKey: string
  sessionId: string
  meetingToken: string
  children: ReactNode
}


// stream.videoType will be 'screen' when the screen share button is initiated.
// For the PACS, we set the media stream outside TokBox and videoType gets set
// to 'custom'
const streamIsScreen = (stream: OT.Stream) => stream.videoType == "screen" || stream.videoType == "custom"

const WebRTCSession: React.FC<IProps> = (props: IProps): JSX.Element => {
  const dispatch = useDispatch()
  const currentUser = useSelector(getCurrentUser)
  const session = useSelector(getWebRTCSession)

  const displayUserMessage = (messageKey: string, type: string) => {
    dispatch(displayUserMessageAction({
      messageKey,
      type
    }))
  }

  const eventHandlers: sessionEventHandlers = {
    streamCreated: (event: webRTCEvent) => {
      // Another user has joined the session
      const userId = getStreamUserId(event.stream)
      if (streamIsScreen(event.stream)) {
        dispatch(updateWebRTCScreenStreamAction(event.stream))
      } else {
        dispatch(addWebRTCStreamAction(userId, event.stream))
      }
    },
    streamDestroyed: (event: webRTCEvent) => {
      // Another user has left the session
      const userId = getStreamUserId(event.stream)
      if (streamIsScreen(event.stream)) {
        dispatch(updateWebRTCScreenStreamAction(null))
      } else {
        dispatch(removeWebRTCStreamAction(userId))
      }
    },
    streamPropertyChanged: (event: webRTCStreamPropertyChangedEvent) => {
      // A user has toggled their audio or video
      if (streamIsScreen(event.stream)) return

      const userId = getStreamUserId(event.stream)
      dispatch(updateWebRTCStreamAction(userId, event.stream))

      const isCurrentUserStream = (userId == currentUser.id)
      const valueHasChanged = (event.newValue !== event.oldValue)

      if (!isCurrentUserStream || !valueHasChanged) return
      switch (event.changedProperty) {
        case "hasAudio":
          dispatch(toggleIsSharingAudioAction(userId, event.newValue))
          break
        case "hasVideo":
          dispatch(toggleIsSharingVideoAction(userId, event.newValue))
          break
      }
    },
    sessionReconnecting: (event: webRTCEvent) => {
      dispatch(updateWebRTCSessionAction(event.target as OT.Session))

      displayUserMessage("webRTCSessionReconnecting", UserMessageTypes.MESSAGE)
    },
    sessionReconnected: (event: webRTCEvent) => {
      dispatch(updateWebRTCSessionAction(event.target as OT.Session))

      displayUserMessage("webRTCSessionReconnected", UserMessageTypes.SUCCESS)
    },
    sessionDisconnected: (event: webRTCEvent) => {
      if (event.reason == "networkDisconnected") {
        displayUserMessage("webRTCSessionDisconnected", UserMessageTypes.ERROR)
      }
    }
  }

  useEffect(() => {
    // Connect to the webRTC session when sessionId is provided
    if (!props.sessionId) return
    dispatch(connectToWebRTCSessionAction(props.webRtcApiKey, props.sessionId, props.meetingToken, eventHandlers))
  }, [props.sessionId])

  useEffect(() => {
    // Disconnect from the webRTC session when component unmounts and there is an existing connection
    if (!session?.connection) return

    return () => {
      dispatch(disconnectFromWebRTCSessionAction(session))
    }
  }, [session])

  useEffect(() => {
    dispatch(toggleIsPresentAction(currentUser.id, true))
    return () => {
      dispatch(toggleIsOnlineAction(currentUser.id, false))
    }
  }, [])

  return (
    <div>
      {props.children}
    </div>
  )
}

export default WebRTCSession
