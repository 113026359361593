/* slint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import FieldError from "../../../../shared/components/forms/FieldError"
import Label from "../../../../shared/components/forms/Label"
import { getAllInternalActiveUsersSorted } from "../../../../shared/selectors/user"
import { CaseTypes } from "../../state/caseTypes"
import humps from "humps"
import InputField from "../../../../shared/components/forms/InputField"
import { getAllCaseClassifications } from "../../../../shared/selectors/caseClassification"
import SelectField from "../../../../shared/components/forms/SelectField"
import { generateCaseSourceOptions, generateCaseClassificationOptions } from "../../../../shared/helpers/selectOptionHelpers"
import { TSelectFieldOptions, TSelectFieldUserOptions } from "../../../../shared/components/forms/StandardSelectField"
import DatePickerField from "../../../../shared/components/datePicker/DatePickerField"
import CheckmarkToggle from "../../../../shared/components/forms/CheckmarkToggle"
import _ from "lodash"
import { buildUserOptions, filterOptionsBySearchValue } from "../../../../shared/components/label/UserOption"
import { getAllCaseSources } from "../../../../shared/selectors/caseSource"

interface IProps {
  errors
  touched
  values
  handleChange
  handleBlur
  setFieldValue
  signedOffByRequired?: boolean
  includeTargetDateFulfilledField?: boolean
}

const CaseFields: React.FC<IProps> = (props): JSX.Element => {
  const { t } = useTranslation(["shared", "case"])
  const [userOptions, setUserOptions] = React.useState([])
  const [caseClassificationOptions, setCaseClassificationOptions] = React.useState([])
  const [caseSourceOptions, setCaseSourceOptions] = React.useState([])

  const users = useSelector(getAllInternalActiveUsersSorted)
  const keyedUsers = _.keyBy(users, "id")
  const caseClassifications = useSelector(getAllCaseClassifications)
  const caseSources = useSelector(getAllCaseSources)

  const caseTypeOptions: TSelectFieldOptions = Object.keys(CaseTypes).map((key) => {
    const translationKey = humps.camelize(CaseTypes[key])
    return {
      value: CaseTypes[key],
      label: t(`case:${translationKey}`)
    }
  })

  const setCaseClassificationOptionsForCaseType = (caseType: string) => {
    const options: TSelectFieldOptions = generateCaseClassificationOptions(caseClassifications, caseType)
    setCaseClassificationOptions(options)
  }

  const onChangeCaseType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCaseClassificationOptionsForCaseType(e.currentTarget.value)
    props.setFieldValue("caseClassificationId", "")
    props.setFieldValue("leadCareUserId", "")
    props.handleChange(e)
  }

  const onChangeClassification = (e: React.ChangeEvent<HTMLSelectElement>) => {
    props.setFieldValue("leadCareUserId", "")
    props.handleChange(e)
  }

  useEffect(() => {
    const options: TSelectFieldUserOptions = buildUserOptions(Object.values(keyedUsers))
    setUserOptions(options)
  }, [users])

  useEffect(() => {
    setCaseClassificationOptionsForCaseType(props.values.caseType)
  }, [caseClassifications])

  useEffect(() => {
    const options: TSelectFieldOptions = generateCaseSourceOptions(caseSources)
    setCaseSourceOptions(options)
  }, [caseSources])

  const renderSignedOffByField = (): JSX.Element => {
    if (!props.signedOffByRequired) return null

    return (
      <div className="mb-2">
        <Label name="signedOffBy" required={props.signedOffByRequired}>
          {t("case:signedOffBy")}
        </Label>
        <InputField
          type="text"
          name="signedOffBy"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.signedOffBy}
          autoComplete="off"
          autoFocus={false}
        />
        <FieldError
          errorMessage={props.errors.signedOffBy as string}
          isVisible={props.errors.signedOffBy && props.touched.signedOffBy}
        />
      </div>
    )
  }

  const renderTargetDateField = (): JSX.Element => {
    return (
      <div className="mb-2">
        <Label name="targetDate">
          {t("case:targetDate")}
        </Label>
        <DatePickerField name="targetDate" />
        <FieldError
          errorMessage={props.errors.targetDate as string}
          isVisible={props.errors.targetDate && props.touched.targetDate}
        />
      </div>
    )
  }

  const renderTargetDateFulfilledField = (): JSX.Element => {
    if (!props.includeTargetDateFulfilledField) return null

    return (
      <div className="mb-2 md:ml-2">
        <Label name="targetDateFulfilled" className="cursor-pointer">
          {t("case:targetDateFulfilled")}
          <CheckmarkToggle name="targetDateFulfilled" value={props.values.targetDateFulfilled} />
        </Label>
      </div>
    )
  }

  const renderSourceField = (): JSX.Element => {
    return (
      <div className="mb-2">
        <Label name="source" required={true}>
          {t("case:source")}
        </Label>
        <SelectField
          name="sourceId"
          options={caseSourceOptions}
          isGrouped={true}
          onBlur={props.handleBlur}
          onChange={props.handleChange}
        />
        <FieldError
          errorMessage={props.errors.sourceId as string}
          isVisible={props.errors.sourceId && props.touched.sourceId}
        />
      </div>
    )
  }

  return (
    <div>
      <div className="mb-4">
        <h3 className="mb-2 text-lg">
          {t("case:caseDetails")}
        </h3>

        <div className="md:flex">
          <div className="mb-2 md:w-1/3">
            <Label name="caseType" required={true}>
              {t("case:caseType")}
            </Label>

            <SelectField
              name="caseType"
              onChange={onChangeCaseType}
              onBlur={props.handleBlur}
              options={caseTypeOptions}
              placeholder={t("case:chooseCaseType")}
            />

            <FieldError
              errorMessage={props.errors.caseType}
              isVisible={props.errors.caseType && props.touched.caseType}
            />
          </div>

          <div className="flex-1 mb-2 md:ml-2">
            <Label name="caseClassificationId" required={true}>
              {t("shared:classification")}
            </Label>

            <SelectField
              name="caseClassificationId"
              options={caseClassificationOptions}
              isDisabled={!props.values.caseType}
              isGrouped={true}
              onChange={onChangeClassification}
              onBlur={props.handleBlur}
            />

            <FieldError
              errorMessage={props.errors.caseClassificationId as string}
              isVisible={
                props.errors.caseClassificationId &&
                props.touched.caseClassificationId
              }
            />
          </div>
        </div>

        <div className="mb-2">
          <Label name="leadCareUserId" required={true}>
            {t("case:leadCare")}
          </Label>
          <SelectField
            name="leadCareUserId"
            options={userOptions}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            filterOption={filterOptionsBySearchValue}
          />
          <FieldError
            errorMessage={props.errors.leadCareUserId as string}
            isVisible={props.errors.leadCareUserId && props.touched.leadCareUserId}
          />
        </div>


        <div className="md:flex ">
          <div className="md:flex flex-wrap md:w-2/5">
            {renderTargetDateField()}
            {renderTargetDateFulfilledField()}
          </div>
          <div className="md:w-3/5">
            {renderSourceField()}
          </div>
        </div>


        {renderSignedOffByField()}
      </div>
    </div>
  )
}

export default CaseFields
