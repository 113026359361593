import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { IconAccept, IconReject, IconNa } from "../../../../../svg/icons"
import { IUser } from "../../../../user/state/userTypes"
import { IAppState } from "../../../../../app/appTypes"
import { CaseStage, ConsentResponse } from "../../../state/liveMeetingTypes"
import { giveConsentOnBehalfAction } from "../../../state/liveMeetingActions"
import { getAllUsers, getCurrentUser } from "../../../../../shared/selectors/user"
import LiveMeetingUserOnlineStatus from "../../users/LiveMeetingUserOnlineStatus"
import NameUser from "../../../../../shared/components/names/NameUser"
import { getLiveMeetingUserConsent } from "../../../../../shared/selectors/liveMeetingConfig/consents"
import { getUserWithConfigById } from "../../../../../shared/selectors/liveMeetingConfig/userConfig"
import MentionFieldReadOnly from "../../../../../shared/components/forms/MentionFieldReadOnly"
import { getLiveMeetingConfig } from "../../../../../shared/selectors/liveMeetingConfig"
import classNames from "classnames"

interface IProps {
  user: IUser
  readOnly?: boolean
}

const ConsentListItem: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["shared", "liveMeeting"])
  const dispatch = useDispatch()
  const caseStage = useSelector(getLiveMeetingConfig).caseStage
  const consent = useSelector((state: IAppState) => getLiveMeetingUserConsent(state, props.user.id))
  const currentUser = useSelector((state: IAppState) => getUserWithConfigById(state, { userId: getCurrentUser(state).id }))
  const users = useSelector(getAllUsers)
  const consentOnBehalf = (response: ConsentResponse, userId: string) => dispatch(giveConsentOnBehalfAction(response, userId))

  const response = consent ? consent.response : ""
  const pending = response == ConsentResponse.PENDING || response == undefined || response == ""
  const approved = response == ConsentResponse.APPROVE
  const rejected = response == ConsentResponse.REJECT
  const na = response == ConsentResponse.NA

  const canConsentOnBehalf =
    // these permissions are different fromt the permission for async recommendations!!
    caseStage == CaseStage.APPROVING &&
    currentUser.config.isPresenter &&
    !props.readOnly &&
    (!consent || consent.consentById == currentUser.id || consent.consentById != props.user.id)

  const coloursForResponse = (thisResponse: boolean, colour: string) => {
    return ({
      [colour]: thisResponse,
      "hidden": !thisResponse && !canConsentOnBehalf,
      "text-ch-blue-400": !thisResponse && canConsentOnBehalf && pending,
      "text-ch-blue-200": !thisResponse && canConsentOnBehalf && !pending,
      "cursor-pointer": canConsentOnBehalf
    })
  }

  const handler = (consent: ConsentResponse) => {
    if (canConsentOnBehalf) { consentOnBehalf(consent, props.user.id) }

  }

  const pickApprove = () => handler(ConsentResponse.APPROVE)
  const pickReject = () => handler(ConsentResponse.REJECT)
  const pickNa = () => handler(ConsentResponse.NA)

  const renderComment = (): JSX.Element => {
    const comment = consent?.comment
    if (!comment) return null

    return (
      <div>
        <h2 className="font-semibold">
          {t("shared:consentComment")}
        </h2>
        <div>
          <MentionFieldReadOnly
            value={comment}
            users={users}
          />
        </div>
      </div>
    )
  }

  const responseOnBehalf = () => {
    if (!props.user || !consent) { return }
    if (props.user?.id != consent?.consentById) {
      return (
        <h4 className="text-sm font-thin uppercase">
          ({t("liveMeeting:consentOnBehalf")})
        </h4>
      )
    }
  }

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <LiveMeetingUserOnlineStatus userId={props.user.id} />
        <div className="flex flex-col">
          <div className="flex items-center">
            <h3 className="mr-1">
              <NameUser
                firstName={props.user.firstName}
                lastName={props.user.lastName}
                professionalTitle={props.user.professionalTitle}
              />
            </h3>
            {responseOnBehalf()}
          </div>
          {renderComment()}
        </div>
      </div>
      <div className="flex">
        <IconAccept
          className={classNames("fill-current w-8 h-8 ml-3 ", coloursForResponse(approved, "text-green-500"))}
          onClick={pickApprove}
        />
        <IconReject
          className={classNames("fill-current w-8 h-8 ml-3  cursor-pointer", coloursForResponse(rejected, "text-red-500"))}
          onClick={pickReject}
        />
        <IconNa
          className={classNames("fill-current w-8 h-8 ml-3 cursor-pointer", coloursForResponse(na, "text-ch-gray-600"))}
          onClick={pickNa}
        />
      </div>
    </div>
  )
}

export default ConsentListItem
