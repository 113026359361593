import { AssetComponentTypes, AssetTypes, CommentTypes, IAsset, ICommentAsset, IDocumentAsset } from "../../features/assets/state/assetsTypes"
import Link from "../../features/assets/components/assetListItem/Link"
import Deferral from "../../features/assets/components/assetListItem/Deferral"
import Recommendation from "../../features/assets/components/assetListItem/Recommendation"
import Comment from "../../features/assets/components/assetListItem/Comment"
import Decision from "../../features/assets/components/assetListItem/Decision"
import Pdf from "../../features/assets/components/assetListItem/Pdf"
import Image from "../../features/assets/components/assetListItem/Image"
import CaseReport from "../../features/assets/components/assetListItem/CaseReport"
import IAssetTypeFilters from "../types/AssetTypeFilters"
import { IMessageAsset, IMessageDocumentAsset } from "../../features/message/state/messageTypes"
import LiveMeetingPdfViewer from "../../features/liveMeeting/components/assets/viewer/LiveMeetingPdfViewer"
import LiveMeetingSharedViewImageViewer from "../../features/liveMeeting/components/sharedView/LiveMeetingSharedViewImageViewer"
import RadiologyLink from "../../features/assets/components/assetListItem/RadiologyLink"

const getCommentType = (comment: ICommentAsset) => {
  switch (comment.commentType) {
    case CommentTypes.Comment:
      return AssetComponentTypes.Comment
    case CommentTypes.Decision:
      return AssetComponentTypes.Decision
    case CommentTypes.Deferral:
      return AssetComponentTypes.Deferral
    case CommentTypes.Recommendation:
      return AssetComponentTypes.Recommendation
  }
}

const getDocumentType = (document: IDocumentAsset | IMessageDocumentAsset) => {
  switch (document.contentType) {
    case "application/pdf":
      return AssetComponentTypes.Pdf
    default:
      return AssetComponentTypes.Image
  }
}

export const getComponentTypeForAsset = (asset: IAsset | IMessageAsset): AssetComponentTypes => {
  switch (asset?.assetType) {
    case AssetTypes.Comment:
      return getCommentType(asset.asset as ICommentAsset)
    case AssetTypes.Link:
      return AssetComponentTypes.Link
    case AssetTypes.RadiologyLink:
      return AssetComponentTypes.RadiologyLink
    case AssetTypes.Document:
      return getDocumentType(asset.asset as IDocumentAsset)
    case AssetTypes.MessageDocument:
      return getDocumentType(asset.asset as IMessageDocumentAsset)
    case AssetTypes.CaseReport:
      return AssetComponentTypes.CaseReport
    default:
      return null
  }
}

export const componentLookup = {
  [AssetComponentTypes.Link]: Link,
  [AssetComponentTypes.RadiologyLink]: RadiologyLink,
  [AssetComponentTypes.Comment]: Comment,
  [AssetComponentTypes.Deferral]: Deferral,
  [AssetComponentTypes.Recommendation]: Recommendation,
  [AssetComponentTypes.Decision]: Decision,
  [AssetComponentTypes.Pdf]: Pdf,
  [AssetComponentTypes.Image]: Image,
  [AssetComponentTypes.CaseReport]: CaseReport,
}

const clickableComponents: string[] = [
  AssetComponentTypes.Pdf,
  AssetComponentTypes.Image,
  AssetComponentTypes.CaseReport,
  AssetComponentTypes.RadiologyLink
]

export function clickableComponentAction<T>(action: T, assetComponentType: AssetComponentTypes): T {
  return (clickableComponents.includes(assetComponentType)) ? action : undefined
}

export const lookupLiveMeetingViewerForAsset = (asset: IAsset) => {
  const assetComponetType = getComponentTypeForAsset(asset)
  const componentLookup = {
    [AssetComponentTypes.Pdf]: LiveMeetingPdfViewer,
    [AssetComponentTypes.CaseReport]: LiveMeetingPdfViewer,
    [AssetComponentTypes.Image]: LiveMeetingSharedViewImageViewer,
  }
  return componentLookup[assetComponetType]

}

export const doesAssetUseViewer = (asset: IAsset) => {
  const selectedAssetComponetType = getComponentTypeForAsset(asset)
  const componentsUsingViewer = [AssetComponentTypes.Pdf, AssetComponentTypes.Image, AssetComponentTypes.CaseReport]
  return (asset && componentsUsingViewer.includes(selectedAssetComponetType)
  )
}
type AssetType = typeof CaseReport | typeof Pdf | typeof Image | typeof Link | typeof Comment |
  typeof Deferral | typeof Recommendation | typeof Decision

export const isAssetFiltered = (asset: AssetType, filters: IAssetTypeFilters): boolean => {
  if (!filters) return false
  switch (asset) {

    case CaseReport:
    case Pdf:
      if (!filters.showAll && !filters.showPdfs) return true
      break

    case Image:
      if (!filters.showAll && !filters.showImages) return true
      break

    case Link:
      if (!filters.showAll && !filters.showLinks) return true
      break

    case Comment:
      if (!filters.showAll && !filters.showComments) return true
      break

    case Deferral:
    case Recommendation:
      if (!filters.showAll && !filters.showRecommendations) return true
      break

    case Decision:
      if (!filters.showAll && !filters.showDecisions) return true
      break

    default:
      return false
  }
}
