import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { MockStore } from "redux-mock-store"
import OT from "@opentok/client"
import { publishToWebRTCSessionAction, addWebRTCStreamAction, removeWebRTCStreamAction, updateWebRTCPublisherAction, unpublishFromWebRTCSessionAction } from "../state/webRTCActions"
import { publisherEventHandlers, webRTCEvent } from "../state/webRTCTypes"
import { getStreamUserId } from "../../../apis/webRTC/webRTC"
import { displayUserMessageAction } from "../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../userMessage/state/userMessageTypes"
import { toggleIsOnlineAction, toggleIsSharingAudioAction, toggleIsSharingVideoAction } from "../../liveMeeting/state/liveMeetingActions"
import { getWebRTCPublisher, getWebRTCSession } from "../../../shared/selectors/webRTC"
import { useSelector } from "react-redux"

interface IProps {
  store?: MockStore,
  publisherOptions: OT.PublisherProperties
}

const WebRTCPublisher: React.FC<IProps> = (props: IProps): JSX.Element => {
  const [hasPublished, setHasPublished] = useState(false)
  const containerRef = React.useRef<HTMLDivElement>()

  const publisher: OT.Publisher = useSelector(getWebRTCPublisher)
  const session = useSelector(getWebRTCSession)
  const connection = publisher?.session?.connection

  const dispatch = useDispatch()
  const eventHandlers: publisherEventHandlers = {
    streamCreated: (event: webRTCEvent) => {
      // current user local stream published to session
      // user considered online
      const stream = event.stream
      const publisher = event.target as OT.Publisher
      const userId = getStreamUserId(stream)
      dispatch(updateWebRTCPublisherAction(publisher))
      dispatch(addWebRTCStreamAction(userId, stream))
      dispatch(toggleIsOnlineAction(userId, true))
      dispatch(toggleIsSharingAudioAction(userId, stream.hasAudio))
      dispatch(toggleIsSharingVideoAction(userId, stream.hasVideo))
    },
    streamDestroyed: (event: webRTCEvent) => {
      // current user local stream unpublished from session
      // user considered offline
      const publisher = event.target as OT.Publisher
      const userId = getStreamUserId(event.stream)
      dispatch(updateWebRTCPublisherAction(publisher))
      dispatch(removeWebRTCStreamAction(userId))
      dispatch(toggleIsOnlineAction(userId, false))
    },
    accessDenied: (_event: webRTCEvent) => {
      dispatch(displayUserMessageAction({
        messageKey: "webRTCAccessDenied",
        type: UserMessageTypes.ERROR
      }))
    }
  }

  useEffect(() => {
    // Publish to session after connection has been established
    if (!session?.connection && !hasPublished) return
    dispatch(publishToWebRTCSessionAction(session, containerRef, props.publisherOptions, eventHandlers))
    setHasPublished(true)
  }, [session])

  useEffect(() => {
    // If connection to session is destroyed unpublish
    // Streams will then in turn be destroyed
    if (!connection) return
    return () => {
      dispatch(unpublishFromWebRTCSessionAction(publisher.session, publisher))
    }
  }, [connection])

  return (
    <div ref={containerRef} className="hidden"></div>
  )
}

export default WebRTCPublisher
