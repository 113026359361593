import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import MentionFieldReadOnly from "../../../../shared/components/forms/MentionFieldReadOnly"
import { getAllUsers } from "../../../../shared/selectors/user"
import { getCurrentCaseRecommendation } from "../../../../shared/selectors/liveMeetingConfig/recommendation"

interface IProps {
  headerKey?: string
}

const RecommendationDisplay: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation(["shared", "liveMeeting"])
  const currentCaseRecommendation = useSelector(getCurrentCaseRecommendation)
  const users = useSelector(getAllUsers)
  const classes = classNames(
    "mb-2",
    "overflow-y-auto",
    "max-h-full",
    "sm:max-h-40",
    "bg-ch-gray-200",
    "p-2",
    "rounded"
  )

  return (
    <div className={classNames("flex-1")}>
      <div className="mb-2">
        {t(props.headerKey)} ({t("liveMeeting:readOnly")}):
      </div>
      <div className={classes}>
        <MentionFieldReadOnly
          value={currentCaseRecommendation}
          users={users}
        />
      </div>
    </div>
  )
}

export default RecommendationDisplay
