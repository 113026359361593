import React, { RefObject, useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { MockStore } from "redux-mock-store"
import classNames from "classnames"
import { IAppState } from "../../../app/appTypes"
import OT from "@opentok/client"
import { subscribeToWebRTCStreamAction } from "../state/webRTCActions"
import { getWebRTCScreenShareStream, getWebRTCSession, getWebRTCStreamByUserId } from "../../../shared/selectors/webRTC"
import { getStreamUserId } from "../../../apis/webRTC/webRTC"
import { getCurrentUserId } from "../../../shared/selectors/user"
import Button from "../../../shared/components/buttons/Button"
import { IconExitFullscreen } from "../../../svg/icons"
import { useTranslation } from "react-i18next"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import { getSelectedMeetingCaseDetails } from "../../../shared/selectors/liveMeetingConfig/meetingCase"
import NameUser from "../../../shared/components/names/NameUser"
import TimeDateOfBirth from "../../../shared/components/time/TimeDateOfBirth"
import { TenantConfigContext } from "../../tenantConfig/contexts/TenantConfigContext"

interface IProps {
  store?: MockStore
  subscriberOptions: OT.SubscriberProperties
  userId: string
  isScreenShareStream?: boolean
  setRef?: (ref: RefObject<HTMLDivElement>) => void
}

const WebRTCSubscriber: React.FC<IProps> = (props: IProps): JSX.Element => {
  const [subscribedStream, setSubscribedStream] = useState<OT.Stream>(null)
  const streamContainerRef = React.useRef<HTMLDivElement>()
  const fullScreenRef = React.useRef<HTMLDivElement>()
  const dispatch = useDispatch()
  const currentUserId = useSelector(getCurrentUserId)
  const session = useSelector(getWebRTCSession)
  const stream = props.isScreenShareStream
    ? useSelector(getWebRTCScreenShareStream)
    : useSelector((state: IAppState) => getWebRTCStreamByUserId(state, props))
  const [showFullScreenHeader, setShowFullScreenHeader] = useState<boolean>(false)
  const { t } = useTranslation(["shared", "liveMeeting"])
  const caseData = useSelector(getSelectedMeetingCaseDetails)
  const { careProviderIdLabel } = useContext(TenantConfigContext)

  useEffect(() => {
    // When session is connected and the provided stream is set, subscribe to it
    // This will show the user's webcam stream or screenshare in the browser
    if (!stream || !session?.connection || (subscribedStream && subscribedStream.streamId == stream.streamId)) return

    dispatch(subscribeToWebRTCStreamAction(session, stream, streamContainerRef, props.subscriberOptions))
    // we record the last stream that we subscribed to. If it changes, we will resubscribe
    setSubscribedStream(stream)
  }, [stream, session])

  useEffect(() => {
    if (!props.setRef) return
    props.setRef(fullScreenRef)
  }, [streamContainerRef.current])

  useEffect(() => {
    const listener = () => { setShowFullScreenHeader(!!document.fullscreenElement) }
    document.addEventListener("fullscreenchange", listener)
    return () => { document.removeEventListener("fullscreenchange", listener) }
  })

  const streamUserId = getStreamUserId(subscribedStream)
  // tailwind 2 doesn't support negative scale values, so using inline style for this
  // if you read this and we have updated to tailwind 3, please put this in the classNames
  // (something like "-scale-x-100": streamUserId == currentUserId)
  const style = {}
  if (streamUserId == currentUserId) {
    style["transform"] = "scaleX(-1)"
  }

  const renderFullScreenHeader = () => {
    if (!showFullScreenHeader) return

    return (
      <div className="absolute flex justify-between items-center top-0 w-full h-10 z-10 bg-white px-5">
        <div className="flex gap-3">
          <NameUser
            firstName={caseData.firstName}
            lastName={caseData.lastName}
            title={caseData.title}
            gender={caseData.gender}
          />
          <TimeDateOfBirth utcTime={caseData.dateOfBirth} />
          <div>
            {careProviderIdLabel}: {caseData.careProviderId}
          </div>
        </div>
        <Button
          action={() => document.exitFullscreen()}
          variant={StyleVariants.BLUE} >
          <div className="flex gap-3">{t("liveMeeting:leaveFullscreen")}<IconExitFullscreen /> </div>
        </Button>
      </div>
    )
  }

  if (!stream) return null
  return (
    <div ref={fullScreenRef} className={classNames("relative h-full", { "hidden": !stream.hasVideo })} >
      {renderFullScreenHeader()}
      <div
        ref={streamContainerRef}
        style={style}
        className="h-full"
      ></div >
    </div>
  )
}

export default WebRTCSubscriber
