import { call, Effect, put, takeLeading } from "redux-saga/effects";
import API from "../../apis/contextmeeting/api";
import { withAuthHeader } from "../../apis/contextmeeting/withAuthHeader";
import { RoutePaths } from "../routes/Routes";
import { AppActionTypes } from "./appActions";
import history from '../routes/history'
import { AxiosError } from "axios";
import { signOutUserAction } from "../../features/account/state/accountActions";

export function* watchAppActions(): Generator {
  yield takeLeading(AppActionTypes.CHECK_SESSION_ACTIVE_PENDING, checkSession)
}

export function* checkSession(_action: Effect): Generator {
  try {
    yield call(API.get, "/status/session", withAuthHeader())
  } catch (_error: unknown) {
    const error = _error as AxiosError
    if (error.response?.status == 401) {
      yield put(signOutUserAction())
      yield call(history.push, RoutePaths.SIGN_IN)
    }
  }
}
