import { Reducer, AnyAction, combineReducers } from "redux"
import _ from "lodash"
import { AssetsActionTypes, IAsset } from "../assetsTypes"
import { INormalizedEntities } from "./../../../../app/appTypes"
import { TaskResponseActionTypes } from "../../../taskResponse/state/taskResponseTypes"

type TByIdState = { [id: string]: IAsset }
type TAllIdsState = string[]

export const byIdInitialState: TByIdState = {}
export const allIdsInitialState: TAllIdsState = []

export const byId: Reducer<TByIdState> = (state: TByIdState = byIdInitialState, action: AnyAction): TByIdState => {
  switch (action.type) {
    case AssetsActionTypes.FETCH_CASE_ASSETS_ASYNC_SUCCESS:
    case AssetsActionTypes.CREATE_CASE_LINK_ASYNC_SUCCESS:
    case AssetsActionTypes.UPLOAD_CASE_FILE_ASYNC_SUCCESS:
    case AssetsActionTypes.CREATE_CASE_COMMENT_ASYNC_SUCCESS:
    case AssetsActionTypes.CREATE_CASE_RECOMMENDATION_ASYNC_SUCCESS:
    case AssetsActionTypes.SAVE_CASE_RECOMMENDATION_ASYNC_SUCCESS:
    case AssetsActionTypes.ADD_CONSENT_ASYNC_SUCCESS:
    case TaskResponseActionTypes.CREATE_CASE_TASK_RESPONSE_ASYNC_SUCCESS:
      return _.merge({}, state, action.payload.all.entities.assets)
    case AssetsActionTypes.DELETE_CASE_ASSET_ASYNC_SUCCESS:
      return action.payload.all.entities.assets || []

    default:
      return { ...state }
  }
}

export const allIds: Reducer<TAllIdsState> = (state: TAllIdsState = allIdsInitialState, action: AnyAction): TAllIdsState => {
  switch (action.type) {
    case AssetsActionTypes.FETCH_CASE_ASSETS_ASYNC_SUCCESS:
    case AssetsActionTypes.DELETE_CASE_ASSET_ASYNC_SUCCESS:
    case AssetsActionTypes.CREATE_CASE_COMMENT_ASYNC_SUCCESS:
    case AssetsActionTypes.CREATE_CASE_RECOMMENDATION_ASYNC_SUCCESS:
    case AssetsActionTypes.SAVE_CASE_RECOMMENDATION_ASYNC_SUCCESS:
      return _.uniq(action.payload.all.result)

    case AssetsActionTypes.CREATE_CASE_LINK_ASYNC_SUCCESS:
    case AssetsActionTypes.UPLOAD_CASE_FILE_ASYNC_SUCCESS:
      return _.uniq(_.concat(action.payload.all.result, state))

    default:
      return state
  }
}

export const assetEntityReducer: Reducer<INormalizedEntities<IAsset>> = combineReducers({
  byId,
  allIds
})
