import React from "react"

interface IPropsFromParent {
  children: React.ReactNode
  className?: string
}

type IProps = IPropsFromParent

const DetailsCardRight: React.FC<IProps> = (props: IProps): JSX.Element => {
  const defaultClasses = [
    "flex",
    "flex-wrap",
    "justify-center",
    "lg:justify-end",
    "flex-col",
    "mb-2",
    "md:w-1/3 lg:w-1/4",
  ]

  const propClasses = props.className ? props.className.split(" ") : []
  const allClasses = [...defaultClasses, ...propClasses].join(" ")

  return (
    <div className={allClasses}>
      {props.children}
    </div>
  )
}

export default DetailsCardRight
