
import React, { ReactNode, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { RoutePaths } from "../../app/routes/Routes"
import Breadcrumbs from "../../shared/components/ui/Breadcrumbs"
import { IAppState } from "../../app/appTypes"
import Wizard from "../../shared/components/ui/wizard/Wizard"
import { IconAcceptThin, IconFolder } from "../../svg/icons"
import { IHavePatientId, IHavePatientIdParam, IPatient } from "../../features/patient/state/patientTypes"
import { generatePath, useParams } from "react-router"
import { IPatientCaseNewData } from "../../features/patientEdit/state/patientEditTypes"
import { getPatientByParamId } from "../../shared/selectors/patients"
import { fetchPatientAsync } from "../../features/patient/state/patientActions"
import CaseDetailsForm from "../../features/patientEdit/components/caseDetails/CaseDetailsForm"
import ReviewAndSaveForm from "../../features/patientEdit/components/caseDetails/reviewAndSave/ReviewAndSaveForm"

interface IPropsFromParent {
  children?: ReactNode
}


type Props = IPropsFromParent & IHavePatientIdParam

const PatientCaseNewPage = (props: Props): JSX.Element => {
  const { patientId } = useParams<IHavePatientId>()
  const dispatch = useDispatch()
  const { t } = useTranslation(["shared", "patient"])
  const patient: IPatient = useSelector((state: IAppState) => getPatientByParamId(state, props))

  const [patientCaseNewData, setPatientCaseNewData] = useState<IPatientCaseNewData>({
    casesAttributes: [{
      caseType: "",
      caseClassificationId: "",
      leadCareUserId: "",
      signedOffBy: "",
      targetDate: "",
      sourceId: ""
    }]
  })

  useEffect(() => {
    dispatch(fetchPatientAsync(patientId))
  }, [])

  const patientUrl = generatePath(RoutePaths.PATIENT, {
    patientId: patientId
  })

  const renderWizard = (): JSX.Element => {
    return <Wizard
      data={patientCaseNewData}
      setData={setPatientCaseNewData}
      steps={[
        {
          name: t("patient:caseDetails:sectionTitle"),
          icon: IconFolder,
          component: CaseDetailsForm,
          nextButtonText: t("patient:caseDetails:buttons:createCase"),
        },
        {
          name: t("patient:reviewAndSave:sectionTitle"),
          icon: IconAcceptThin,
          component: ReviewAndSaveForm,
          nextButtonText: t("patient:reviewAndSave:buttons:savePatient"),
          previousButtonText: t("shared:previous")
        }
      ]}
    />
  }

  const renderContent = (): JSX.Element => {
    if (!patient) return <h3 className="text-lg">{t("patient:messages:patientNotFound")}</h3>

    return renderWizard()
  }

  return (
    <div className="h-full flex flex-col">
      <Breadcrumbs
        breadcrumbs={[
          {
            pageTitle: t("shared:patient"),
            href: patientUrl
          },
          {
            pageTitle: t("patient:caseDetails:sectionTitle")
          }
        ]}
      />
      {renderContent()}
    </div>
  )
}

export default PatientCaseNewPage
