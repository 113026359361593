import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { IconPresenter, IconScribe } from "../../../../svg/icons"
import NameUser from "../../../../shared/components/names/NameUser"
import { getCurrentUserWithConfig } from "../../../../shared/selectors/user"

const LiveMeetingUserDetails: React.FC = () => {
  const { t } = useTranslation(["liveMeeting", "user"])
  const user = useSelector(getCurrentUserWithConfig)

  const isScribeOrPresenter = (): boolean => {
    return user.config.isPresenter || user.config.isScribe
  }

  const hasRoles = (): boolean => {
    return user.config.isCoordinator || isScribeOrPresenter()
  }

  const renderDivider = (): JSX.Element => {
    if (!user.config.isCoordinator || !isScribeOrPresenter()) return
    return <span className="mx-1">&#124;</span>
  }

  const renderCoordinator = (): JSX.Element => {
    if (!user.config.isCoordinator) return
    return <span className="hidden sm:block">{t("user:coordinator")}</span>
  }

  const renderPresenter = (): JSX.Element => {
    if (!user.config.isPresenter) return
    return (
      <span className="mr-2 text-white">
        <IconPresenter
          className="fill-current w-5 h-5"
          title={t("user:presenter")}
        />
      </span>
    )
  }

  const renderScribe = (): JSX.Element => {
    if (!user.config.isScribe) return
    return (
      <span className="mr-2 text-white">
        <IconScribe
          className="fill-current w-5 h-5"
          title={t("user:scribe")}
        />
      </span>
    )
  }

  const renderRoles = (): JSX.Element => {
    if (!hasRoles()) return
    return (
      <div className="flex items-center text-sm font-thin uppercase">
        {renderCoordinator()}
        {renderDivider()}
        {renderScribe()}
        {renderPresenter()}
      </div>
    )
  }

  if (!user.config) return null

  return (
    <div>
      <h2 className="hidden sm:block">
        <NameUser
          firstName={user.firstName}
          lastName={user.lastName}
        />
      </h2>
      {renderRoles()}
    </div>
  )
}

export default LiveMeetingUserDetails
