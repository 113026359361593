import React from "react"
import { IAppState } from "../../../../../app/appTypes"
import _ from "lodash"
import { getTaskResponseById } from "../../../../../shared/selectors/task"
import ResponseDetails from "./ResponseDetails"
import { useSelector } from "react-redux"

interface IProps {
  taskResponseId: string
}

const ResponseListItem: React.FC<IProps> = (props) => {
  const taskResponse = useSelector((state: IAppState) => getTaskResponseById(state, props))
  if (!taskResponse) return null

  return (
    <li className="mb-1 w-full">
      <ResponseDetails
        taskResponseId={props.taskResponseId}
      />
    </li>
  )
}

export default ResponseListItem
