import React from "react"
import TextLink from "../text/TextLink"

type IBreadcrumb = {
  pageTitle: string
  href?: string
}

interface IPropsFromParent {
  breadcrumbs: IBreadcrumb[]
}

type Props = IPropsFromParent

const Breadcrumbs: React.FC<Props> = (props): JSX.Element => {
  const renderBreadcrumb = (breadcrumb: IBreadcrumb): JSX.Element => {
    if (breadcrumb.href) return (
      <div>
        <TextLink href={breadcrumb.href} className="font-semibold">
          {breadcrumb.pageTitle}
        </TextLink>
        <span>&nbsp;/&nbsp;</span>
      </div>
    )
    if (!breadcrumb.href) return (
      <div>
        {breadcrumb.pageTitle}
      </div>
    )
  }

  return (
    <h2 className="flex flex-wrap mb-4 text-lg">
      {props.breadcrumbs.map((breadcrumb: IBreadcrumb, index: number) => {
        return (
          <div key={`breadcrumb-${index}`}>
            {renderBreadcrumb(breadcrumb)}
          </div>
        )
      })}
    </h2>
  )
}

export default Breadcrumbs
