import React, { ReactNode, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { RoutePaths } from "../../app/routes/Routes"
import Breadcrumbs from "../../shared/components/ui/Breadcrumbs"
import { IAppState } from "../../app/appTypes"
import Wizard from "../../shared/components/ui/wizard/Wizard"
import { IconAcceptThin, IconFurtherDetails } from "../../svg/icons"
import { getCurrentUser } from "../../shared/selectors/user"
import { IPatientEditFurtherDetailsData } from "../../features/patientEdit/state/patientEditTypes"
import { IHavePatientId, IHavePatientIdParam, IPatient } from "../../features/patient/state/patientTypes"
import { getPatientByParamId } from "../../shared/selectors/patients"
import { useParams } from "react-router"
import { fetchPatientAsync } from "../../features/patient/state/patientActions"
import LoadingSpinner from "../../shared/components/ui/LoadingSpinner"
import FurtherDetailsForm from "../../features/patientNew/components/furtherDetails/FurtherDetailsForm"
import ReviewAndSaveForm from "../../features/patientEdit/components/furtherDetails/ReviewAndSaveForm"

interface IPropsFromParent {
  children?: ReactNode
}

type Props = IPropsFromParent & IHavePatientIdParam

const PatientEditFurtherDetailsPage = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "patient"])
  const dispatch = useDispatch()
  const currentUser = useSelector((state: IAppState) => getCurrentUser(state))
  const { patientId } = useParams<IHavePatientId>()
  const patient: IPatient = useSelector((state: IAppState) => getPatientByParamId(state, props))
  const [patientEditFurtherDetailsData, setPatientEditFurtherDetailsData] = useState<IPatientEditFurtherDetailsData>(null)

  useEffect(() => {
    dispatch(fetchPatientAsync(patientId))
  }, [])

  useEffect(() => {
    if (!patient) return
    setPatientEditFurtherDetailsData({
      furtherDetailsAttributes: {
        id: patient.furtherDetails?.id,
        postcode: patient.furtherDetails?.postcode,
        addressLine1: patient.furtherDetails?.addressLine1,
        addressLine2: patient.furtherDetails?.addressLine2,
        city: patient.furtherDetails?.city,
        county: patient.furtherDetails?.county,
        country: patient.furtherDetails?.country,
        email: patient.furtherDetails?.email,
        phoneNumber: patient.furtherDetails?.phoneNumber,
        phoneNumberAlt: patient.furtherDetails?.phoneNumberAlt,
        gpPostcode: patient.furtherDetails?.gpPostcode,
        gpPracticeName: patient.furtherDetails?.gpPracticeName,
        gpAddressLine1: patient.furtherDetails?.gpAddressLine1,
        gpAddressLine2: patient.furtherDetails?.gpAddressLine2,
        gpCity: patient.furtherDetails?.gpCity,
        gpCounty: patient.furtherDetails?.gpCounty,
        gpCountry: patient.furtherDetails?.gpCountry,
        gpEmail: patient.furtherDetails?.gpEmail,
        gpPhoneNumber: patient.furtherDetails?.gpPhoneNumber,
        gpName: patient.furtherDetails?.gpName
      }
    })
  }, [patient])

  const renderWizard = (): JSX.Element => {
    if (!currentUser.abilities?.patient.canCreate) return <h3 className="text-lg">{t("shared:notPermitted")}</h3>

    if (!patientEditFurtherDetailsData) return <LoadingSpinner />

    return <Wizard
      data={patientEditFurtherDetailsData}
      setData={setPatientEditFurtherDetailsData}
      steps={[
        {
          name: t("patient:editPatientDetails:sectionTitle"),
          icon: IconFurtherDetails,
          component: FurtherDetailsForm,
          nextButtonText: t("shared:next"),
          previousButtonText: t("shared:previous"),
          updateRequiredBeforeNext: true
        },
        {
          name: t("patient:reviewAndSave:sectionTitle"),
          icon: IconAcceptThin,
          component: ReviewAndSaveForm,
          nextButtonText: t("patient:reviewAndSave:buttons:savePatient"),
          previousButtonText: t("shared:previous")
        }
      ]}
    />
  }

  const renderContent = (): JSX.Element => {
    if (!patient) return <h3 className="text-lg">{t("patient:messages:patientNotFound")}</h3>
    return renderWizard()
  }

  return (
    <div className="h-full flex flex-col">
      <Breadcrumbs
        breadcrumbs={[
          {
            pageTitle: t("shared:patients"),
            href: RoutePaths.PATIENTS
          },
          {
            pageTitle: t("patient:editPatientFurtherDetails:sectionTitle")
          }
        ]}
      />
      {renderContent()}
    </div>
  )
}

export default PatientEditFurtherDetailsPage
