import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import AssetListItem from "../assets/AssetListItem"
import { getSelectedMeetingCase } from "../../../../shared/selectors/liveMeetingConfig/meetingCase"
import { IMeetingCase, IMeetingCaseAsset } from "../../../meetingCase/state/meetingCaseTypes"
import IAssetTypeFilters from "../../../../shared/types/AssetTypeFilters"

interface IProps {
  assetTypeFilters: IAssetTypeFilters
}

const LiveMeetingAssetListObserver: React.FC<IProps> = (props): JSX.Element => {
  const { t } = useTranslation(["case", "liveMeeting", "shared"])
  const selectedMeetingCase: IMeetingCase = useSelector(getSelectedMeetingCase)
  const hoveredAssetId: string = useSelector((state: IAppState) => state.liveMeetingConfig.sharedViewHoveredAssetId)
  const selectedAssetId: string = useSelector((state: IAppState) => state.liveMeetingConfig.sharedViewSelectedAssetId)

  if (!selectedMeetingCase?.meetingCaseAssets?.length) return (
    <h3 className="text-center text-ch-blue-alt-400">
      {t("case:noAssets")}
    </h3>
  )

  if (!selectedMeetingCase?.case) return null

  return (
    <div>
      <ul>
        {selectedMeetingCase?.meetingCaseAssets.map((meetingCaseAsset: IMeetingCaseAsset) => {
          return (
            <AssetListItem
              assetTypeFilters={props.assetTypeFilters}
              key={meetingCaseAsset.caseAssetId}
              assetId={meetingCaseAsset.caseAssetId}
              caseId={selectedMeetingCase.case}
              isHovered={hoveredAssetId == meetingCaseAsset.caseAssetId}
              isSelected={selectedAssetId == meetingCaseAsset.caseAssetId}
              noAction
            />
          )
        })}
      </ul>
    </div>
  )
}

export default LiveMeetingAssetListObserver
