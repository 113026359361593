import { UserConfigActionTypes, IUserConfigFetchAsyncAction, IUserConfigEditData, IUpdateUserConfigAsyncAction, IUISettingData, IUpdateUISettingAction } from "./userConfigTypes"

export const fetchUserConfigAction = (userId: string): IUserConfigFetchAsyncAction => {
  return {
    type: UserConfigActionTypes.FETCH_USER_CONFIG_ASYNC_PENDING,
    payload: userId
  }
}

export const updateUserConfigAction = (userId: string, userData: IUserConfigEditData): IUpdateUserConfigAsyncAction => {
  return {
    type: UserConfigActionTypes.UPDATE_USER_CONFIG_ASYNC_PENDING,
    payload: {
      id: userId,
      user: userData
    }
  }
}

export const updateUISettingAction = (userId: string, setting: IUISettingData): IUpdateUISettingAction => {
  return {
    type: UserConfigActionTypes.UPDATE_UI_SETTING_ASYNC_PENDING,
    payload: {
      id: userId,
      data: setting
    }
  }
}
