import React from "react"
import { Form, useFormikContext } from "formik"
import { useTranslation } from "react-i18next"
import DenotesRequiredMessage from "../../../shared/components/forms/DenotesRequiredMessage"
import FieldError from "../../../shared/components/forms/FieldError"
import InputField from "../../../shared/components/forms/InputField"
import Label from "../../../shared/components/forms/Label"
import SelectField from "../../../shared/components/forms/SelectField"
import Buttons from "../../../shared/components/layout/Buttons"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import ExistingUserWarning from "../../user/components/existingUserWarning/ExistingUserWarning"
import flags from "react-phone-number-input/flags"
import Button from "../../../shared/components/buttons/Button"
import { TSelectFieldOptions } from "../../../shared/components/forms/StandardSelectField"
import PhoneInput  from "react-phone-number-input"
import { IGuestUserNewFormData, UserTitles } from "../../user/state/userTypes"


interface Props {
  caseId?: string
  closeModal?: () => void
}

const GuestUserFormContent: React.FC<Props> = (props): JSX.Element => {
  const {
    isSubmitting,
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    isValid,
  } = useFormikContext<IGuestUserNewFormData>()

  const { t } = useTranslation(["shared", "user"])

  const titleOptions: TSelectFieldOptions = Object.keys(UserTitles).map((key) => {
    return {
      value: UserTitles[key],
      label: key
    }
  })

  return (
    <Form>
      <DenotesRequiredMessage />

      <div className="mb-2">
        <Label name="title">
          {t("user:title")}
        </Label>
        <SelectField
          name="title"
          options={titleOptions}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <FieldError errorMessage={errors.title as string} isVisible={(errors.title && touched.title) as boolean} />
      </div>

      <div className="mb-2">
        <Label name="professionalTitle">
          {t("user:professionalTitle")}
        </Label>
        <InputField
          type="text"
          name="professionalTitle"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.professionalTitle}
          autoComplete="off"
        />
        <FieldError errorMessage={errors.professionalTitle as string} isVisible={(errors.professionalTitle && touched.professionalTitle) as boolean} />
      </div>

      <div className="mb-2">
        <Label name="firstName" required={true}>
          {t("user:firstName")}
        </Label>
        <InputField
          type="text"
          name="firstName"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.firstName}
          autoComplete="off"
        />
        <FieldError errorMessage={errors.firstName as string} isVisible={(errors.firstName && touched.firstName) as boolean} />
      </div>

      <div className="mb-2">
        <Label name="lastName" required={true}>
          {t("user:lastName")}
        </Label>
        <InputField
          type="text"
          name="lastName"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.lastName}
          autoComplete="off"
        />
        <FieldError errorMessage={errors.lastName as string} isVisible={(errors.lastName && touched.lastName) as boolean} />
      </div>

      <ExistingUserWarning
        caseId={props.caseId}
        canInviteAsGuest
        closeModal={props.closeModal}
      />

      <div className="mb-2">
        <Label name="email" required={true}>
          {t("user:email")}
        </Label>
        <InputField
          type="email"
          name="email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          autoComplete="off"
        />
        <FieldError errorMessage={errors.email as string} isVisible={(errors.email && touched.email) as boolean} />
      </div>

      <div className="mb-2">
        <Label name="phoneNumber" required={true}>
          {t("user:phoneNumber")}
        </Label>
        <PhoneInput
          value={values.phoneNumber}
          onChange={(value) => {
            setFieldValue("phoneNumber", value)
          }}
          onBlur={() => setFieldTouched("phoneNumber")}
          defaultCountry="GB"

          // TODO: Fix flags not showing in prod when loaded with flagUrl
          // flagUrl={`${location.origin}/images/svg/flags/{xx}.svg`}
          flags={flags}
        />
        <FieldError errorMessage={errors.phoneNumber as string} isVisible={(errors.phoneNumber && touched.phoneNumber) as boolean} />
      </div>

      <div className="mb-6">
        <Label name="organisation">
          {t("user:guestOrganisation")}
        </Label>
        <InputField
          type="text"
          name="organisation"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.organisation}
          autoComplete="off"
        />
      </div>

      <Buttons
        className="mb-2"
        buttons={[
          <Button
            action={props.closeModal}
            variant={StyleVariants.BLUE_ALT}
            key="1"
          >
            {t("shared:cancel")}
          </Button>,
          <Button
            isDisabled={isSubmitting || !isValid}
            variant={StyleVariants.PURPLE}
            isSubmit={true}
            key={2}
          >
            {t("case:guestUsers:buttons:createGuestUser")}
          </Button>
        ]}
      />
    </Form>
  )
}
export default GuestUserFormContent
