import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { YourViewTabs, CaseStage } from "../../state/liveMeetingTypes"
import LiveMeetingUsersView from "../users/LiveMeetingUsersView"
import LiveMeetingUsersViewSwitch from "../users/LiveMeetingUsersViewSwitch"
import { getSelectedYourViewTab } from "../../../../shared/selectors/ui"
import LiveMeetingYourViewAssets from "./LiveMeetingYourViewAssets"
import LiveMeetingMyConsent from "./LiveMeetingMyConsent"
import { getLiveMeetingConfig } from "../../../../shared/selectors/liveMeetingConfig"

interface IProps {
  readonly meetingId: string
}

const LiveMeetingYourView: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["liveMeeting"])
  const selectedYourViewTab = useSelector(getSelectedYourViewTab)
  const caseStage = useSelector(getLiveMeetingConfig).caseStage

  const decideWhatToRender = (): JSX.Element => {
    if (caseStage == CaseStage.APPROVING) { return renderConsentForm() }
    else if (selectedYourViewTab === YourViewTabs.YOUR_VIEW) { return renderYourView() }
  }

  const renderConsentForm = () => {
    return (
      <div className={classNames("flex", "flex-col", "h-full")}>
        <h2 className="bg-ch-blue-200 p-2">
          {t("liveMeeting:yourConsent")}
        </h2>
        <LiveMeetingMyConsent meetingId={props.meetingId} />
      </div>
    )
  }

  const renderYourView = () => {
    return (
      <div className={classNames("flex", "flex-col", "h-full")}>
        <h2 className="bg-ch-blue-200 p-2">
          {t("liveMeeting:yourView")}
        </h2>
        <LiveMeetingYourViewAssets meetingId={props.meetingId} />
      </div>
    )
  }

  const renderUsersView = () => {
    // We always need to render the users view, as at the moment, the
    // WebRTC streams are tied to them. I'm not sure there's a way around that.
    const showUsersView = selectedYourViewTab === YourViewTabs.USERS_VIEW && caseStage != CaseStage.APPROVING
    return (
      <div className={classNames("flex", "flex-col", "h-full", { "hidden": !showUsersView })}>
        <div className="flex justify-between items-center bg-ch-blue-200 p-2">
          <h2>
            {t("liveMeeting:users")}
          </h2>
          <LiveMeetingUsersViewSwitch />
        </div>

        <div className="p-2 overflow-y-auto w-full">
          <LiveMeetingUsersView meetingId={props.meetingId} />
        </div>
      </div>
    )
  }
  return (
    <div className="h-full">
      {decideWhatToRender()}
      {renderUsersView()}
    </div>
  )
}

export default LiveMeetingYourView
