import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { MockStore } from "redux-mock-store"
import { IAppState } from "../../../../app/appTypes"
import MeetingUserListItem from "./MeetingUserListItem"
import { IMeeting, MeetingStatuses } from "../../../meeting/state/meetingTypes"
import { getMeetingById } from "../../../../shared/selectors/meeting"

interface IPropsFromParent {
  store?: MockStore;
  meetingId: string;
}

const MeetingUserList: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["user"])
  const meeting = useSelector((state: IAppState): IMeeting => getMeetingById(state, props))

  if (!meeting.meetingUsers) return null
  if (!meeting.meetingUsers.length) return <span>{t("user:noUsers")}</span>
  return (
    <ul>
      {meeting.meetingUsers.map((meetingUserId: string) => {
        return (
          <MeetingUserListItem
            key={meetingUserId}
            meetingUserId={meetingUserId}
            meetingEnded={meeting.status == MeetingStatuses.Ended}
          />
        )
      })}
    </ul>
  )
}

export default MeetingUserList
