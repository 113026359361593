import { FormikErrors, FormikTouched } from "formik"
import { ICreatedBy } from "../../../shared/state/sharedTypes"
import { IGuestUserNewData } from "../../user/state/userTypes"

// Models
export interface ICaseMemberStatus {
  userId: string
  sharedAssetCount: number
}

export interface ICaseMemberStatusByUserId {
  [userId: string]: ICaseMemberStatus
}

export interface ICase {
  id: string
  title: string
  firstName: string
  lastName: string
  dateOfBirth: string
  gender: string
  healthcareId: string
  careProviderId: string
  caseType: string
  leadCareUser: string
  classification: string
  caseClassificationId: string
  updatedAt: string
  createdAt: string
  createdBy: ICreatedBy
  sourceName: string
  sourceId: string
  assets?: string[]
  permissions: ICasePermissions
  members?: string[]
  meetings?: string[]
  tasks?: string[]
  casePathways?: string[]
  signedOffBy?: string
  targetDate?: string
  targetDateFulfilled?: boolean
  targetDateFulfilledOn?: string
  locked?: boolean
  outstandingTaskCount?: number
  caseMembersStatus?: ICaseMemberStatus[]
}

export interface ICaseNewData {
  patientId: string
  title: string
  firstName: string
  lastName: string
  caseType: string
  gender: string
  caseClassificationId: string
  leadCareUserId: string
  dateOfBirth: string
  healthcareId: string
  careProviderId: string
  signedOffBy?: string
  targetDate?: string
}

export interface ICaseUpdateData {
  id: string
  caseType?: string
  caseClassificationId?: string
  leadCareUserId?: string
  memberIds?: string[]
  signedOffBy?: string
  targetDate?: string
  targetDateFulfilled?: boolean
  teamIds?: string[]
  sourceId?: string
}

export interface ICasePermissions {
  canRead: boolean
  canEdit: boolean
  canContribute: boolean
  canInvite: boolean
  canManage: boolean
  canShareAsset: boolean
  canTrackChanges: boolean
}

// State
export enum CaseTypes {
  SuspectedCancer = "suspected_cancer",
  Cancer = "cancer",
  NonCancer = "non_cancer"
}

export interface IHaveCaseId {
  caseId: string
}

export interface IHaveCaseIdParam {
  match?: {
    params: {
      caseId: string
    }
  }
}

// Actions
export interface IFetchCaseAsyncAction {
  type: string,
  payload: string
}

export interface ICreateCaseAction {
  type: string
  payload: ICaseNewData
}

export interface IUpdateCaseAction {
  type: string
  payload: ICaseUpdateData
}

export interface ICaseLockedData {
  id: string
  locked: boolean
}

export interface IToggleCaseLockedAction {
  type: string
  payload: ICaseLockedData
}

export interface ICreateCaseGuestUserAction {
  type: string
  payload: {
    caseId: string
    guestUserData: IGuestUserNewData
  }
}

export interface ICreateGuestUserAction {
  type: string
  payload: {
    guestUserData: IGuestUserNewData
  }
}
// Action Types
export enum CaseActionTypes {
  FETCH_CASE_ASYNC_PENDING = "@@case/FETCH_CASE_ASYNC_PENDING",
  FETCH_CASE_ASYNC_SUCCESS = "@@case/FETCH_CASE_ASYNC_SUCCESS",
  FETCH_CASE_ASYNC_ERROR = "@@case/FETCH_CASE_ASYNC_ERROR",

  UPDATE_CASE_ASYNC_PENDING = "@@case/UPDATE_CASE_ASYNC_PENDING",
  UPDATE_CASE_ASYNC_SUCCESS = "@@case/UPDATE_CASE_ASYNC_SUCCESS",
  UPDATE_CASE_ASYNC_ERROR = "@@case/UPDATE_CASE_ASYNC_ERROR",

  UPDATE_CASE_MEMBERS_ASYNC_PENDING = "@@case/UPDATE_CASE_MEMBERS_ASYNC_PENDING",
  UPDATE_CASE_MEMBERS_ASYNC_SUCCESS = "@@case/UPDATE_CASE_MEMBERS_ASYNC_SUCCESS",
  UPDATE_CASE_MEMBERS_ASYNC_ERROR = "@@case/UPDATE_CASE_MEMBERS_ASYNC_ERROR",

  TOGGLE_CASE_LOCKED_ASYNC_PENDING = "@@case/TOGGLE_CASE_LOCKED_ASYNC_PENDING",
  TOGGLE_CASE_LOCKED_ASYNC_SUCCESS = "@@case/TOGGLE_CASE_LOCKED_ASYNC_SUCCESS",
  TOGGLE_CASE_LOCKED_ASYNC_ERROR = "@@case/TOGGLE_CASE_LOCKED_ASYNC_ERROR",

  CREATE_CASE_GUEST_USER_ASYNC_PENDING = "@@case/CREATE_CASE_GUEST_USER_ASYNC_PENDING",
  CREATE_CASE_GUEST_USER_ASYNC_SUCCESS = "@@case/CREATE_CASE_GUEST_USER_ASYNC_SUCCESS",
  CREATE_CASE_GUEST_USER_ASYNC_ERROR = "@@case/CREATE_CASE_GUEST_USER_ASYNC_ERROR"
}

export const CaseChannelName = "CaseChannel"
export const CaseCablePrefix = "@@caseCable/"

export enum CaseCableActionTypes {
  ASSETS_CHANGED = "@@caseCable/ASSETS_CHANGED",
  MEMBERS_CHANGED = "@@caseCable/MEMBERS_CHANGED"
}

export type AddCaseErrors = FormikErrors<ICaseNewData>
export type AddCaseTouched = FormikTouched<ICaseNewData>
