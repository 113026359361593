import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import Button from "../../../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../../../shared/state/sharedTypes"
import { IconComments } from "../../../../../../svg/icons"
import { Formik, Form } from "formik"
import DenotesRequiredMessage from "../../../../../../shared/components/forms/DenotesRequiredMessage"
import Buttons from "../../../../../../shared/components/layout/Buttons"
import RecommendationFields from "./RecommendationFields"
import { IRecommendationNewData } from "../../../../../assets/state/assetsTypes"
import * as Yup from "yup"
import i18n from "../../../../../../app/i18n"
import { createCaseRecommendationAction } from "../../../../../assets/state/assetsActions"
import { ICase } from "../../../../state/caseTypes"
import { IAppState } from "../../../../../../app/appTypes"
import { getCaseById } from "../../../../../../shared/selectors/case"
import { formatTimeString, dateWithTime } from "../../../../../../shared/helpers/timeHelpers"
import { addDays, format, setMinutes } from "date-fns"
import { IUser } from "../../../../../user/state/userTypes"
import { useParams } from "react-router"
import { IHaveMeetingCaseId } from "../../../../../meetingCase/state/meetingCaseTypes"
import { useAppSelector } from "../../../../../../shared/hooks"
import { getAllMeetingCaseAssetIds } from "../../../../../../shared/selectors/asset"
import WarningBanner from "../../../../../../shared/components/text/WarningBanner"

export const validationSchema = (): Yup.AnyObjectSchema => {
  return Yup.object().shape({
    body: Yup.string()
      .trim()
      .required(i18n.t("shared:required"))
      .max(5000, i18n.t("shared:maxLengthExceeded", { maxLength: 5000 })),
    requestConsentUserIds: Yup.array().min(1, i18n.t("asset:selectUserForConsentErrorMessage")),
    deadlineDate:
      Yup.date().required(i18n.t("shared:required")),
    deadlineTime: Yup.string()
      .trim()
      .required(i18n.t("shared:required"))
  })
}

interface IProps {
  closeAction: () => void
  caseId: string
  mentionableUsers: { [id: string]: IUser }
  switchToNewAssetTab: () => void
}

const RecommendationNewForm: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["shared", "case"])
  const { meetingCaseId } = useParams<IHaveMeetingCaseId>()
  const meetingCaseAssetIds = useAppSelector((state) => getAllMeetingCaseAssetIds(state, { meetingCaseId }))

  const dispatch = useDispatch()
  const createRecommendation = (caseId: string, recommendation: IRecommendationNewData, meetingCaseId?: string) =>
    dispatch(createCaseRecommendationAction(caseId, recommendation, meetingCaseId))

  const currentCase: ICase = useSelector((state: IAppState) => getCaseById(state, { caseId: props.caseId }))
  const time = addDays(new Date(), 7)

  const defaultDeadlineDate = setMinutes(time, 0)
  const defaultDeadlineTime = format(defaultDeadlineDate, formatTimeString)

  const noMeetingCaseAssetsWarning = () => {

    if (!meetingCaseId || !meetingCaseAssetIds || meetingCaseAssetIds?.length > 0) return null

    return (<div className="mb-5"><WarningBanner>{t("case:noMeetingCaseAssets")}</WarningBanner></div> )
  }

  const initialValues: IRecommendationNewData = {
    body: "",
    requestConsentUserIds: [],
    deadlineDate: defaultDeadlineDate,
    deadlineTime: defaultDeadlineTime,
    meetingCaseId
  }

  return (
    <div>
      <h4 className="flex mb-2">
        <IconComments className="w-6 h-6 mr-2 text-ch-purple-500" title={t("case:addRecommendation")} />
        {meetingCaseId ? t("case:addMeetingRecommendation") : t("case:addCaseRecommendation")}
      </h4>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          values.deadlineDate = dateWithTime(values.deadlineDate, values.deadlineTime)
          createRecommendation(props.caseId, values, meetingCaseId)
          setSubmitting(false)
          props.closeAction()
          props.switchToNewAssetTab()
        }}
      >
        {({
          isSubmitting,
          setFieldValue,
          errors,
          values,
          touched,
          handleChange,
          handleBlur,
          isValid,
          dirty
        }) => {
          return (
            <Form className="w-full">
              <DenotesRequiredMessage />

              <RecommendationFields
                mentionableUsers={props.mentionableUsers}
                errors={errors}
                touched={touched}
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                caseId={currentCase.id}
              />

              {noMeetingCaseAssetsWarning()}

              <Buttons
                className="mb-2"
                buttons={[
                  <Button
                    action={() => { props.closeAction() }}
                    variant={StyleVariants.BLUE_ALT}
                    key="1"
                  >
                    {t("shared:cancel")}
                  </Button>,
                  <Button
                    isDisabled={isSubmitting || !isValid || !dirty}
                    variant={StyleVariants.PURPLE}
                    isSubmit={true}
                    key="2"
                  >
                    {t("case:addRecommendation")}
                  </Button>
                ]}
              />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default RecommendationNewForm
