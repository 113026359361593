import React from "react"
import { useTranslation } from "react-i18next"
import { IconQuestionMark } from "../../../svg/icons"
import ButtonLink from "./ButtonLink"

interface IPropsFromParent {
  asButton?: boolean
  action: VoidFunction
}

const HelpButton: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["shared"])

  return (
    <div className="mt-auto">
      <ButtonLink
        noPaddingY={!props.asButton}
        noPaddingX={!props.asButton}
        variant="ch-gray-alt"
        baseTextColour={props.asButton ? "text-ch-gray-500" : null}
        hoverTextColour={props.asButton ? "hover:text-white" : null}
        className={props.asButton ? "hover:bg-ch-blue-700 rounded-md" : null}
        action={props.action}
      >
        <IconQuestionMark className="w-5 h-5 mr-2" title={t("shared:helpGuide")} />
        <span>
          {t("shared:helpGuide")}
        </span>
      </ButtonLink>
    </div >
  )
}

export default HelpButton
