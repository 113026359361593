import { ICreatedBy } from "../../../shared/state/sharedTypes"
import { IBaseAction } from "../../../shared/types/baseActionTypes"
import { ConsentResponse, RecommendationStatus, RecommendationType } from "../../liveMeeting/state/liveMeetingTypes"
import { IMessageDocumentAsset } from "../../message/state/messageTypes"

export interface IAssetPermissions {
  canDelete: boolean
  canEdit: boolean
}

export interface IAsset {
  id: string
  asset: ICaseAsset | ILinkAsset | ICommentAsset | IRecommendationAsset | IDocumentAsset | IMessageDocumentAsset
  assetType: string
  createdAt: string
  createdBy: ICreatedBy
  decisionId: string
  taskResponseId?: string
  taskId?: string
  permissions: IAssetPermissions
}

export interface IPatientRadiologyLink {
  idFile: number
  fileUuid: string
  name: string

}

export interface IHaveAssetId {
  assetId: string
}

export interface IAssetMeeting {
  name: string
  specialtyName: string
  startDate: string
  endDateActual: string
}

export interface ICaseAsset {
  createdAt: string
  createdBy: ICreatedBy
  meetingId?: string
  taskId?: string
  meeting?: IAssetMeeting
  summary?: string
}

export interface ILinkAsset extends ICaseAsset {
  title: string
  link: string
  originalFileName: string
}

export interface IRadiologyLinkAsset extends ICaseAsset {
  title: string
  link: string
}

export interface ICommentAsset extends ICaseAsset {
  commentType: string
  title: string
  body: string
}

export interface IRecommendationAsset extends ICommentAsset {
  id: string
  recommendationType: RecommendationType
  recommendationStatus: RecommendationStatus
  deadline: string
  consents: IRecommendationConsent[]
}

export interface IConsentPermission {
  canUpdate: boolean
}

export interface IRecommendationConsent {
  consentComment: string
  user: ICreatedBy
  response: ConsentResponse
  consentBy: ICreatedBy
  permissions?: IConsentPermission
}

export interface ICommentNewData {
  commentType: string
  title: string
  body: string
  meetingCaseId?: string
}

export interface IRecommendationNewData {
  body: string
  requestConsentUserIds: string[]
  deadlineDate: Date
  deadlineTime: string
  meetingCaseId?: string
}

export interface ILinkNewData {
  title: string
  link: string
  meetingCaseId?: string
}

export interface IRadiologyLinkNewData {
  title: string
  fileUuid: string
  idFile: number
  dicomName: string
  meetingCaseId?: string
}

export interface ICreateCaseCommentAsyncAction extends IBaseAction {
  payload: {
    caseId: string
    meetingCaseId?: string
    comment: ICommentNewData
  }
}

export interface ICreateCaseRecommendationAsyncAction extends IBaseAction {
  payload: {
    caseId: string
    meetingCaseId?: string
    recommendation: IRecommendationNewData
  }
}

export interface IUploadCaseFileFormValues {
  file: File
  name: string
  meetingCaseId?: string
}

export interface IDeleteCaseAssetData {
  caseId: string
  assetId: string
}

export interface IUploadCaseFileAsyncAction extends IBaseAction {
  payload: {
    caseId: string
    values: IUploadCaseFileFormValues
    meetingCaseId: string
  }
}

export interface IDocumentAsset extends ICaseAsset {
  name: string
  bytes: number
  path: string
  contentType: string
}

export interface ICaseReportAsset extends ICaseAsset {
  name: string
  bytes: number
  path: string
  contentType: string
  status: string
}

export enum CaseReportStatuses {
  PENDING = "pending",
  DONE = "done"
}

export interface IFetchCaseAssetsAsyncAction extends IBaseAction {
  payload: string
}

export interface IDeleteAssetAsyncAction extends IBaseAction {
  payload: IDeleteCaseAssetData
}

export interface IFetchPatientRadiologyLinksAction extends IBaseAction {
  payload: {
    caseId: string
  }
}

export interface ICreateCaseRadiologyLinkAsyncAction extends IBaseAction {
  payload: {
    caseId: string
    meetingCaseId?: string
    radiologyLink: IRadiologyLinkNewData
  }
}

export interface ICreateCaseLinkAsyncAction extends IBaseAction {
  payload: {
    caseId: string
    meetingCaseId?: string
    link: ILinkNewData
  }
}

export interface IAddConsentAction extends IBaseAction {
  payload: {
    recommendationId: string
    userId: string
    response: ConsentResponse
    comment: string
  }
}

export interface ISaveRecommendationAction extends IBaseAction {
  payload: {
    recommendationId: string
    caseId: string
  }
}

export enum AssetsActionTypes {
  FETCH_CASE_ASSETS_ASYNC_PENDING = "@@assets/FETCH_CASE_ASSETS_ASYNC_PENDING",
  FETCH_CASE_ASSETS_ASYNC_SUCCESS = "@@assets/FETCH_CASE_ASSETS_ASYNC_SUCCESS",
  FETCH_CASE_ASSETS_ASYNC_ERROR = "@@assets/FETCH_CASE_ASSETS_ASYNC_ERROR",

  FETCH_PATIENT_RADIOLOGY_LINKS_ASYNC_PENDING = "@@assets/FETCH_PATIENT_RADIOLOGY_LINKS_ASYNC_PENDING",
  FETCH_PATIENT_RADIOLOGY_LINKS_ASYNC_SUCCESS = "@@assets/FETCH_PATIENT_RADIOLOGY_LINKS_ASYNC_SUCCESS",
  FETCH_PATIENT_RADIOLOGY_LINKS_ASYNC_ERROR = "@@assets/FETCH_PATIENT_RADIOLOGY_LINKS_ASYNC_ERROR",

  CREATE_CASE_COMMENT_ASYNC_PENDING = "@@assets/CREATE_CASE_COMMENT_ASYNC_PENDING",
  CREATE_CASE_COMMENT_ASYNC_SUCCESS = "@@assets/CREATE_CASE_COMMENT_ASYNC_SUCCESS",
  CREATE_CASE_COMMENT_ASYNC_ERROR = "@@assets/CREATE_CASE_COMMENT_ASYNC_ERROR",

  UPLOAD_CASE_FILE_ASYNC_PENDING = "@@assets/UPLOAD_CASE_FILE_ASYNC_PENDING",
  UPLOAD_CASE_FILE_ASYNC_SUCCESS = "@@assets/UPLOAD_CASE_FILE_ASYNC_SUCCESS",
  UPLOAD_CASE_FILE_ASYNC_ERROR = "@@assets/UPLOAD_CASE_FILE_ASYNC_ERROR",

  DELETE_CASE_ASSET_ASYNC_PENDING = "@@assets/DELETE_CASE_ASSET_ASYNC_PENDING",
  DELETE_CASE_ASSET_ASYNC_SUCCESS = "@@assets/DELETE_CASE_ASSET_ASYNC_SUCCESS",
  DELETE_CASE_ASSET_ASYNC_ERROR = "@@assets/DELETE_CASE_ASSET_ASYNC_ERROR",

  CREATE_CASE_LINK_ASYNC_PENDING = "@@assets/CREATE_CASE_LINK_ASYNC_PENDING",
  CREATE_CASE_LINK_ASYNC_SUCCESS = "@@assets/CREATE_CASE_LINK_ASYNC_SUCCESS",
  CREATE_CASE_LINK_ASYNC_ERROR = "@@assets/CREATE_CASE_LINK_ASYNC_ERROR",

  CREATE_CASE_RADIOLOGY_LINK_ASYNC_PENDING = "@@assets/CREATE_CASE_RADIOLOGY_LINK_ASYNC_PENDING",
  CREATE_CASE_RADIOLOGY_LINK_ASYNC_SUCCESS = "@@assets/CREATE_CASE_RADIOLOGY_LINK_ASYNC_SUCCESS",
  CREATE_CASE_RADIOLOGY_LINK_ASYNC_ERROR = "@@assets/CREATE_CASE_RADIOLOGY_LINK_ASYNC_ERROR",

  CREATE_CASE_RECOMMENDATION_ASYNC_PENDING = "@@assets/CREATE_CASE_RECOMMENDATION_ASYNC_PENDING",
  CREATE_CASE_RECOMMENDATION_ASYNC_SUCCESS = "@@assets/CREATE_CASE_RECOMMENDATION_ASYNC_SUCCESS",
  CREATE_CASE_RECOMMENDATION_ASYNC_ERROR = "@@assets/CREATE_CASE_RECOMMENDATION_ASYNC_ERROR",

  ADD_CONSENT_ASYNC_PENDING = "@@assets/ADD_CONSENT_ASYNC_PENDING",
  ADD_CONSENT_ASYNC_SUCCESS = "@@assets/ADD_CONSENT_ASYNC_SUCCESS",
  ADD_CONSENT_ASYNC_ERROR = "@@assets/ADD_CONSENT_ASYNC_ERROR",

  SAVE_CASE_RECOMMENDATION_ASYNC_PENDING = "@@assets/SAVE_CASE_RECOMMENDATION_ASYNC_PENDING",
  SAVE_CASE_RECOMMENDATION_ASYNC_SUCCESS = "@@assets/SAVE_CASE_RECOMMENDATION_ASYNC_SUCCESS",
  SAVE_CASE_RECOMMENDATION_ASYNC_ERROR = "@@assets/SAVE_CASE_RECOMMENDATION_ASYNC_ERROR"
}

// Capitalised value due to rails class name
export enum AssetTypes {
  Link = "Link",
  RadiologyLink = "RadiologyLink",
  Comment = "Comment",
  Document = "Document",
  CaseReport = "CaseReport",
  MessageDocument = "MessageDocument"
}

export enum CommentTypes {
  Comment = "comment",
  Decision = "decision",
  Deferral = "deferral",
  Recommendation = "recommendation"
}

// types of component rendered depending on asset properties

export enum AssetComponentTypes {
  Link = "link",
  RadiologyLink = "radiology_link",
  Comment = "comment",
  Decision = "decision",
  Deferral = "deferral",
  Recommendation = "recommendation",
  Pdf = "pdf",
  Image = "image",
  CaseReport = "report",
}

export interface IPatientRadiologyLinkUIState {
  fetching: boolean
}
