import React, { useState, useEffect } from "react"
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom"
import AuthenticatedRoute from "./AuthenticatedRoute"
import { useTranslation } from "react-i18next"
import { translationFilenames } from "../i18n"
import LiveMeetingPage from "../../pages/liveMeeting/LiveMeetingPage"
import UserNewPage from "../../pages/user/UserNewPage"
import UserNewGuestPage from "../../pages/user/UserNewGuestPage"
import MeetingPage from "../../pages/meeting/MeetingPage"
import MeetingsPage from "../../pages/meeting/MeetingsPage"
import SignInPage from "../../pages/account/SignInPage"
import NewMeetingPage from "../../pages/meeting/MeetingNewPage"
import EditMeetingPage from "../../pages/meeting/MeetingEditPage"
import UsersPage from "../../pages/user/UsersPage"
import UserEditPage from "../../pages/user/UserEditPage"
import SetPasswordPage from "../../pages/account/SetPasswordPage"
import CasesPage from "../../pages/case/CasesPage"
import CasePage from "../../pages/case/CasePage"
import CaseEditPage from "../../pages/case/CaseEditPage"
import MeetingCasePage from "../../pages/meetingCase/MeetingCasePage"
import NotificationsPage from "../../pages/notification/NotificationsPage"
import TermsPage from "../../pages/account/TermsPage"
import AdminPage from "../../pages/admin/AdminPage"
import AppLayout from "../../layouts/AppLayout"
import AccountLayout from "../../layouts/AccountLayout"
import TermsLayout from "../../layouts/TermsLayout"
import SetupTotpCompletePage from "../../pages/account/SetupTotpCompletePage"
import MfaTotpPage from "../../pages/account/MfaTotpPage"
import MfaPage from "../../pages/account/MfaPage"
import SetupSmsCompletePage from "../../pages/account/SetupSmsCompletePage"
import TasksPage from "../../pages/task/TasksPage"
import PatientsPage from "../../pages/patients/PatientsPage"
import PathwaysPage from "../../pages/pathways/PathwaysPage"
import PathwayPage from "../../pages/pathways/PathwayPage"
import PathwayNewPage from "../../pages/pathways/PathwaysNewPage"
import PatientPage from "../../pages/patients/PatientPage"
import PatientNewPage from "../../pages/patients/PatientNewPage"
import PatientCaseNewPage from "../../pages/patients/PatientCaseNewPage"
import PatientEditDetailsPage from "../../pages/patients/PatientEditDetailsPage"
import PatientEditFurtherDetailsPage from "../../pages/patients/PatientEditFurtherDetailsPage"
import TeamsPage from "../../pages/teams/TeamsPage"
import TeamsNewPage from "../../pages/teams/TeamsNewPage"
import TeamPage from "../../pages/teams/TeamPage"
import TeamEditPage from "../../pages/teams/TeamEditPage"
import history from "./history"
import { IAppState } from "../appTypes"
import { useSelector } from "react-redux"
import MessagesPage from "../../pages/messages/MessagesPage"
import { getCurrentUser } from "../../shared/selectors/user"
import { isGuest } from "../../shared/helpers/userHelpers"
import AnalyticsPage from "../../pages/analytics/AnalyticsPage"
import RadiologyPage from "../../pages/radiology/RadiologyPage"

export enum RoutePaths {
  ROOT = "/",
  SIGN_IN = "/signin",
  SETUP_MFA_INTRO = "/setup-mfa/intro",
  SETUP_MFA_TOTP = "/setup-mfa/totp",
  SETUP_MFA_TOTP_COMPLETE = "/setup-mfa/totp-complete",
  SETUP_MFA_SMS_COMPLETE = "/setup-mfa/sms-complete",
  MFA_TOTP = "/mfa/totp",
  TERMS = "/terms",
  SET_PASSWORD = "/set-password",
  MEETING_LIVE = "/meeting/:meetingId/live",
  MEETINGS = "/meetings",
  MEETING_NEW = "/meeting/new",
  MEETING_EDIT = "/meeting/:meetingId/edit",
  MEETING = "/meeting/:meetingId",
  MEETING_CASE = "/meetingcase/:meetingCaseId/:currentTab?",
  USERS = "/users",
  USER = "/user/:userId",
  USER_NEW = "/user/new",
  USER_NEW_GUEST = "/user/new_guest",
  USER_EDIT = "/user/:userId/edit",
  CASES = "/cases",
  CASE_NEW = "/case/new",
  CASE_EDIT = "/case/:caseId/edit",
  CASE_LOCK = "/case/:caseId/lock",
  CASE_UNLOCK = "/case/:caseId/unlock",
  CASE = "/case/:caseId/:currentTab?",
  PATIENTS = "/patients",
  PATIENT = "/patient/:patientId",
  PATIENT_NEW = "/patient/new",
  PATIENT_EDIT_DETAILS = "/patient/:patientId/details/edit",
  PATIENT_EDIT_FURTHER_DETAILS = "/patient/:patientId/further-details/edit",
  PATIENT_CASE_NEW = "/patient/:patientId/cases/new",
  RADIOLOGY = "/radiology",
  NOTIFICATIONS = "/notifications",
  TASKS = "/tasks",
  ADMIN = "/admin",
  TEAMS = "/teams",
  TEAMS_NEW = "/team/new",
  TEAM_EDIT = "/team/:teamId/edit",
  TEAM = "/team/:teamId",
  MESSAGES = "/messages",
  PATHWAYS = "/pathways",
  PATHWAY = "/pathway/:pathwayId",
  PATHWAY_NEW = "/pathway/new",
  ANALYTICS = "/analytics"
}

const blacklistDeepLinks: string[] = [
  RoutePaths.SIGN_IN,
  RoutePaths.SET_PASSWORD,
  RoutePaths.SETUP_MFA_INTRO,
  RoutePaths.SETUP_MFA_TOTP,
  RoutePaths.SETUP_MFA_TOTP_COMPLETE,
  RoutePaths.SETUP_MFA_SMS_COMPLETE,
  RoutePaths.MFA_TOTP
]

export const Routes = (): JSX.Element => {
  // preload all transations
  useTranslation(translationFilenames)

  // the following is to support deep links while not signed in
  // initially storing the deep link should be done synchronously, as otherwise
  // the redirect to sign in has already taken place
  const [deepLinkPath, setDeepLinkPath] = useState<string>(null)
  const apiToken = useSelector((state: IAppState) => state.userSession.apiToken)
  const currentUser = useSelector(getCurrentUser)
  if (!apiToken && !deepLinkPath) {
    setDeepLinkPath(location.pathname)
  }
  useEffect(() => {
    if (!apiToken || !currentUser || currentUser.mfaRequired) return
    if (deepLinkPath && !blacklistDeepLinks.includes(deepLinkPath)) {
      history.replace(deepLinkPath)
    }
    setDeepLinkPath(null)
  }, [apiToken, currentUser?.mfaRequired])

  return (
    <Switch>
      <Route path={RoutePaths.SIGN_IN} render={() => <AccountLayout><SignInPage /></AccountLayout>} />
      <Route path={RoutePaths.SET_PASSWORD} render={() => <AccountLayout><SetPasswordPage /></AccountLayout>} />

      <Route path={RoutePaths.TERMS} render={() => <TermsLayout><TermsPage /></TermsLayout>} />

      <Route path="/tenant/:tenantId?" component={AuthenticatedApp} />
      <Route path="/" component={AuthenticatedApp} />
    </Switch>
  )
}

const AuthenticatedApp: React.FC = () => {
  const currentUser = useSelector(getCurrentUser)
  const match = useRouteMatch()
  const matchedPath = match.path == "/" ? "" : match.path
  return (
    <Switch>
      <Route exact path={matchedPath + RoutePaths.ROOT}>
        {
          currentUser && isGuest(currentUser)
            ? <Redirect to={matchedPath + RoutePaths.CASES} />
            : <Redirect to={matchedPath + RoutePaths.MEETINGS} />
        }
      </Route>

      <AuthenticatedRoute
        path={matchedPath + RoutePaths.MEETING_LIVE}
        component={LiveMeetingPage}
      />

      <AuthenticatedRoute
        path={matchedPath + RoutePaths.MEETINGS}
        component={MeetingsPage}
        layout={AppLayout}
      />
      <AuthenticatedRoute
        path={matchedPath + RoutePaths.MEETING_NEW}
        component={NewMeetingPage}
        layout={AppLayout}
      />
      <AuthenticatedRoute
        path={matchedPath + RoutePaths.MEETING_EDIT}
        component={EditMeetingPage}
        layout={AppLayout}
      />
      <AuthenticatedRoute
        path={matchedPath + RoutePaths.MEETING}
        component={MeetingPage}
        layout={AppLayout}
      />
      <AuthenticatedRoute
        path={matchedPath + RoutePaths.RADIOLOGY}
        component={RadiologyPage}
        layout={AppLayout}
      />
      <AuthenticatedRoute
        path={matchedPath + RoutePaths.MEETING_CASE}
        component={MeetingCasePage}
        layout={AppLayout}
      />

      <AuthenticatedRoute
        path={matchedPath + RoutePaths.USERS}
        component={UsersPage}
        layout={AppLayout}
      />

      <AuthenticatedRoute
        path={matchedPath + RoutePaths.USER_NEW}
        component={UserNewPage}
        layout={AppLayout}
      />
      <AuthenticatedRoute
        path={matchedPath + RoutePaths.USER_NEW_GUEST}
        component={UserNewGuestPage}
        layout={AppLayout}
      />

      <AuthenticatedRoute
        path={matchedPath + RoutePaths.USER_EDIT}
        component={UserEditPage}
      />

      <AuthenticatedRoute
        path={matchedPath + RoutePaths.CASES}
        component={CasesPage}
        layout={AppLayout}
      />
      <AuthenticatedRoute
        path={matchedPath + RoutePaths.CASE_EDIT}
        component={CaseEditPage}
        layout={AppLayout}
      />
      <AuthenticatedRoute
        path={matchedPath + RoutePaths.CASE}
        component={CasePage}
        layout={AppLayout}
      />
      <AuthenticatedRoute
        path={matchedPath + RoutePaths.NOTIFICATIONS}
        component={NotificationsPage}
        layout={AppLayout}
      />
      <AuthenticatedRoute
        path={matchedPath + RoutePaths.TASKS}
        component={TasksPage}
        layout={AppLayout}
      />
      <AuthenticatedRoute
        path={matchedPath + RoutePaths.PATIENTS}
        component={PatientsPage}
        layout={AppLayout}
      />
      <AuthenticatedRoute
        path={matchedPath + RoutePaths.PATIENT_EDIT_DETAILS}
        component={PatientEditDetailsPage}
        layout={AppLayout}
      />
      <AuthenticatedRoute
        path={matchedPath + RoutePaths.PATIENT_EDIT_FURTHER_DETAILS}
        component={PatientEditFurtherDetailsPage}
        layout={AppLayout}
      />
      <AuthenticatedRoute
        path={matchedPath + RoutePaths.PATIENT_CASE_NEW}
        component={PatientCaseNewPage}
        layout={AppLayout}
      />
      <AuthenticatedRoute
        path={matchedPath + RoutePaths.PATIENT_NEW}
        component={PatientNewPage}
        layout={AppLayout}
      />
      <AuthenticatedRoute
        path={matchedPath + RoutePaths.PATIENT}
        component={PatientPage}
        layout={AppLayout}
      />

      <AuthenticatedRoute
        path={matchedPath + RoutePaths.PATHWAYS}
        component={PathwaysPage}
        layout={AppLayout}
      />

      <AuthenticatedRoute
        path={matchedPath + RoutePaths.PATHWAY_NEW}
        component={PathwayNewPage}
        layout={AppLayout}
      />

      <AuthenticatedRoute
        path={matchedPath + RoutePaths.PATHWAY}
        component={PathwayPage}
        layout={AppLayout}
      />

      <AuthenticatedRoute
        path={matchedPath + RoutePaths.ADMIN}
        component={AdminPage}
        layout={AppLayout}
      />

      <AuthenticatedRoute
        path={matchedPath + RoutePaths.ANALYTICS}
        component={AnalyticsPage}
        layout={AppLayout}
      />

      <AuthenticatedRoute
        path={matchedPath + RoutePaths.SETUP_MFA_INTRO}
        component={() => <MfaPage directRoute />}
        layout={AccountLayout}
        overrideMfaIntercept
      />

      <AuthenticatedRoute
        path={matchedPath + RoutePaths.SETUP_MFA_TOTP_COMPLETE}
        component={SetupTotpCompletePage}
        layout={AccountLayout}
        overrideMfaIntercept
      />

      <AuthenticatedRoute
        path={matchedPath + RoutePaths.SETUP_MFA_SMS_COMPLETE}
        component={SetupSmsCompletePage}
        layout={AccountLayout}
        overrideMfaIntercept
      />

      <AuthenticatedRoute
        path={matchedPath + RoutePaths.MFA_TOTP}
        component={MfaTotpPage}
        layout={AccountLayout}
      />

      <AuthenticatedRoute
        path={matchedPath + RoutePaths.TEAMS}
        component={TeamsPage}
        layout={AppLayout}
      />

      <AuthenticatedRoute
        path={matchedPath + RoutePaths.TEAM_EDIT}
        component={TeamEditPage}
        layout={AppLayout}
      />

      <AuthenticatedRoute
        path={matchedPath + RoutePaths.TEAMS_NEW}
        component={TeamsNewPage}
        layout={AppLayout}
      />

      <AuthenticatedRoute
        path={matchedPath + RoutePaths.TEAM}
        component={TeamPage}
        layout={AppLayout}
      />

      <AuthenticatedRoute
        path={matchedPath + RoutePaths.MESSAGES}
        component={MessagesPage}
        layout={AppLayout}
      />
    </Switch>
  )
}
