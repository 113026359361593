import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useAppSelector } from "../shared/hooks"
import { getCurrentUserId } from "../shared/selectors/user"
import { updateUISettingAction } from "../features/userConfig/state/userConfigActions"
import { StyleVariants, defaultModalStyle } from "../shared/state/sharedTypes"
import ModalContainer from "../shared/components/modals/ModalContainer"
import Button from "../shared/components/buttons/Button"
import Modal from "react-modal"
import { useTranslation } from "react-i18next"

interface IProps {
  open: boolean
  setOpen: (val: boolean) => void
}

const QuickStartGuide: React.FC<IProps> = (props) => {
  const { t } = useTranslation(["shared"])
  const currentUserId = useAppSelector(getCurrentUserId)
  const { uiSettings } = useAppSelector((state) => state.userConfig)
  const dispatch = useDispatch()
  const closeHelpModal = () => props.setOpen(false)
  const updateUISetting = (value: boolean) => dispatch(updateUISettingAction(currentUserId, { showQuickStartGuide: value }))
  const [variant, setVariant] = useState<StyleVariants>(null)

  useEffect(() => {
    if (!uiSettings) return
    props.setOpen(uiSettings.showQuickStartGuide)
  }, [uiSettings])

  useEffect(() => {
    const _variant = uiSettings?.showQuickStartGuide ? StyleVariants.BLUE : StyleVariants.GRAY
    setVariant(_variant)
  }, [uiSettings])

  return (
    <Modal isOpen={props.open} style={defaultModalStyle} onRequestClose={closeHelpModal} >
      <ModalContainer handleClose={() => props.setOpen(false)}>
        <Button
          action={() => updateUISetting(!uiSettings?.showQuickStartGuide)}
          variant={variant}
          className="absolute top-0 right-8" >
          {uiSettings?.showQuickStartGuide ? t("shared:quickStartGuide:dontShow") : t("shared:quickStartGuide:showOnLogin")}
        </Button>
        <iframe src="https://contexthealth.com/contextmdt-quick-start/" width="100%" height="100%" />
      </ModalContainer>
    </Modal>
  )
}

export default QuickStartGuide
