import { Reducer, AnyAction, combineReducers } from "redux"
import _ from "lodash"
import { INormalizedEntities } from "../../../../app/appTypes"
import { ChatMessagesActionTypes } from "../../../messages/state/messagesTypes"
import { IMessage } from "../messageTypes"
import { ChatCableActionTypes } from "../../../chat/state/chatTypes"
import { normalize } from "normalizr"
import { fetchMessagesSchema } from "../../../../apis/contextmeeting/schema"

type TByIdState = { [id: string]: IMessage }
type TAllIdsState = string[]

export const byIdInitialState: TByIdState = {}
export const allIdsInitialState: TAllIdsState = []

export const byId: Reducer<TByIdState> = (state: TByIdState = byIdInitialState, action: AnyAction): TByIdState => {
  switch (action.type) {
    case ChatMessagesActionTypes.FETCH_CHAT_MESSAGES_ASYNC_SUCCESS:
      return _.merge({}, state, action.payload.all.entities.messages)
    case ChatCableActionTypes.DELIVER_MESSAGE:
    case ChatCableActionTypes.UPDATE_MESSAGE:
      return {
        ...state,
        // Payload from cable must be normalized
        ...normalize(action.payload.message, fetchMessagesSchema).entities.messages || {}
      }

    default:
      return { ...state }
  }
}

export const allIds: Reducer<TAllIdsState> = (state: TAllIdsState = allIdsInitialState, action: AnyAction): TAllIdsState => {
  switch (action.type) {
    default:
      return state
  }
}

export const messageEntityReducer: Reducer<INormalizedEntities<IMessage>> = combineReducers({
  byId,
  allIds
})
