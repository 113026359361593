import { takeLeading, put, call } from "redux-saga/effects"
import _ from "lodash"
import { WebRTCActionTypes } from "../webRTCTypes"
import { connectToSession, disconnectFromSession } from "../../../../apis/webRTC/webRTC"
import { handleError, logError } from "../../../../shared/helpers/sagaHelpers"

export function* connectToWebRTCSessionAsync(action) {
  try {
    const session: OT.Session = yield call(connectToSession, action.payload.apiKey, action.payload.sessionId, action.payload.meetingToken, action.payload.eventHandlers)

    yield put({
      type: WebRTCActionTypes.CONNECT_TO_WEB_RTC_SESSION_ASYNC_SUCCESS,
      payload: session
    })
  } catch (error) {
    yield logError("could not connect to WebRTC session", error)
    yield handleError(WebRTCActionTypes.CONNECT_TO_WEB_RTC_SESSION_ASYNC_ERROR, error, "connectToWebRTCSessionError")
  }
}

export function* watchConnectToWebRTCSessionAsync() {
  yield takeLeading(WebRTCActionTypes.CONNECT_TO_WEB_RTC_SESSION_ASYNC_PENDING, connectToWebRTCSessionAsync)
}

export function* disconnectFromWebRTCSessionAsync(action) {
  try {
    yield call(disconnectFromSession, action.payload.session)

    yield put({
      type: WebRTCActionTypes.DISCONNECT_FROM_WEB_RTC_SESSION_ASYNC_SUCCESS
    })
  } catch (error) {
    yield handleError(WebRTCActionTypes.DISCONNECT_FROM_WEB_RTC_SESSION_ASYNC_ERROR, error, "disconnectFromWebRTCSessionError")
  }
}

export function* watchDisconnectFromWebRTCSessionAsync() {
  yield takeLeading(WebRTCActionTypes.DISCONNECT_FROM_WEB_RTC_SESSION_ASYNC_PENDING, disconnectFromWebRTCSessionAsync)
}
