import { CaseActionTypes, IFetchCaseAsyncAction, ICaseUpdateData, IUpdateCaseAction, IToggleCaseLockedAction, ICaseLockedData } from "./caseTypes"

export const fetchCaseAction = (caseId: string): IFetchCaseAsyncAction => {
  return {
    type: CaseActionTypes.FETCH_CASE_ASYNC_PENDING,
    payload: caseId
  }
}

export const updateCase = (caseData: ICaseUpdateData): IUpdateCaseAction => {
  return {
    type: CaseActionTypes.UPDATE_CASE_ASYNC_PENDING,
    payload: caseData
  }
}

export const updateCaseMembers = (caseData: ICaseUpdateData): IUpdateCaseAction => {
  return {
    type: CaseActionTypes.UPDATE_CASE_MEMBERS_ASYNC_PENDING,
    payload: caseData
  }
}

export const toggleCaseLocked = (caseData: ICaseLockedData): IToggleCaseLockedAction => {
  return {
    type: CaseActionTypes.TOGGLE_CASE_LOCKED_ASYNC_PENDING,
    payload: caseData
  }
}
