import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { MockStore } from "redux-mock-store"
import OT from "@opentok/client"
import { publishScreenToWebRTCSessionAction, stopPublishScreenToWebRTCSessionAction, updateWebRTCScreenStreamAction } from "../state/webRTCActions"
import { publisherEventHandlers, webRTCEvent } from "../state/webRTCTypes"
import { getStreamUserId } from "../../../apis/webRTC/webRTC"
import { displayUserMessageAction } from "../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../userMessage/state/userMessageTypes"
import { toggleIsSharingScreenAction } from "../../liveMeeting/state/liveMeetingActions"
import { getWebRTCScreenPublisher, getWebRTCScreenShareIsOn, getWebRTCScreenSharePreferCurrentTab, getWebRTCSession } from "../../../shared/selectors/webRTC"
import { useSelector } from "react-redux"
import { getLiveMeetingConfig } from "../../../shared/selectors/liveMeetingConfig"
import { getCurrentUserId } from "../../../shared/selectors/user"

interface IProps {
  store?: MockStore,
  publisherOptions: OT.PublisherProperties
}

// Adding the preferCurrentTab option for now, as it is missing in the global
// type definition. Eventually this should probably go, when the option is
// included in typescript.
declare global {
  interface DisplayMediaStreamOptions {
    audio?: boolean | MediaTrackConstraints
    video?: boolean | MediaTrackConstraints
    preferCurrentTab?: boolean
  }
}

const WebRTCScreenPublisher: React.FC<IProps> = (props: IProps): JSX.Element => {
  const containerRef = React.useRef<HTMLDivElement>()
  const dispatch = useDispatch()
  const screenPublisher = useSelector(getWebRTCScreenPublisher)
  const session = useSelector(getWebRTCSession)
  const isScreenShareOn = useSelector(getWebRTCScreenShareIsOn)
  const screenSharePreferCurrentTab = useSelector(getWebRTCScreenSharePreferCurrentTab)
  const connection = screenPublisher?.session.connection
  const [stream, setStream] = useState<MediaStream>(null)
  const { presenterId } = useSelector(getLiveMeetingConfig)
  const currentUserId = useSelector(getCurrentUserId)

  const displayUserMessage = (messageKey: string, type: string) => {
    dispatch(displayUserMessageAction({
      messageKey,
      type
    }))
  }

  const handleStopEvent = (event: webRTCEvent) => {
    const userId = getStreamUserId(event.stream)
    dispatch(updateWebRTCScreenStreamAction(null))
    dispatch(toggleIsSharingScreenAction(userId, false))
  }

  const eventHandlers: publisherEventHandlers = {
    streamCreated: (event: webRTCEvent) => {
      const userId = getStreamUserId(event.stream)
      dispatch(updateWebRTCScreenStreamAction(event.stream))
      dispatch(toggleIsSharingScreenAction(userId, true))
    },
    streamDestroyed: handleStopEvent,
    // for some reason, the screen share in the modal (for the idonia viewer)
    // wants the following event handlers defined as well, just the
    // streamDestroyed isn't enough. Perhaps this has to do with the default
    // behaviour is not to propagate this event? I couldn't figure that out easily.
    destroyed: handleStopEvent,
    mediaStopped: handleStopEvent,
    accessDenied: (_event: webRTCEvent) => {
      displayUserMessage("webRTCAccessDenied", UserMessageTypes.ERROR)
    }
  }

  // whenever the user becomes presenter, switch off their screen share
  useEffect(() => {
    if (!currentUserId && currentUserId != presenterId) return

    if (stream) stream.getTracks()[0].stop()
    dispatch(updateWebRTCScreenStreamAction(null))
    dispatch(toggleIsSharingScreenAction(currentUserId, false))
  }, [presenterId])

  useEffect(() => {
    // Publish screen to session after connection has been established
    // and if screen share is turned on by user
    if (!session?.connection) return
    if (isScreenShareOn && !screenPublisher) {
      if (screenSharePreferCurrentTab) {
        // if we want to share the current tab, we have to call getDisplayMedia
        // ourselves (otherwise we could leave it up to OT), and then, when we
        // have a MediaStreamTrack, we can pass that to OT and it will use that.
        //
        // So here, we are just asking for the stream and then setting it in the
        // state, which will trigger an effect hook (see below) to actually
        // trigger the publish screen action.
        navigator.mediaDevices.getDisplayMedia({
          audio: false,
          video: { displaySurface: "browser" },
          preferCurrentTab: true
        }).then(setStream)
          .catch(() => {
            displayUserMessage("publishScreenToWebRTCSessionError", UserMessageTypes.ERROR)
          })
        return
      }
      // if we are not interested in the current tab, we can just call the
      // publish action and OT will ask for a screen to publish (because
      // videoSource will be set to 'screen').
      dispatch(publishScreenToWebRTCSessionAction(session, containerRef, props.publisherOptions, eventHandlers))
    } else if (!isScreenShareOn && screenPublisher) {
      dispatch(stopPublishScreenToWebRTCSessionAction(session, screenPublisher))
    }
  }, [session, isScreenShareOn])

  useEffect(() => {
    if (!stream) return
    const options = {
      ...props.publisherOptions,
      videoSource: stream.getTracks()[0]
    }
    dispatch(publishScreenToWebRTCSessionAction(session, containerRef, options, eventHandlers))
  }, [stream])

  useEffect(() => {
    // If connection to session if destoryed unpublish screen
    // Streams will then in turn be destroyed
    if (!connection) return
    return () => {
      dispatch(stopPublishScreenToWebRTCSessionAction(session, screenPublisher))
    }
  }, [connection])

  return (
    <div ref={containerRef} className="hidden"></div>
  )
}

export default WebRTCScreenPublisher
