import { takeLeading, put, call } from "redux-saga/effects"
import { WebRTCActionTypes } from "../webRTCTypes"
import { publishToSession, unpublishFromSession } from "../../../../apis/webRTC/webRTC"
import { handleError, logError } from "../../../../shared/helpers/sagaHelpers"

export function* publishToWebRTCSessionAsync(action) {
  try {
    const publisher: OT.Publisher = yield call(publishToSession, action.payload.session, action.payload.containerRef, action.payload.options, action.payload.eventHandlers)

    yield put({
      type: WebRTCActionTypes.PUBLISH_TO_WEB_RTC_SESSION_ASYNC_SUCCESS,
      payload: publisher
    })
  } catch (error) {
    yield logError("error publishing to session", error)
    yield handleError(WebRTCActionTypes.PUBLISH_TO_WEB_RTC_SESSION_ASYNC_ERROR, error,  "publishToWebRTCSessionError")

  }
}

export function* watchPublishToWebRTCSessionAsync() {
  yield takeLeading(WebRTCActionTypes.PUBLISH_TO_WEB_RTC_SESSION_ASYNC_PENDING, publishToWebRTCSessionAsync)
}

export function* unpublishFromWebRTCSession(action) {
  try {
    yield call(unpublishFromSession, action.payload.session, action.payload.publisher)

    yield put({
      type: WebRTCActionTypes.UNPUBLISH_FROM_WEB_RTC_SESSION_SUCCESS
    })
  } catch (error) {
    yield logError("error publishing to session", error)
    yield handleError(WebRTCActionTypes.UNPUBLISH_FROM_WEB_RTC_SESSION_ERROR, error,  "unpublishFromWebRTCSessionError")
  }
}

export function* watchUnpublishFromWebRTCSession() {
  yield takeLeading(WebRTCActionTypes.UNPUBLISH_FROM_WEB_RTC_SESSION_PENDING, unpublishFromWebRTCSession)
}
