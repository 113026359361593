/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getAllCaseIds } from "../../../../shared/selectors/case"
import { IChatNewFormData } from "../../state/chatNewTypes"
import ChatNewCaseListItem from "./ChatNewCaseListItem"

interface Props {
  values: IChatNewFormData
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  closeModal: () => void
}

const ChatNewCaseList = (props: Props): JSX.Element => {
  const { t } = useTranslation(["case"])
  const caseIds = useSelector((state: IAppState) => getAllCaseIds(state))

  if (!caseIds) return (
    <h3 className="text-lg font-bold text-center">
      {t("case:noCases")}
    </h3>
  )

  return (
    <ul>
      {caseIds.map((caseId: string) => {
        return (
          <ChatNewCaseListItem
            key={caseId}
            caseId={caseId}
            values={props.values}
            setFieldValue={props.setFieldValue}
            closeModal={props.closeModal}
          />
        )
      })}
    </ul>
  )
}

export default ChatNewCaseList
