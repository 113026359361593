import React, { useEffect, useState, useCallback } from "react"
import { Formik, Form } from "formik"
import Label from "../../../../shared/components/forms/Label"
import { useTranslation } from "react-i18next"
import { giveConsentAction } from "../../state/liveMeetingActions"
import { ConsentResponse } from "../../state/liveMeetingTypes"
import { useDispatch } from "react-redux"
import { IconReject, IconNa, IconAccept } from "../../../../svg/icons"
import classNames from "classnames"
import FieldError from "../../../../shared/components/forms/FieldError"
import MentionField from "../../../../shared/components/forms/MentionField"
import { IAppState } from "../../../../app/appTypes"
import { getMeetingUsers } from "../../../../shared/selectors/meeting"
import _ from "lodash"
import { getLiveMeetingUserConsent } from "../../../../shared/selectors/liveMeetingConfig/consents"
import { useAppSelector as useSelector } from "../../../../shared/hooks"
import { getCurrentUserId } from "../../../../shared/selectors/user"

interface IProps {
  meetingId: string
}

const LiveMeetingMyConsent: React.FC<IProps> = (props: IProps): JSX.Element => {
  const dispatch = useDispatch()
  const _giveConsent = (response: ConsentResponse, text: string) => dispatch(giveConsentAction(response, text))
  const submitConsent = useCallback(_.debounce(_giveConsent, 1000, { leading: true, maxWait: 1500 }), [])

  const users = useSelector((state: IAppState) => getMeetingUsers(state, props))
  const { t } = useTranslation(["shared", "case"])

  const currentUserId = useSelector(getCurrentUserId)
  const existingConsent = useSelector((state) => getLiveMeetingUserConsent(state, currentUserId))
  const [response, setResponse] = useState<ConsentResponse | undefined>(null)
  const [comment, setComment] = useState<string>(null)
  const [consentFetched, setConsentFetched] = useState<boolean>(false)


  const pending = response == ConsentResponse.PENDING || response == undefined
  const approved = response == ConsentResponse.APPROVE
  const rejected = response == ConsentResponse.REJECT
  const na = response == ConsentResponse.NA

  const baseIconStyle = "fill-current w-6 g-6 lg:w-8 lg:h-8 ml-3"
  const buttonStyle = "group flex cursor-pointer items-center  px-4 py-1 rounded-full m-1 "
  const buttonClasses = (thisResponse: boolean) => classNames(buttonStyle, {
    "bg-ch-blue-alt-500 hover:bg-ch-blue-alt-500 text-ch-gray-200": thisResponse,
    "bg-ch-blue-500 hover:bg-ch-blue-600 text-ch-gray-200": !thisResponse && pending,
    "bg-ch-blue-100 hover:bg-ch-blue-600 hover:text-ch-gray-200": !thisResponse && !pending
  })

  const iconClasses = (thisResponse: boolean) => classNames(baseIconStyle, {
    "text-ch-gray-200": thisResponse,
    "text-ch-gray-200 group-hover:text-current": !thisResponse && pending,
    "text-ch-blue-400 group-hover:text-current": !thisResponse && !pending
  })

  const initialValues = {
    comment: comment ? comment : ""
  }

  useEffect(() => {
    if (!response) return
    submitConsent(response, comment)
  }, [response, comment])

  useEffect(() => {
    if (
      !existingConsent ||
      consentFetched ||
      existingConsent.consentById != currentUserId
    ) return
    setComment(existingConsent.comment)
    setResponse(existingConsent.response)
    setConsentFetched(true)
  }, [existingConsent])

  return (
    <div className="flex flex-col flex-1 p-2 overflow-scroll md:overflow-auto">
      <div className="p-2 rounded-md mt-2">
        <Formik
          initialValues={initialValues}
          onSubmit={(_values, _actions) => null}
          enableReinitialize
        >
          {({
            handleBlur,
            setFieldValue,
            values,
            errors,
            touched
          }) => (
            <Form className="flex-1">
              <div className="mb-2 hidden sm:block">
                <Label name="comment">
                  <span>{t("shared:yourResponse")} ({t("shared:optional")}):</span>
                </Label>

                <MentionField
                  maxLength={1500}
                  name="comment"
                  onBlur={handleBlur}
                  users={users}
                  value={values.comment}
                  setValue={(body: string) => {
                    setComment(body)
                    setFieldValue("comment", body)
                  }}
                  height={100}
                />

                {(pending && comment) ? <div>{t("liveMeeting:consentCommentWithoutVote")}</div> : null}

                <FieldError
                  errorMessage={errors.comment}
                  isVisible={errors.comment && touched.comment}
                />
              </div>
              <div className="flex flex-wrap flex-1 space-x-2 lg:space-x-8 justify-center mb-2">
                <div className={buttonClasses(approved)} onClick={() => setResponse(ConsentResponse.APPROVE)}>
                  {t("case:approveRecommendation")}
                  <IconAccept className={iconClasses(approved)} />
                </div>
                <div className={buttonClasses(rejected)} onClick={() => setResponse(ConsentResponse.REJECT)}>
                  {t("case:rejectRecommendation")}
                  <IconReject className={iconClasses(rejected)} />
                </div>
                <div className={buttonClasses(na)} onClick={() => setResponse(ConsentResponse.NA)} >
                  {t("shared:na")}
                  <IconNa className={iconClasses(na)} />
                </div>
              </div>
            </Form>
          )}
        </Formik >
      </div>
    </div>
  )
}

export default LiveMeetingMyConsent
