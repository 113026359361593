import React from "react"

interface IProps {
  className?: string
  errorMessage?: string
  isVisible?: boolean
}

const FieldError: React.FC<IProps> = (props): JSX.Element => {
  const defaultClasses = ["flex", "text-ch-red-500", "text-sm", "mb-1"]
  const propClasses = props.className ? props.className.split(" ") : []
  const allClasses = [...defaultClasses, ...propClasses].join(" ")

  if (!props.isVisible) return null

  return (
    <span className={allClasses}>
      {props.errorMessage}
    </span>
  )
}

export default FieldError
