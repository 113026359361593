import React from "react"
import UserConsentList from "./UserConsentList"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { CaseStage } from "../../../state/liveMeetingTypes"
import Button from "../../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../../shared/state/sharedTypes"
import { IUser } from "../../../../user/state/userTypes"
import RecommendationArea from "../../shared/RecommendationArea"
import { useSelector } from "react-redux"
import { getSelectedMeetingCaseId } from "../../../../../shared/selectors/liveMeetingConfig/meetingCase"
import { IAppState } from "../../../../../app/appTypes"

interface IPropsFromParent {
  cancelRecommendation: () => void
  users: IUser[]
  requestApproval: () => void
  deferCurrentCase: () => void
  caseStage: CaseStage
  meetingId: string
}
const RecommendationAreaWrapper: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["liveMeeting", "shared"])
  const selectedMeetingCaseId = useSelector(getSelectedMeetingCaseId)
  const recommendations = useSelector((state: IAppState) => state.liveMeetingConfig?.recommendations)
  const currentCaseRecommendation = (recommendations ? recommendations[selectedMeetingCaseId] : "") || ""
  const actionButtonDisabled = (currentCaseRecommendation.length < 1)

  let actionButton: JSX.Element
  let userList: JSX.Element
  if (props.caseStage == CaseStage.DEFERRING) {
    actionButton = (<Button variant={StyleVariants.PURPLE} action={props.deferCurrentCase} isDisabled={actionButtonDisabled}>
      {t("shared:defer")}
    </Button>)
    userList = null
  } else {
    actionButton = <Button variant={StyleVariants.PURPLE} action={props.requestApproval} isDisabled={actionButtonDisabled}>
      {t("shared:requestApproval")}
    </Button>
    userList = <UserConsentList users={props.users} />
  }
  return (
    <div className={classNames(
      "flex",
      "flex-col",
      "flex-1",
      "p-2",
      "overflow-y-auto")}>
      <div
        className={classNames(
          "p-2",
          "rounded-md",
          "mt-2")}>
        <RecommendationArea meetingId={props.meetingId} readOnly={false} />

        <div className="flex flex-wrap flex-1 gap-1 justify-center my-6">
          <Button variant={StyleVariants.BLUE_ALT} action={props.cancelRecommendation}>
            {t("shared:cancel")}
          </Button>
          {actionButton}
        </div>
        {userList}
      </div>
    </div>
  )
}

export default RecommendationAreaWrapper
