import { takeLeading, put, call, Effect } from "redux-saga/effects"
import { normalize } from "normalizr"
import { CaseActionTypes, CaseCableActionTypes } from "../caseTypes"
import API from "../../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import history from "../../../../app/routes/history"
import { RoutePaths } from "../../../../app/routes/Routes"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { fetchCaseSchema } from "../../../../apis/contextmeeting/schema"
import { generatePath } from "react-router"
import { checkCurrentUser, getNormalisedSingle, handleError, handleSuccess, hasByUserId, postNormalisedSingle } from "../../../../shared/helpers/sagaHelpers"
import { fetchCaseAssetsAction } from "../../../assets/state/assetsActions"
import { fetchCaseAction } from "../caseActions"

export function* fetchCaseAsync(action: Effect): Generator {
  try {
    const caseId = action.payload

    const normalizedResponse: any = yield getNormalisedSingle( `/cases/${caseId}`, fetchCaseSchema)
    yield handleSuccess(CaseActionTypes.FETCH_CASE_ASYNC_SUCCESS, normalizedResponse)

  } catch (error) {
    yield handleError(CaseActionTypes.FETCH_CASE_ASYNC_ERROR, error, "caseNotFound")
  }
}

export function* watchFetchCaseAsync(): Generator {
  yield takeLeading(CaseActionTypes.FETCH_CASE_ASYNC_PENDING, fetchCaseAsync)
}

export function* updateCaseAsync(action: Effect): Generator {
  try {
    const data = {
      case: action.payload
    }

    const response: any = yield call(API.patch, `/cases/${action.payload.id}`, data, withAuthHeader())

    yield put({
      type: CaseActionTypes.UPDATE_CASE_ASYNC_SUCCESS,
      payload: response.data
    })

    yield put(displayUserMessageAction({
      messageKey: "updateCaseSuccess",
      type: UserMessageTypes.SUCCESS
    }))

    const route = response.data.permissions.canRead ? RoutePaths.CASE : RoutePaths.CASES
    const caseUrl = generatePath(route, {
      caseId: action.payload.id
    })

    yield history.push(caseUrl)
  } catch (error) {
    yield put({
      type: CaseActionTypes.UPDATE_CASE_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "updateCaseFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchUpdateCaseAsync(): Generator {
  yield takeLeading(CaseActionTypes.UPDATE_CASE_ASYNC_PENDING, updateCaseAsync)
}

export function* updateCaseMembersAsync(action: Effect): Generator {
  try {
    const caseId = action.payload.id
    const data = {
      case: action.payload
    }

    const response: any = yield call(API.patch, `/cases/${action.payload.id}`, data, withAuthHeader())

    const normalizedResponse = normalize(response.data, fetchCaseSchema)

    yield put({
      type: CaseActionTypes.UPDATE_CASE_MEMBERS_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        caseId: caseId
      }
    })

    yield put(displayUserMessageAction({
      messageKey: "updateCaseMembersSuccess",
      type: UserMessageTypes.SUCCESS
    }))

    const route = response.data.permissions.canRead ? RoutePaths.CASE : RoutePaths.CASES
    const caseUrl = generatePath(route, {
      caseId: action.payload.id
    })

    yield history.push(caseUrl)
  } catch (error) {
    yield put({
      type: CaseActionTypes.UPDATE_CASE_MEMBERS_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "updateCaseMembersFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchUpdateCaseMembersAsync(): Generator {
  yield takeLeading(CaseActionTypes.UPDATE_CASE_MEMBERS_ASYNC_PENDING, updateCaseMembersAsync)
}

export function* toggleCaseLockedAsync(action: Effect): Generator {
  try {
    const caseId = action.payload.id
    const url = action.payload.locked ? `/cases/${caseId}/lock` : `/cases/${caseId}/unlock`
    const response: any = yield call(API.post, url, {}, withAuthHeader())
    const normalizedResponse = normalize(response.data, fetchCaseSchema)

    yield put({
      type: CaseActionTypes.TOGGLE_CASE_LOCKED_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

    yield put(displayUserMessageAction({
      messageKey: action.payload.locked ? "lockCaseSuccess" : "unlockCaseSuccess",
      type: UserMessageTypes.SUCCESS
    }))

  } catch (error) {
    yield put({
      type: CaseActionTypes.TOGGLE_CASE_LOCKED_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: action.payload.locked ? "lockCaseFail" : "unlockCaseFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchToggleCaseLockedAsync(): Generator {
  yield takeLeading(CaseActionTypes.TOGGLE_CASE_LOCKED_ASYNC_PENDING, toggleCaseLockedAsync)
}

export function* receiveCaseCableUpdateCaseAssets(action: Effect): Generator {
  if (yield checkCurrentUser(action.payload as hasByUserId)) return
  yield put(fetchCaseAssetsAction(action.payload.caseId))
}

export function* receiveCaseCableUpdateCaseMembers(action: Effect): Generator {
  if (yield checkCurrentUser(action.payload as hasByUserId)) return
  yield put(fetchCaseAction(action.payload.caseId))
}

export function* watchCaseCableActions(): Generator {
  yield takeLeading(CaseCableActionTypes.ASSETS_CHANGED, receiveCaseCableUpdateCaseAssets)
  yield takeLeading(CaseCableActionTypes.MEMBERS_CHANGED, receiveCaseCableUpdateCaseMembers)
}

