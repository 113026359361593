/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react"
import Modal from "react-modal"
import classNames from "classnames"
import _ from "lodash"
import { Mention, MentionsInput, SuggestionDataItem, OnChangeHandlerFunc } from "react-mentions"
import { IUser } from "../../../features/user/state/userTypes"
import { IMentionOption } from "./types"
import { formatUserMentionString } from "../../helpers/stringHelpers"
import ModalContainer from "../modals/ModalContainer"
import { dialogueModalStyle, StyleVariants } from "../../state/sharedTypes"
import Buttons from "../layout/Buttons"
import Button from "../buttons/Button"
import { useTranslation } from "react-i18next"

interface IProps {
  className?: string
  name: string
  value?: string
  type?: string
  autoFocus?: boolean
  autoComplete?: string
  innerRef?: React.RefObject<HTMLInputElement>
  onChange?: OnChangeHandlerFunc
  onBlur: (e: React.FocusEvent<any>) => void
  onFocus?: (e: React.FocusEvent<any>) => void
  onKeyUp?: (event: React.KeyboardEvent) => void
  onKeyDown?: (event: React.KeyboardEvent) => void
  users: { [id: string]: IUser }
  height?: number
  maxLength?: number
  setValue: (body: string) => void
  isDisabled?: boolean
}

const defaultProps = {
  height: 250
}

export const allUser = {
  id: "all",
  firstName: "all",
  lastName: ""
} as IUser

const MentionField: React.FC<IProps> = (props: IProps): JSX.Element => {
  const [mentionOptions, setMentionOptions] = React.useState([])
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const { t } = useTranslation(["shared"])

  const openModal = (): void => {
    setIsOpen(true)
  }

  const closeModal = (): void => {
    setIsOpen(false)
  }

  // assigned to styles object to prevent stylint warning
  const styles = {
    input: {
      overflow: "auto",
      height: props.height,
    },
    highlighter: {
      boxSizing: "border-box",
      overflow: "hidden",
      height: props.height,
    }
  }

  const scrollStyle = styles

  useEffect(() => {
    if (!props.users) return
    const filteredUsers: IUser[] = Object.values(props.users)
      .filter((u) => u.activeInCurrentTenant)
    filteredUsers.unshift(allUser)

    const options: IMentionOption[] = filteredUsers
      .map((user: IUser) => {
        return {
          id: user.id,
          display: formatUserMentionString(user)
        }
      })
    setMentionOptions(options)
  }, [props.users])


  const renderMentionSuggestion = (
    suggestion: SuggestionDataItem,
    _search: string,
    _highlightedDisplay: React.ReactNode,
    index: number,
    focused: boolean
  ) => {
    return (
      <div className={classNames(
        "bg-white",
        "p-2",
        "text-ch-blue-alt-400",
        "border-b",
        "mr-2",
        {
          "bg-ch-blue-alt-200": focused,
          "border-ch-blue-alt-400": (index !== mentionOptions.length - 1)
        }
      )}>
        {suggestion.display}
      </div>
    )
  }

  const prefixClass = "mentions"
  const defaultClasses = [prefixClass, "mb-1", "text-ch-gray-600"]
  const propClasses = props.className ? props.className.split(" ") : []
  const allClasses = [...defaultClasses, ...propClasses].join(" ")

  const renderMentionText = (id, _display): string => {
    if (id == allUser.id) { return formatUserMentionString(allUser) }
    if (!props.users[id]) return ""
    return formatUserMentionString(props.users[id])
  }

  const addHandler = (id: string | number, _display: string) => {
    if (id == allUser.id) {
      openModal()
    }
  }

  const undoAddAll = () => {
    // we need double \\ in the string here because we'll convert from to regex
    const regexString = ` *@\\[@${allUser.firstName}\\]\\(${allUser.id}\\) *`
    const newValue = props.value.replace(new RegExp(regexString, "g"), "")
    props.setValue(newValue)
    closeModal()
  }

  const changeHandler: OnChangeHandlerFunc = props.onChange ? props.onChange : (_e, newValue, _plainTextValue, _mentions) => { props.setValue(newValue) }

  return (
    <div>
      <MentionsInput
        style={scrollStyle}
        className={allClasses}
        autoFocus={props.autoFocus}
        name={props.name}
        id={props.name}
        onChange={changeHandler}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        value={props.value}
        autoComplete={props.autoComplete}
        maxLength={props.maxLength}
        allowSuggestionsAboveCursor={true}
        onKeyDown={props.onKeyDown}
        onKeyUp={props.onKeyUp}
        disabled={props.isDisabled}
      >
        <Mention
          className="bg-ch-blue-alt-200"
          trigger="@"
          markup="@[__display__](__id__)"
          displayTransform={(id, display) => renderMentionText(id, display)}
          data={mentionOptions}
          renderSuggestion={renderMentionSuggestion}
          appendSpaceOnAdd={true}
          onAdd={addHandler}
        />
      </MentionsInput>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={dialogueModalStyle}>
        <ModalContainer handleClose={closeModal}>
          <h2 className="text-lg mb-3">
            {t("mentionAllWarningHeader")}
          </h2>
          <ul className="list-disc ml-6">
            <li>
              {t("mentionAllWarningCase")}
            </li>
            <li>
              {t("mentionAllWarningMeeting")}
            </li>
          </ul>
          <Buttons
            className="mt-6"
            buttons={
              [<Button key={1} variant={StyleVariants.BLUE_ALT} action={undoAddAll}>{t("undo")}</Button>,
              <Button key={2} variant={StyleVariants.PURPLE} action={closeModal}>{t("continue")}</Button>]}
          />
        </ModalContainer>
      </Modal>
    </div>
  )
}

MentionField.defaultProps = defaultProps

export default MentionField
