import { createSelector } from "reselect"
import { IAppState } from "../../../app/appTypes"
import { ITerms } from "../../../features/account/state/accountTypes"
import { ILiveMeetingUserConfig } from "../../../features/liveMeeting/state/liveMeetingTypes"
import { IHaveUserId, IUser, IUserAbilities, IUserSessionState, SignInType } from "../../../features/user/state/userTypes"
import _ from "lodash"
import { isGuest } from "../../helpers/userHelpers"

export const getCurrentUserUserConfig = (state: IAppState): ILiveMeetingUserConfig => state.liveMeetingConfig.userConfigs[state.userSession.id]

export const getUserSession = (state: IAppState): IUserSessionState => state.userSession

export const getUserSessionSignInType = (state: IAppState): SignInType => state.userSession.signInType

export const getCurrentUser = (state: IAppState): IUser => {
  return state.entities.users.byId[state.userSession.id]
}

export const getCurrentUserId = (state: IAppState): string => state.userSession.id

export const getAllUsers = (state: IAppState): { [id: string]: IUser } => state.entities.users.byId

export const getAllUserIds = (state: IAppState): string[] => state.entities.users.allIds

export const getUsersById = (state: IAppState, userIds: string[]): IUser[] => userIds.map((id) => state.entities.users.byId[id])

export const getUserByHasUserId = (state: IAppState, ownProps: IHaveUserId): IUser =>
  getUserById(state, ownProps.userId)

export const getUserById = (state: IAppState, id: string): IUser =>
  state.entities.users.byId[id]

export const getCurrentUserAbilities = createSelector(
  getCurrentUser, (user): IUserAbilities => {
    return user?.abilities
  }
)

export const getCurrentUserWithConfig = createSelector(
  getCurrentUser, getCurrentUserUserConfig,
  (user, currentUserConfig): IUser => {
    return {
      ...user,
      config: currentUserConfig
    }
  }
)

export const getAllActiveUsersSortedFirstNameLastName = createSelector(
  getAllUsers,
  (allUsers): IUser[] => {
    const activeUsers: IUser[] = Object.values(allUsers).filter((u: IUser) => u.activeInCurrentTenant)
    return _.sortBy(activeUsers, ["firstName", "lastName"])
  }
)

export const getAllInternalActiveUsersSorted = createSelector(
  getAllUsers,
  (allUsers): IUser[] => {
    const activeUsers: IUser[] = Object.values(allUsers).filter((u: IUser) => u.activeInCurrentTenant && !isGuest(u))
    return _.sortBy(activeUsers, ["firstName", "lastName"])
  }
)
export const getTerms = (state: IAppState): ITerms => state.userSession.terms
