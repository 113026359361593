import React from "react"
import classNames from "classnames"

interface IPropsFromParent {
  hasDarkBackground?: boolean
  children: JSX.Element[]
}

type Props = IPropsFromParent

const defaultProps = {
  hasDarkBackground: false
}

const LiveMeetingAssetsContainer: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <div className={classNames(
      "flex flex-col flex-1 p-2 overflow-hidden",
      {
        "bg-ch-gray-200": props.hasDarkBackground
      }
    )}>
      { props.children }
    </div>
  )
}

LiveMeetingAssetsContainer.defaultProps = defaultProps

export default LiveMeetingAssetsContainer
