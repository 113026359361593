import React, { useState } from "react"
import { IAppState } from "../../../../app/appTypes"
import { useTranslation } from "react-i18next"
import {
  updateSharedViewSelectedAssetAction,
  deferCurrentCaseAction,
  cancelRecommendationAction,
  shareRecommendationAction,
  requestApprovalAction,
  saveRecommendationAction
} from "../../state/liveMeetingActions"
import { CaseStage } from "../../state/liveMeetingTypes"
import LiveMeetingAssetSelectHeading from "../assets/select/LiveMeetingAssetSelectHeading"
import LiveMeetingAssetList from "../assets/LiveMeetingAssetList"
import { getLiveMeetingSharedViewSelectedAssetId } from "../../../../shared/selectors/liveMeetingConfig/asset"
import LiveMeetingAssetsContainer from "../assets/LiveMeetingAssetsContainer"
import LiveMeetingAssetViewerCloseButton from "../assets/viewer/LiveMeetingAssetViewerCloseButton"
import LiveMeetingSharedViewAssetViewer from "./LiveMeetingSharedViewAssetViewer"
import LiveMeetingSelectedAssetHeading from "../assets/viewer/LiveMeetingSelectedAssetHeading"
import { getMeetingUserIds } from "../../../../shared/selectors/meeting"
import { getUserWithConfigById } from "../../../../shared/selectors/liveMeetingConfig/userConfig"
import RecommendationAreaWrapper from "./consent/RecommendationAreaWrapper"
import ApprovalAreaWrapper from "./consent/ApprovalAreaWrapper"
import ScribeRecommendation from "../shared/ScribeRecommendation"
import { getSelectedMeetingCase } from "../../../../shared/selectors/liveMeetingConfig/meetingCase"
import IAssetTypeFilters from "../../../../shared/types/AssetTypeFilters"
import ListBackground from "../../../../shared/components/layout/ListBackground"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { getComponentTypeForAsset } from "../../../../shared/helpers/assetHelpers"
import { AssetComponentTypes } from "../../../assets/state/assetsTypes"
import { getAssetById } from "../../../../shared/selectors/asset"
import Modal from "react-modal"
import { bottomModalStyle, StyleVariants } from "../../../../shared/state/sharedTypes"
import ModalContainer from "../../../../shared/components/modals/ModalContainer"
import SelectMeetingAssets from "../../../case/components/activityPanel/SelectMeetingAssets"
import Button from "../../../../shared/components/buttons/Button"
import CaseRadiologyViewer from "../../../case/components/assetViewer/CaseRadiologyViewer"
import { toggleWebRTCScreenShareIsOnAction, webRTCScreenShareCurrentAction } from "../../../webRTC/state/webRTCActions"

interface IProps {
  meetingId: string
}

const LiveMeetingSharedViewPresenter: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["liveMeeting", "shared"])
  const dispatch = useDispatch()
  const clearSharedViewSelectedAsset = (assetTypeFilters: IAssetTypeFilters) => () => dispatch(updateSharedViewSelectedAssetAction(null, assetTypeFilters))
  const cancelRecommendation = () => dispatch(cancelRecommendationAction())
  const deferCurrentCase = () => dispatch(deferCurrentCaseAction())
  const shareRecommendation = () => dispatch(shareRecommendationAction())
  const requestApproval = () => dispatch(requestApprovalAction())
  const saveRecommendation = () => dispatch(saveRecommendationAction())
  const [assetTypeFilters, setAssetTypeFilters] = React.useState<IAssetTypeFilters>(null)
  const selectedAssetId = useSelector(getLiveMeetingSharedViewSelectedAssetId)
  const caseStage = useSelector((state: IAppState) => state.liveMeetingConfig.caseStage)
  const users = useSelector((state: IAppState) => getMeetingUserIds(state, props)
    .map((userId: string) => (getUserWithConfigById(state, { userId })))
    .filter((u) => u.activeInCurrentTenant)
  )
  const meetingCase = useSelector(getSelectedMeetingCase)
  const meetingCaseId = meetingCase?.id
  const publishAssetTypeFilters = (filters: IAssetTypeFilters) => {
    setAssetTypeFilters(filters)
    dispatch(updateSharedViewSelectedAssetAction(null, filters))
  }
  const selectedAsset = useSelector((state: IAppState) => getAssetById(state, { assetId: selectedAssetId }))
  const [isSelectAssetModalOpen, setIsSelectAssetModalOpen] = useState(false)

  const assetComponentType = getComponentTypeForAsset(selectedAsset)
  const isOpen = selectedAsset && assetComponentType == AssetComponentTypes.RadiologyLink
  const clearSelectedAsset = () => dispatch(updateSharedViewSelectedAssetAction(null, assetTypeFilters))
  const startSharingScreen = () => dispatch(webRTCScreenShareCurrentAction())
  const stopSharingScreen = () => dispatch(toggleWebRTCScreenShareIsOnAction(false))

  const renderSelectAssetModal = () => {
    if (!meetingCase) { return null }
    return (<Modal isOpen={isSelectAssetModalOpen} onRequestClose={() => setIsSelectAssetModalOpen(false)} style={bottomModalStyle}>
      <ModalContainer handleClose={() => setIsSelectAssetModalOpen(false)}>
        <SelectMeetingAssets
          meetingId={props.meetingId}
          caseId={meetingCase.case}
          meetingCaseId={meetingCaseId}
          openViewAssetModal={() => setIsSelectAssetModalOpen(true)}
        />
      </ModalContainer>
    </Modal>
    )
  }

  const renderRadiologyModal = () => {
    if (!meetingCase) { return null }
    return (<Modal isOpen={isOpen} onAfterOpen={startSharingScreen} onAfterClose={stopSharingScreen} onRequestClose={clearSelectedAsset} style={bottomModalStyle}>
      <ModalContainer handleClose={clearSelectedAsset}>
        <CaseRadiologyViewer assetId={selectedAsset?.id} />
      </ModalContainer>
    </Modal>
    )
  }

  const renderSelectAssetButton = () => {
    return (
      <Button
        className={"hidden sm:block text-sm"}
        variant={StyleVariants.BLUE_ALT}
        action={() => setIsSelectAssetModalOpen(true)}>
        {t("asset:selectMeetingAssets")}
      </Button>
    )
  }

  const renderAssetSelect = (): JSX.Element => {
    if (!meetingCaseId) {
      return (
        <div className="p-2 w-full">
          {t("liveMeeting:yourViewAssetsInstructions")}
          <ListBackground className="-mt-10" />
        </div>
      )
    }
    return (
      <LiveMeetingAssetsContainer hasDarkBackground={true}>
        <div className="flex justify-between mb-2">
          <LiveMeetingAssetSelectHeading
            openText={t("liveMeeting:selectItemToViewAndShare")}
          />
          {renderRadiologyModal()}
          {renderSelectAssetModal()}
          {renderSelectAssetButton()}
        </div>
        <div className="flex-1 overflow-y-scroll pr-4">
          <LiveMeetingAssetList
            selectAction={updateSharedViewSelectedAssetAction}
            assetTypeFilters={assetTypeFilters}
            setAssetTypeFilters={publishAssetTypeFilters} />
        </div>
        <ScribeRecommendation meetingId={props.meetingId} buttonsOnly />
      </LiveMeetingAssetsContainer>
    )
  }

  const assetViewer = (): JSX.Element => (
    <LiveMeetingAssetsContainer>
      <div className="flex justify-end mb-2">
        <LiveMeetingSelectedAssetHeading assetId={selectedAssetId} />
        <LiveMeetingAssetViewerCloseButton clearSelectedAsset={clearSharedViewSelectedAsset(assetTypeFilters)} />
      </div>
      <div className="flex-1 overflow-y-scroll pr-4">
        <LiveMeetingSharedViewAssetViewer assetId={selectedAssetId} />
      </div>
      <ScribeRecommendation meetingId={props.meetingId} buttonsOnly />
    </LiveMeetingAssetsContainer>
  )

  const selectedAssetComponentType = getComponentTypeForAsset(selectedAsset)
  const renderAssetViewer = selectedAssetId &&
    [AssetComponentTypes.Pdf, AssetComponentTypes.Image, AssetComponentTypes.CaseReport].includes(selectedAssetComponentType)

  const decideWhatToRender = (): JSX.Element => {
    switch (caseStage) {
      case CaseStage.PRESENTING: {
        if (renderAssetViewer) { return assetViewer() }
        return renderAssetSelect()
      }
      case CaseStage.DEFERRING:
        return (
          <RecommendationAreaWrapper
            cancelRecommendation={cancelRecommendation}
            users={users}
            requestApproval={requestApproval}
            deferCurrentCase={deferCurrentCase}
            caseStage={caseStage}
            meetingId={props.meetingId}
          />
        )
      case CaseStage.RECOMMENDING:
        return (
          <RecommendationAreaWrapper
            cancelRecommendation={cancelRecommendation}
            users={users}
            requestApproval={requestApproval}
            deferCurrentCase={deferCurrentCase}
            caseStage={caseStage}
            meetingId={props.meetingId}
          />
        )
      case CaseStage.APPROVING:
        return <ApprovalAreaWrapper
          users={users}
          saveRecommendation={saveRecommendation}
          shareRecommendation={shareRecommendation}
        />
    }
  }

  return (
    <div className="flex flex-1 overflow-hidden">
      {decideWhatToRender()}
    </div>
  )
}

export default LiveMeetingSharedViewPresenter
