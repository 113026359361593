import { all } from "redux-saga/effects"
import { liveMeetingWatchers } from "../../features/liveMeeting/state/liveMeetingSagas"
import { watchUserConfigActions } from "../../features/userConfig/state/userConfigSagas"
import { watchFetchTenantConfigAsync } from "../../features/tenantConfig/state/tenantConfigSagas"
import { watchDeleteAssetsAsync, watchFetchCaseAssetsAsync } from "../../features/assets/state/sagas/assetsSagas"
import { watchFetchMeetingsAsync, watchFetchMeetingsInDateRangeAsync } from "../../features/meetings/state/meetingsSagas"
import { watchDeleteMeetingCase, watchFetchMeetingCaseAsync, watchReceiveMeetingCaseCableUpdateCaseAssets } from "../../features/meetingCase/state/sagas/meetingCaseSagas"
import { watchStartMeetingAsync } from "../../features/meeting/state/sagas/startMeetingSagas"
import { watchConnectToWebRTCSessionAsync, watchDisconnectFromWebRTCSessionAsync } from "../../features/webRTC/state/sagas/webRTCSessionSagas"
import { watchPublishToWebRTCSessionAsync, watchUnpublishFromWebRTCSession } from "../../features/webRTC/state/sagas/webRTCPublisherSagas"
import { watchPublishScreenToWebRTCSessionAsync, watchStopPublishScreenToWebRTCSessionAsync } from "../../features/webRTC/state/sagas/webRTCPublisherScreenSagas"
import { watchSubscribeToWebRTCStreamAsync } from "../../features/webRTC/state/sagas/webRTCStreamSagas"
import { watchGetWebRTCAudioDevicesAsync, watchGetWebRTCAudioSourceAsync, watchSetWebRTCAudioSourceAsync, watchToggleWebRTCPublishAudioAsync } from "../../features/webRTC/state/sagas/webRTCPublisherAudioSagas"
import { watchCycleWebRTCVideoSourceAsync, watchToggleWebRTCPublishVideoAsync } from "../../features/webRTC/state/sagas/webRTCPublisherVideoSagas"
import { watchFetchSpecialtiesAsync } from "../../features/specialty/state/specialtySagas"
import { watchSignInUserAsync, watchSignOutUserAsync, watchUserSignInTypeAsync } from "../../features/account/state/sagas/signInSagas"
import { watchSetPasswordAsync, watchSendResetPasswordLinkAsync } from "../../features/account/state/sagas/passwordSagas"
import { watchFetchAllUsers, watchFetchUsersAsync } from "../../features/users/state/usersSagas"
import { watchUserActions } from "../../features/user/state/sagas/userSagas"
import { watchFetchCaseAsync, watchUpdateCaseAsync, watchUpdateCaseMembersAsync, watchToggleCaseLockedAsync, watchCaseCableActions } from "../../features/case/state/sagas/caseSagas"
import { meetingChannelWatchers, meetingWatchers } from "../../features/meeting/state/sagas/meetingSagas"
import { watchCreateMeetingUser, watchUpdateMeetingUser, watchDestroyMeetingUser } from "../../features/meetingUser/state/sagas/meetingUserSagas"
import { watchFetchCaseClassificationAsync } from "../../features/caseClassification/state/caseClassificationSagas"
import { watchFetchPatientsAsync, watchFetchAllPatientsAsync, watchUploadPatientsCSVAsync } from "../../features/patients/state/patientsSagas"
import { watchFetchCasesAsync } from "../../features/cases/state/casesSagas"
import { watchCreateCommentAsync } from "../../features/assets/state/sagas/commentSagas"
import { watchUploadFileAsync } from "../../features/assets/state/sagas/fileSagas"
import { watchCreateMeetingCase } from "../../features/meetingCase/state/sagas/meetingCaseSagas"
import { watchFetchNotificationsAsync } from "../../features/notifications/state/notificationsSagas"
import { watchAcceptTermsAsync, watchFetchTermsAsync } from "../../features/account/state/sagas/termsSagas"
import { watchUploadTermsFileAsync } from "../../features/admin/state/sagas/adminTermsSagas"
import { watchCreateLinkAsync } from "../../features/assets/state/sagas/linkSagas"
import { watchCreateRecommendationAsync, watchSaveCaseRecommendationAsync } from "../../features/assets/state/sagas/recommendationSagas"
import { watchAddConsentAsync } from "../../features/assets/state/sagas/addConsentSagas"
import { watchUploadTenantLogoAsync } from "../../features/admin/state/sagas/adminTenantLogoSagas"
import { watchFetchCasesAddableToMeetingsAsync } from "../../features/addCaseToMeeting/state/addCaseToMeetingSagas"
import { watchFetchTeamsAsync, watchFetchAllTeamsAsync } from "../../features/teams/state/teamsSagas"
import { watchCreateTaskAsync, watchFetchCaseTasksAsync, watchDeleteCaseTaskAsync } from "../../features/tasks/state/sagas/caseTasksSagas"
import { watchCreateTaskResponseAsync } from "../../features/taskResponse/state/sagas/caseTaskResponseSagas"
import { watchFetchTasksAsync } from "../../features/tasks/state/sagas/tasksSagas"
import { watchFetchPatientAsync } from "../../features/patient/state/patientSagas"
import { watchCheckPatientAsync, watchCreatePatientAsync } from "../../features/patientNew/state/sagas/patientNewSagas"
import { watchAddCaseForPatientAsync, watchUpdatePatientAsync } from "../../features/patientEdit/state/sagas/patientEditSagas"
import { teamWatchers } from "../../features/team/state/sagas/teamSagas"
import { watchCreateTeamUser, watchDestroyTeamUser } from "../../features/teamUser/state/sagas/teamUserSagas"
import { meetingCaseAssetWatchers } from "../../features/meetingCaseAssets/state/sagas/meetingCaseAssetsSagas"
import { watchCreateTenantAsync } from "../../features/tenant/state/sagas/tenantNewSagas"
import { watchFetchChatsAsync } from "../../features/chats/state/chatsSagas"
import { watchFetchChatMessagesAsync } from "../../features/messages/state/messagesSagas"
import { watchCreateChatMessageAsync } from "../../features/message/state/sagas/messageSagas"
import { watchCreateChatAsync } from "../../features/chatNew/state/sagas/chatNewSagas"
import { watchFetchChatAsync } from "../../features/chat/state/sagas/chatSagas"
import { watchFetchAuditEventsAsync } from "../../features/audit/state/auditEventSagas"
import { watchEndChatAsync } from "../../features/chat/state/sagas/endChatSagas"
import { watchToggleMutedChatAsync } from "../../features/chat/state/sagas/toggleMuteChatSagas"
import { watchFetchPathways } from "../../features/pathways/state/pathwaysSagas"
import { watchFetchPathwayAsync } from "../../features/pathway/state/sagas/fetchPathwaySagas"
import { watchCreatePathwayAsync } from "../../features/pathway/state/sagas/createPathwaySagas"
import { watchUpdatePathwayAsync } from "../../features/pathway/state/sagas/updatePathwaySagas"
import { watchCreatePathwayStepAsync } from "../../features/pathway/state/sagas/createPathwayStepSagas"
import { watchUpdatePathwayStepAsync } from "../../features/pathway/state/sagas/updatePathwayStepSagas"
import { watchDeletePathwayStepAsync } from "../../features/pathway/state/sagas/deletePathwayStepSagas"
import { watchAddPathwayUsersAsync } from "../../features/pathway/state/sagas/addPathwayUsersSagas"
import { watchRemovePathwayUserAsync } from "../../features/pathway/state/sagas/removePathwayUserSagas"
import { watchFetchChatAssetsAsync } from "../../features/chat/state/sagas/fetchChatAssetsSagas"
import { watchExportChatAsync } from "../../features/chat/state/sagas/exportChatSagas"
import { watchFetchPathwaysAddableToCase } from "../../features/addPathwayToCase/state/sagas/fetchPathwaysAddableToCaseSagas"
import { watchCreateCasePathwayAsync, watchFetchCasePathwaysAsync, watchUpdateCasePathwayAsync, watchUpdateCasePathwayTaskAsync } from "../../features/casePathways/state/sagas/casePathwaysSagas"
import { watchDeletePathwayAsync } from "../../features/pathway/state/sagas/deletePathwaySagas"
import { watchFetchCaseSourceAsync } from "../../features/sources/state/caseSourceSagas"
import { watchCreateCaseGuestUserAsync } from "../../features/case/state/sagas/caseGuestUserSagas"
import { watchFetchCaseAssetsCanBeSharedWithUser } from "../../features/caseSharedAssets/state/sagas/fetchCaseAssetsCanBeSharedWithUserSagas"
import { watchCreateCaseSharedAssetAsync } from "../../features/caseSharedAssets/state/sagas/createCaseSharedAssetSagas"
import { watchDeleteCaseSharedAssetAsync } from "../../features/caseSharedAssets/state/sagas/deleteCaseSharedAssetSagas"
import { watchCheckUserAsync } from "../../features/user/state/sagas/checkUserSagas"
import { watchAnalyticsActions } from "../../features/analytics/state/analyticsSagas"
import { mfaWatchers } from "../../features/mfa/state/mfaSagas"
import { watchAppActions } from "./appSagas"

export default function* appSaga(): Generator {
  yield all([
    watchAppActions(),
    watchSignInUserAsync(),
    watchSignOutUserAsync(),
    watchUserSignInTypeAsync(),
    watchSetPasswordAsync(),
    watchSendResetPasswordLinkAsync(),
    watchFetchTermsAsync(),
    watchAcceptTermsAsync(),
    watchFetchAllUsers(),
    watchFetchUsersAsync(),
    watchUserActions(),
    watchUpdateCaseAsync(),
    watchToggleCaseLockedAsync(),
    watchCaseCableActions(),
    watchDeleteAssetsAsync(),
    watchUpdateCaseMembersAsync(),
    meetingWatchers(),
    watchFetchMeetingCaseAsync(),
    watchDeleteMeetingCase(),
    watchStartMeetingAsync(),
    watchFetchMeetingsAsync(),
    watchFetchMeetingsInDateRangeAsync(),
    watchCreateMeetingUser(),
    watchUpdateMeetingUser(),
    watchDestroyMeetingUser(),
    watchFetchCaseAsync(),
    watchFetchCasesAsync(),
    watchFetchCasesAddableToMeetingsAsync(),
    liveMeetingWatchers(),
    watchUserConfigActions(),
    watchFetchTenantConfigAsync(),
    watchFetchCaseAssetsAsync(),
    watchFetchCaseTasksAsync(),
    watchDeleteCaseTaskAsync(),
    watchCreateTaskAsync(),
    watchCreateTaskResponseAsync(),
    watchFetchTasksAsync(),
    watchCreateCommentAsync(),
    watchCreateRecommendationAsync(),
    watchSaveCaseRecommendationAsync(),
    watchAddConsentAsync(),
    watchUploadFileAsync(),
    watchCreateLinkAsync(),
    watchConnectToWebRTCSessionAsync(),
    watchDisconnectFromWebRTCSessionAsync(),
    watchPublishToWebRTCSessionAsync(),
    watchPublishScreenToWebRTCSessionAsync(),
    watchStopPublishScreenToWebRTCSessionAsync(),
    watchUnpublishFromWebRTCSession(),
    watchSubscribeToWebRTCStreamAsync(),
    watchToggleWebRTCPublishAudioAsync(),
    watchToggleWebRTCPublishVideoAsync(),
    watchCycleWebRTCVideoSourceAsync(),
    watchSetWebRTCAudioSourceAsync(),
    watchGetWebRTCAudioDevicesAsync(),
    watchGetWebRTCAudioSourceAsync(),
    watchFetchSpecialtiesAsync(),
    watchFetchCaseClassificationAsync(),
    watchFetchPatientsAsync(),
    watchFetchAllPatientsAsync(),
    watchUploadPatientsCSVAsync(),
    watchFetchPatientAsync(),
    watchCheckPatientAsync(),
    watchCreatePatientAsync(),
    watchUpdatePatientAsync(),
    watchAddCaseForPatientAsync(),
    watchCreateMeetingCase(),
    watchFetchNotificationsAsync(),
    watchUploadTermsFileAsync(),
    watchUploadTenantLogoAsync(),
    teamWatchers(),
    watchFetchTeamsAsync(),
    watchCreateTeamUser(),
    watchDestroyTeamUser(),
    watchFetchAllTeamsAsync(),
    meetingCaseAssetWatchers(),
    watchCreateTenantAsync(),
    watchFetchChatsAsync(),
    watchFetchChatAsync(),
    watchCreateChatAsync(),
    watchFetchChatMessagesAsync(),
    watchCreateChatMessageAsync(),
    watchEndChatAsync(),
    watchToggleMutedChatAsync(),
    watchFetchChatAssetsAsync(),
    watchExportChatAsync(),
    watchFetchPathways(),
    watchFetchPathwayAsync(),
    watchCreatePathwayAsync(),
    watchUpdatePathwayAsync(),
    watchDeletePathwayAsync(),
    watchCreatePathwayStepAsync(),
    watchUpdatePathwayStepAsync(),
    watchDeletePathwayStepAsync(),
    watchAddPathwayUsersAsync(),
    watchRemovePathwayUserAsync(),
    watchFetchPathwaysAddableToCase(),
    watchFetchCasePathwaysAsync(),
    watchCreateCasePathwayAsync(),
    watchUpdateCasePathwayAsync(),
    watchUpdateCasePathwayTaskAsync(),
    watchFetchAuditEventsAsync(),
    watchFetchCaseSourceAsync(),
    watchReceiveMeetingCaseCableUpdateCaseAssets(),
    meetingChannelWatchers(),
    watchCreateCaseGuestUserAsync(),
    watchFetchCaseAssetsCanBeSharedWithUser(),
    watchCreateCaseSharedAssetAsync(),
    watchDeleteCaseSharedAssetAsync(),
    watchCheckUserAsync(),
    watchAnalyticsActions(),
    mfaWatchers()
  ])
}
