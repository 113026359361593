import React from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../../shared/components/buttons/Button"
import { useAppDispatch, useAppSelector } from "../../../../shared/hooks"
import { getUserByHasUserId } from "../../../../shared/selectors/user"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { addUserToCurrentTenantAction } from "../../state/userActions"

interface Props {
  userId: string
  createAsGuest?: boolean
  closeModal?: () => void
}

const AddUserToTenantButton: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "user"])
  const user = useAppSelector(state => getUserByHasUserId(state, props))
  const dispatch = useAppDispatch()
  const translationKey = props.createAsGuest ? "user:userCheck:buttons:addGuestToTenant" : "user:userCheck:buttons:addUserToTenant"

  const addGuestUserToCaseMembers = () => {
    const type = props.createAsGuest ? "guest" : "participant"
    dispatch(addUserToCurrentTenantAction(props.userId, type))
    if (props.closeModal) props.closeModal()
  }

  if (user.existsInCurrentTenant) {
    return (<div>{t("user:userCheck:text:userAlreadyExistsInTenant")}</div>)
  }

  return (
    <Button
      action={addGuestUserToCaseMembers}
      variant={StyleVariants.ORANGE}
      key={props.userId}
    >
      {t(translationKey)}
    </Button>
  )
}

export default AddUserToTenantButton
