import { Formik } from "formik"
import _ from "lodash"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../../../../app/appTypes"
import { getAllUsers } from "../../../../../shared/selectors/user"
import { IGuestUserNewData, IGuestUserNewFormData } from "../../../../user/state/userTypes"
import { fetchAllUsersAsync } from "../../../../users/state/usersActions"
import { parsePhoneNumber } from "react-phone-number-input"
import "react-phone-number-input/style.css"
import { caseGuestUserNewValidationSchema } from "../../../../../shared/state/sharedValidations"
import { createCaseGuestUser } from "../../../../user/state/userActions"
import GuestUserFormContent from "../../../../userNew/components/GuestUserFormContent"

interface Props {
  caseId: string
  closeModal: () => void
}

const CaseGuestUserNewForm: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch()
  const allUsers = useSelector((state: IAppState) => getAllUsers(state))

  useEffect(() => {
    dispatch(fetchAllUsersAsync())
  }, [])

  const initialValues: IGuestUserNewFormData = {
    title: "",
    professionalTitle: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    specialtyIds: [],
  }

  const usersEmail = _.map(allUsers, "email")

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={caseGuestUserNewValidationSchema(usersEmail)}
      validateOnMount
      onSubmit={async (values) => {
        const phoneNumberData = parsePhoneNumber(values.phoneNumber)
        const data: IGuestUserNewData = {
          ...values,
          phoneNumber: phoneNumberData.nationalNumber,
          phoneCountryCode: phoneNumberData.countryCallingCode
        }
        dispatch(createCaseGuestUser(props.caseId, data))
        props.closeModal()
      }}
    >
      <>
        <div>HI</div>
        <GuestUserFormContent {...props} />
      </>
    </Formik>
  )
}

export default CaseGuestUserNewForm
