import React from "react"
import { useSelector } from "react-redux"
import _ from "lodash"
import { IAppState } from "../../../../app/appTypes"
import { IconMeeting } from "../../../../svg/icons"
import { getMeetingById } from "../../../../shared/selectors/meeting"
import TimeDefaultFormat from "../../../../shared/components/time/TimeDefaultFormat"

interface IProps {
  meetingId: string;
}

const CaseMeetingUserMeetingListItem: React.FC<IProps> = (props): JSX.Element => {
  const meeting = useSelector((state: IAppState) => getMeetingById(state, props))
  if (!meeting) return null


  const displaySpecialty = " - " + meeting.specialtyName + " - "

  return (
    <li className="flex flex-row">
      <div>
        <IconMeeting className="fill-current text-ch-purple-500 w-6 h-6 mr-2" title={meeting.name} />
      </div>
      <div className="w-full">
        <span className="font-semibold">{meeting.name}</span>{displaySpecialty}
        <TimeDefaultFormat utcTime={meeting.startDate} />
      </div>
    </li>
  )
}

export default CaseMeetingUserMeetingListItem
