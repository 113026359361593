import { Form, Formik } from "formik"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../../../shared/components/buttons/Button"
import DenotesRequiredMessage from "../../../../../shared/components/forms/DenotesRequiredMessage"
import FieldError from "../../../../../shared/components/forms/FieldError"
import InputField from "../../../../../shared/components/forms/InputField"
import Label from "../../../../../shared/components/forms/Label"
import Buttons from "../../../../../shared/components/layout/Buttons"
import { StyleVariants } from "../../../../../shared/state/sharedTypes"
import { IconLink } from "../../../../../svg/icons"
import { createCaseRadiologyLinkAction, fetchPatientRadiologyLinksAction } from "../../../../assets/state/assetsActions"
import { IRadiologyLinkNewData } from "../../../../assets/state/assetsTypes"
import { useParams } from "react-router"
import { IHaveMeetingCaseId } from "../../../../meetingCase/state/meetingCaseTypes"
import { useAppDispatch, useAppSelector } from "../../../../../shared/hooks"
import { caseRadiologyLinkNewValidation } from "../../../state/validation/caseRadiologyLinkNewValidation"
import { ISelectFieldOption } from "../../../../../shared/components/forms/types"
import SelectField from "../../../../../shared/components/forms/SelectField"
import LoadingSpinner from "../../../../../shared/components/ui/LoadingSpinner"

interface IProps {
  caseId: string
  closeAction: () => void
  switchToNewAssetTab: () => void
}

const CaseRadiologyLinkNewForm: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["shared", "case", "radiology"])
  const { meetingCaseId } = useParams<IHaveMeetingCaseId>()
  const radiologyLinkIds = useAppSelector(state => state.entities.patientRadiologyLinks.allIds)
  const radiologyLinks = useAppSelector(state => state.entities.patientRadiologyLinks.byId)
  const fetching = useAppSelector(state => state.ui.patientRadiologyLink.fetching)
  const dispatch = useAppDispatch()
  const createLink = (caseId: string, link: IRadiologyLinkNewData, meetingCaseId: string) => {
    dispatch(createCaseRadiologyLinkAction(caseId, link, meetingCaseId))
  }

  const [patientLinkOptions, setPatientLinkOptions] = useState<ISelectFieldOption[]>()

  const initialValues: IRadiologyLinkNewData = {
    title: "",
    fileUuid: "",
    idFile: null,
    dicomName: "",
    meetingCaseId: meetingCaseId
  }

  useEffect(() => {
    dispatch(fetchPatientRadiologyLinksAction(props.caseId))
  }, [])

  useEffect(() => {
    const options = radiologyLinkIds.map((id) => {
      const link = radiologyLinks[id]
      return {
        value: id,
        label: link.name
      }
    })
    setPatientLinkOptions(options)
  }, [radiologyLinkIds])

  if (fetching) { return <LoadingSpinner /> }

  if (radiologyLinkIds?.length < 1) {
    return <div>
      <div className="flex flex-1 justify-center mt-3 mb-5 font-semibold">
        {t("radiology:text:noStudiesFound")}
      </div>
      <Buttons
        className="mb-2"
        buttons={[
          <Button
            action={() => { props.closeAction() }}
            variant={StyleVariants.BLUE_ALT}
            key="1"
          >
            {t("shared:back")}
          </Button>
        ]}
      />
    </div>
  }

  return (
    <div>
      <h4 className="flex mb-2">
        <IconLink className="w-6 h-6 mr-2 text-ch-purple-500" title={t("case:addNewRadiologyLink")} />
        {t("case:addNewDicomLink")}
      </h4>

      <Formik
        initialValues={initialValues}
        validationSchema={caseRadiologyLinkNewValidation}
        onSubmit={(values, { setSubmitting }) => {
          createLink(props.caseId, values, meetingCaseId)
          setSubmitting(true)
          props.closeAction()
          props.switchToNewAssetTab()
        }}
      >
        {({
          isSubmitting,
          errors,
          values,
          touched,
          handleChange,
          handleBlur,
          setFieldValue
        }) => {
          return (
            <Form className="w-full">
              <DenotesRequiredMessage />
              <div className="mb-5">
                <Label name="fileUuid" required={true}>
                  {t("asset:study")}
                </Label>
                <SelectField
                  name="fileUuid"
                  options={patientLinkOptions}
                  onChange={(e) => {
                    handleChange(e)
                    setFieldValue("title", radiologyLinks[e.currentTarget.value].name)
                    setFieldValue("dicomName", radiologyLinks[e.currentTarget.value].name)
                    setFieldValue("idFile", radiologyLinks[e.currentTarget.value].idFile)
                  }}
                  onBlur={handleBlur}
                />
                <FieldError errorMessage={errors.title as string} isVisible={(errors.title && touched.title) as boolean} />
              </div>

              <div className="mb-5">
                <Label name="title" required={true}>
                  {t("asset:title")}
                </Label>
                <InputField
                  type="text"
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  autoComplete="off"
                />
                <FieldError errorMessage={errors.title as string} isVisible={(errors.title && touched.title) as boolean} />
              </div>

              <Buttons
                className="mb-2"
                buttons={[
                  <Button
                    action={() => { props.closeAction() }}
                    variant={StyleVariants.BLUE_ALT}
                    key="1"
                  >
                    {t("shared:cancel")}
                  </Button>,
                  <Button
                    isDisabled={isSubmitting}
                    variant={StyleVariants.PURPLE}
                    isSubmit={true}
                    key="2"
                  >
                    {t("case:addLink")}
                  </Button>
                ]}
              />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default CaseRadiologyLinkNewForm
