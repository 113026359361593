import React, { ReactNode } from "react"
import classNames from "classnames"
import { IAppState } from "../../../../app/appTypes"
import { MockStore } from "redux-mock-store"
import { IDocumentAsset } from "../../state/assetsTypes"
import { getAssetById } from "../../../../shared/selectors/asset"
import { bytesToSize } from "../../../../shared/helpers/fileHelpers"
import ImageThumb from "../../../../shared/components/images/ImageThumb"
import { IMeetingCaseAsset } from "../../../meetingCase/state/meetingCaseTypes"
import { IMessageDocumentAsset } from "../../../message/state/messageTypes"
import AssetSubHeader from "./AssetSubHeading"
import { useSelector } from "react-redux"

interface Props {
  store?: MockStore
  assetId: string
  action?: () => void
  isSelected?: boolean
  meetingCaseAsset?: IMeetingCaseAsset
  meetingId?: string
  children?: ReactNode
}

const Image: React.FC<Props> = (props: Props): JSX.Element => {
  const asset = useSelector((state: IAppState) => getAssetById(state, props))
  const image = asset.asset as IDocumentAsset | IMessageDocumentAsset

  return (
    <div
      onClick={props.action ? () => props.action() : null}
      className={classNames(
        "bg-white p-2 flex rounded-md border-2",
        {
          "border-ch-blue-200": !props.isSelected,
          "border-ch-blue-500": props.isSelected,
          "hover:border-ch-blue-500": props.action,
          "cursor-pointer": props.action,
          "cursor-default": !props.action,
        }
      )}
    >
      <div className="w-16 h-16 p-1 flex items-center justify-center mr-2 overflow-hidden">
        <ImageThumb path={image.path} title={image.name} />
      </div>

      <div className="flex-1 min-w-0">
        <h4 className="font-semibold text-lg">
          {`${image.name} (${bytesToSize(image.bytes)})`}
        </h4>
        <AssetSubHeader
          meetingCaseAsset={props.meetingCaseAsset}
          meetingId={props.meetingId}
          asset={asset}
        />
      </div>
      {props.children}
    </div>
  )
}

export default Image
