import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { MockStore } from "redux-mock-store"
import history from "../../../../app/routes/history"
import { IAppState } from "../../../../app/appTypes"
import { getCaseById } from "../../../../shared/selectors/case"
import Buttons from "../../../../shared/components/layout/Buttons"
import Button from "../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import CaseCommentNewForm from "./forms/comments/CaseCommentNewForm"
import { IconComments, IconFiles } from "../../../../svg/icons"
import CaseDecisionNewForm from "./forms/comments/CaseDecisionNewForm"
import RecommendationNewForm from "./forms/recommendation/RecommendationNewForm"
import CaseLinkNewForm from "./forms/CaseLinkNewForm"
import CaseFileNewForm from "./forms/CaseFileNewForm"
import { IUser } from "../../../user/state/userTypes"
import ListBackground from "../../../../shared/components/layout/ListBackground"
import { CaseAssetTabNames } from "../activityPanel/CaseActivityPanel"
import { getCurrentUser } from "../../../../shared/selectors/user"
import { isGuest } from "../../../../shared/helpers/userHelpers"
import CaseRadiologyLinkNewForm from "./forms/CaseRadiologyLinkNewForm"
import { getTenantConfig } from "../../../../shared/selectors/tenantConfig"

interface IProps {
  store?: MockStore
  caseId: string
  meetingId?: string // meeting case page only
  mentionableUsers: { [id: string]: IUser }
}

export enum Tabs {
  CommentNew = "comment_new",
  DecisionNew = "decision_new",
  RecommendationNew = "recommendation_new",
  RadiologyLinkNew = "radiology_link_new",
  LinkNew = "link_new",
  UploadFile = "upload_file"
}

const AddCaseAssetPanel: React.FC<IProps> = (props) => {
  const { t } = useTranslation(["shared", "case"])
  const currentUser = useSelector(getCurrentUser)
  const [currentTab, setCurrentTab] = useState(null)
  const theCase = useSelector((state: IAppState) => getCaseById(state, props))
  const { radiologyEnabled } = useSelector(getTenantConfig)

  const switchToNewAssetTab = () => {
    if (props.meetingId) {
      history.replace(CaseAssetTabNames.MEETING_ASSETS)
    } else {
      history.replace(CaseAssetTabNames.ASSETS)
    }
  }

  const renderTabContent = (): JSX.Element => {
    switch (currentTab) {
      case Tabs.CommentNew:
        return <CaseCommentNewForm
          caseId={props.caseId}
          closeAction={() => setCurrentTab(null)}
          mentionableUsers={props.mentionableUsers}
          switchToNewAssetTab={switchToNewAssetTab}
        />
      case Tabs.DecisionNew:
        return <CaseDecisionNewForm
          caseId={props.caseId}
          closeAction={() => setCurrentTab(null)}
          mentionableUsers={props.mentionableUsers}
          switchToNewAssetTab={switchToNewAssetTab}
        />
      case Tabs.RecommendationNew:
        return <RecommendationNewForm
          caseId={props.caseId}
          closeAction={() => setCurrentTab(null)}
          mentionableUsers={props.mentionableUsers}
          switchToNewAssetTab={switchToNewAssetTab}
        />
      case Tabs.LinkNew:
        return <CaseLinkNewForm
          caseId={props.caseId}
          closeAction={() => setCurrentTab(null)}
          switchToNewAssetTab={switchToNewAssetTab}
        />
      case Tabs.RadiologyLinkNew:
        return <CaseRadiologyLinkNewForm
          caseId={props.caseId}
          closeAction={() => setCurrentTab(null)}
          switchToNewAssetTab={switchToNewAssetTab}
        />
      case Tabs.UploadFile:
        return <CaseFileNewForm
          caseId={props.caseId}
          closeAction={() => setCurrentTab(null)}
          switchToNewAssetTab={switchToNewAssetTab}
        />

      default:
        return null
    }
  }

  const renderButtons = (): JSX.Element => {
    if (currentTab !== null) return null

    if (theCase.locked) return (
      <div>
        <h3 className="text-center text-ch-blue-alt-400">
          {t("case:addAssetsLocked")}
        </h3>
        <ListBackground />
      </div>
    )

    const commentButtons = []

    if (!isGuest(currentUser)) commentButtons.push(
      <Button
        action={() => setCurrentTab(Tabs.CommentNew)}
        variant={StyleVariants.BLUE_ALT}
        key="1"
      >
        {t("case:addComment")}
      </Button>,
      <Button
        action={() => setCurrentTab(Tabs.DecisionNew)}
        variant={StyleVariants.BLUE_ALT}
        key="2"
      >
        {t("case:addDecision")}
      </Button>,
      <Button
        action={() => setCurrentTab(Tabs.RecommendationNew)}
        variant={StyleVariants.BLUE_ALT}
        key="3"
      >
        {t("case:addRecommendation")}
      </Button>
    )

    const fileButtons = [
      <Button
        action={() => setCurrentTab(Tabs.UploadFile)}
        variant={StyleVariants.BLUE}
        key="1"
      >
        {t("case:uploadNewFile")}
      </Button>
    ]

    if (!isGuest(currentUser)) fileButtons.push(
      <Button
        action={() => setCurrentTab(Tabs.LinkNew)}
        variant={StyleVariants.BLUE}
        key="2"
      >
        {t("case:addNewLink")}
      </Button>
    )

    if (radiologyEnabled && !isGuest(currentUser)) fileButtons.push(
      <Button
        action={() => setCurrentTab(Tabs.RadiologyLinkNew)}
        variant={StyleVariants.BLUE}
        key="2"
      >
        {t("case:addNewRadiologyLink")}
      </Button>
    )

    return (
      <div>
        {commentButtons.length > 0 ?
          <h3 className="flex mb-2">
            <IconComments className="w-6 h-6 mr-2 text-ch-purple-500" title={t("case:commentsTitle")} />
            {t("case:commentsTitle")}
          </h3>
          : null}

        <Buttons
          className="mb-2"
          buttons={commentButtons}
        />

        {fileButtons.length > 0 ?
          <h3 className="flex mb-2">
            <IconFiles className="w-6 h-6 mr-2 text-ch-purple-500" title={t("case:filesTitle")} />
            {t("case:filesTitle")}
          </h3>
          : null}

        <Buttons
          buttons={fileButtons}
        />
      </div>
    )
  }

  return (
    <div>
      {renderButtons()}
      {renderTabContent()}
    </div>
  )
}

export default AddCaseAssetPanel
