import React, { useEffect, useState } from "react"
import { Form, Formik } from "formik"
import _ from "lodash"
import humps from "humps"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import Button from "../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import Label from "../../../shared/components/forms/Label"
import InputField from "../../../shared/components/forms/InputField"
import FieldError from "../../../shared/components/forms/FieldError"
import { IPathwayNewData } from "../../pathway/pathwayTypes"
import { getAllInternalActiveUsersSorted } from "../../../shared/selectors/user"
import { TSelectFieldOptions } from "../../../shared/components/forms/StandardSelectField"
import { pathwayNewValidationSchema } from "./pathwayValidationSchema"
import DenotesRequiredMessage from "../../../shared/components/forms/DenotesRequiredMessage"
import SelectField from "../../../shared/components/forms/SelectField"
import { createPathwayAction } from "../../pathway/pathwayActions"
import { buildUserOptions, filterOptionsBySearchValue } from "../../../shared/components/label/UserOption"
import { generateCaseClassificationOptions } from "../../../shared/helpers/selectOptionHelpers"
import { getAllCaseClassifications } from "../../../shared/selectors/caseClassification"
import { CaseTypes } from "../../case/state/caseTypes"
import { fetchCaseClassificationAction } from "../../caseClassification/state/caseClassificationActions"
import { fetchAllUsersAsync } from "../../users/state/usersActions"

const PathwayNewForm = (): JSX.Element => {
  const dispatch = useDispatch()
  const { t } = useTranslation(["pathway"])
  const [userOptions, setUserOptions] = useState([])
  const [caseClassificationOptions, setCaseClassificationOptions] = useState([])
  const users = useSelector(getAllInternalActiveUsersSorted)
  const classifications = useSelector(getAllCaseClassifications)
  const [caseType, setCaseType] = useState<string>(null)

  const initialValues: IPathwayNewData = {
    name: "",
    description: "",
    caseClassificationId: "",
    caseType: null,
    userIds: []
  }

  const setCaseClassificationOptionsForCaseType = (caseType: string) => {
    const options: TSelectFieldOptions = generateCaseClassificationOptions(classifications, caseType)
    setCaseClassificationOptions(options)
  }

  const caseTypeOptions: TSelectFieldOptions = Object.keys(CaseTypes)
    .filter((key) => key != "SuspectedCancer")
    .map((key) => {
      const translationKey = humps.camelize(CaseTypes[key])
      return {
        value: CaseTypes[key],
        label: t(`case:${translationKey}`)
      }
    })

  const onChangeCaseType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const currentCaseType = e.currentTarget.value
    setCaseType(currentCaseType)
    setCaseClassificationOptionsForCaseType(currentCaseType)
  }


  useEffect(() => {
    dispatch(fetchAllUsersAsync())
    dispatch(fetchCaseClassificationAction())
  }, [])

  useEffect(() => {
    const options: TSelectFieldOptions = buildUserOptions(Object.values(users))
    setUserOptions(options)
  }, [users])

  useEffect(() => {
    if (!caseType) return
    setCaseClassificationOptionsForCaseType(caseType)
  }, [classifications])


  return (
    <div className="flex justify-left lg:pl-4 sm:pl-0">
      <Formik
        initialValues={initialValues}
        validationSchema={pathwayNewValidationSchema}
        validateOnMount
        onSubmit={(values, { setSubmitting }) => {
          const data = pathwayNewValidationSchema().cast(values)
          dispatch(createPathwayAction(data))
          setSubmitting(false)
        }}
      >
        {({
          isSubmitting,
          errors,
          values,
          touched,
          handleChange,
          handleBlur,
          isValid
        }) => {
          return (
            <Form className="w-full lg:w-2/3">
              <DenotesRequiredMessage />

              <div className="mb-2">
                <Label name="name" required>
                  {t("pathway:name")}
                </Label>
                <InputField
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  autoComplete="off"
                  autoFocus={true}
                />
                <FieldError
                  errorMessage={errors.name as string}
                  isVisible={(errors.name && touched.name) as boolean}
                />
              </div>


              <div className="md:flex">
                <div className="mb-2 md:w-1/3">
                  <Label name="caseType" required>
                    {t("pathway:caseType")}
                  </Label>

                  <SelectField
                    name="caseType"
                    onChange={onChangeCaseType}
                    onBlur={handleBlur}
                    options={caseTypeOptions}
                  />

                  <FieldError
                    errorMessage={errors.caseType}
                    isVisible={errors.caseType && touched.caseType}
                  />
                </div>

                <div className="flex-1 mb-2 md:ml-2">
                  <Label name="caseClassificationId" required>
                    {t("shared:classification")}
                  </Label>

                  <SelectField
                    name="caseClassificationId"
                    options={caseClassificationOptions}
                    isDisabled={!values.caseType}
                    isGrouped={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <FieldError
                    errorMessage={errors.caseClassificationId as string}
                    isVisible={
                      errors.caseClassificationId &&
                      touched.caseClassificationId
                    }
                  />
                </div>
              </div>

              <div className="mb-2">
                <Label name="description" required>
                  {t("pathway:summary")}
                </Label>
                <InputField
                  type="text"
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  autoComplete="off"
                  as="textarea"
                  className={"h-32"}
                />
                <FieldError
                  errorMessage={errors.description as string}
                  isVisible={(errors.description && touched.description) as boolean}
                />
              </div>

              <div className="mb-2">
                <Label name="userIds">
                  {t("pathway:collaborators")}
                </Label>
                <SelectField
                  name="userIds"
                  options={userOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  filterOption={filterOptionsBySearchValue}
                />
                <FieldError
                  errorMessage={errors.userIds as string}
                  isVisible={(errors.userIds && touched.userIds) as boolean}
                />
              </div>

              <div className="flex justify-center">
                <Button
                  isDisabled={isSubmitting || !isValid}
                  variant={StyleVariants.PURPLE}
                  isSubmit={true}
                >
                  {t("pathway:new:createPathway")}

                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default PathwayNewForm
