import React, { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { debounce } from "lodash"
import Label from "../../../../shared/components/forms/Label"
import { updateRecommendationAction } from "../../state/liveMeetingActions"
import RecommendationInputField from "./RecommendationInputField"
import { getSelectedMeetingCase } from "../../../../shared/selectors/liveMeetingConfig/meetingCase"
import { getLiveMeetingConfig } from "../../../../shared/selectors/liveMeetingConfig"

interface IProps {
  meetingId: string
  headerKey: string
}


const RecommendationForm: React.FC<IProps> = (props: IProps): JSX.Element => {
  const dispatch = useDispatch()
  const { t } = useTranslation(["shared"])
  const _updateRecommendation = (text: string, caseId: string) => dispatch(updateRecommendationAction(text, caseId))
  const throttled = useCallback(debounce(_updateRecommendation, 1000, { maxWait: 2000 }), [])
  const updateRecommendation = (text: string, caseId: string) => throttled(text, caseId)
  const selectedMeetingCase = useSelector(getSelectedMeetingCase)
  const recommendations = useSelector(getLiveMeetingConfig).recommendations
  const currentCaseRecommendation = recommendations ? recommendations[selectedMeetingCase.id] : "" || ""

  const handleUpdateRecommendation = (text: string) => {
    updateRecommendation(text, selectedMeetingCase.id)
  }

  const headerKey = props.headerKey

  const initialValues = {
    recommendation: currentCaseRecommendation
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={null}
    >
      {({
        setFieldValue,
        handleChange,
        handleBlur
      }) => {
        return (
          <Form className="flex-1">
            <div className="mb-2">
              <Label name="recommendation" className="mb-2" required>
                <span>{t(headerKey)}:</span>
              </Label>
              <RecommendationInputField
                meetingId={props.meetingId}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleUpdateRecommendation={handleUpdateRecommendation}
                setFieldValue={setFieldValue}
                serverText={currentCaseRecommendation || ""}
              />
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default RecommendationForm
