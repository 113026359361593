import OT from "@opentok/client"
import { RefObject } from "react"

// State
export interface IWebRTCState {
  session: OT.Session
  publisher: OT.Publisher
  screenShare: IWebRTCScreenShareState
  streams: IWebRTCStreamState,
  audioDevices: IWebRTCDeviceState,
  errors: {
    mediaError: string
  }
}

export interface IWebRTCScreenShareState {
  isOn: boolean
  preferCurrentTab: boolean
  publisher: OT.Publisher
  stream: OT.Stream
}

export type TWebRTCStreamByUserIdState = { [id: string]: OT.Stream }

export interface IWebRTCStreamState {
  byUserId: TWebRTCStreamByUserIdState
}

export interface IWebRTCErrorState {
  mediaError: string
}

export type TWebRTCDeviceByIdState = { [id: string]: OT.Device }
export type TWebRTCDeviceAllIdsState = string[]

export interface IWebRTCDeviceState {
  selectedId: string
  byId: TWebRTCDeviceByIdState
  allIds: TWebRTCDeviceAllIdsState
}

export interface IHaveDeviceId {
  deviceId: string
}

// Actions
export interface IConnectToWebRTCSessionAsyncAction {
  type: string
  payload: {
    apiKey: string
    sessionId: string
    meetingToken: string
    eventHandlers: sessionEventHandlers
  }
}

export interface IDisconnectFromWebRTCSessionAsyncAction {
  type: string
  payload: {
    session: OT.Session
  }
}

export interface IUpdateWebRTCSessionAction {
  type: string
  payload: {
    session: OT.Session
  }
}

export interface IPublishToWebRTCSessionAction {
  type: string
  payload: {
    session: OT.Session
    containerRef: RefObject<HTMLDivElement>
    options: OT.PublisherProperties
    eventHandlers: publisherEventHandlers
  }
}

export interface IStopPublishToWebRTCSessionAction {
  type: string
  payload: {
    session: OT.Session
    publisher: OT.Publisher
  }
}

export interface IUpdateWebRTCPublisherAction {
  type: string
  payload: {
    publisher: OT.Publisher
  }
}

export interface IUnpublishFromWebRTCSessionAction {
  type: string
  payload: {
    session: OT.Session
    publisher: OT.Publisher
  }
}


export interface IAddWebRTCStreamAction {
  type: string
  payload: {
    userId: string,
    stream: OT.Stream
  }
}

export interface IRemoveWebRTCStreamAction {
  type: string
  payload: {
    userId: string
  }
}

export interface IUpdateWebRTCScreenStreamAction {
  type: string
  payload: OT.Stream
}

export interface ISubscribeToWebRTCStreamAction {
  type: string
  payload: {
    session: OT.Session
    stream: OT.Stream
    containerRef: RefObject<HTMLDivElement>
    options: OT.SubscriberProperties
  }
}

export interface IToggleWebRTCPublishAudioAction {
  type: string
  payload: {
    userId: string
    publisher: OT.Publisher
    isSharingAudio: boolean
  }
}

export interface IToggleWebRTCPublishVideoAction {
  type: string
  payload: {
    userId: string
    publisher: OT.Publisher
    isSharingVideo: boolean
  }
}

export interface IToggleWebRTCPublishScreenAction {
  type: string
  payload: {
    userId: string
    screenPublisher: OT.Publisher
    isSharingScreen: boolean
  }
}

export interface IToggleWebRTCScreenShareIsOnAction {
  type: string
  payload: {
    isSharingScreen: boolean,
    preferCurrentTab?: boolean
  }
}

export interface IGetAudioDevicesAction {
  type: string
}

export interface ISetAudioSourceAction {
  type: string
  payload: {
    deviceId: string
    publisher: OT.Publisher
  }
}

export interface IGetAudioSourceAction {
  type: string
  payload: {
    publisher: OT.Publisher
  }
}

export interface IGetVideoDevicesAction {
  type: string
}

export interface ICycleVideoSourceAction {
  type: string
  payload: {
    publisher: OT.Publisher
  }
}

// Action Types
export enum WebRTCActionTypes {
  CONNECT_TO_WEB_RTC_SESSION_ASYNC_PENDING = "@@webRTC/CONNECT_TO_WEB_RTC_SESSION_ASYNC_PENDING",
  CONNECT_TO_WEB_RTC_SESSION_ASYNC_SUCCESS = "@@webRTC/CONNECT_TO_WEB_RTC_SESSION_ASYNC_SUCCESS",
  CONNECT_TO_WEB_RTC_SESSION_ASYNC_ERROR = "@@webRTC/CONNECT_TO_WEB_RTC_SESSION_ASYNC_ERROR",

  DISCONNECT_FROM_WEB_RTC_SESSION_ASYNC_PENDING = "@@webRTC/DISCONNECT_FROM_WEB_RTC_SESSION_ASYNC_PENDING",
  DISCONNECT_FROM_WEB_RTC_SESSION_ASYNC_SUCCESS = "@@webRTC/DISCONNECT_FROM_WEB_RTC_SESSION_ASYNC_SUCCESS",
  DISCONNECT_FROM_WEB_RTC_SESSION_ASYNC_ERROR = "@@webRTC/DISCONNECT_FROM_WEB_RTC_SESSION_ASYNC_ERROR",

  UPDATE_WEB_RTC_SESSION = "@@webRTC/UPDATE_WEB_RTC_SESSION",

  PUBLISH_TO_WEB_RTC_SESSION_ASYNC_PENDING = "@@webRTC/PUBLISH_TO_WEB_RTC_SESSION_ASYNC_PENDING",
  PUBLISH_TO_WEB_RTC_SESSION_ASYNC_SUCCESS = "@@webRTC/PUBLISH_TO_WEB_RTC_SESSION_ASYNC_SUCCESS",
  PUBLISH_TO_WEB_RTC_SESSION_ASYNC_ERROR = "@@webRTC/PUBLISH_TO_WEB_RTC_SESSION_ASYNC_ERROR",

  PUBLISH_SCREEN_TO_WEB_RTC_SESSION_ASYNC_PENDING = "@@webRTC/PUBLISH_SCREEN_TO_WEB_RTC_SESSION_ASYNC_PENDING",
  PUBLISH_SCREEN_TO_WEB_RTC_SESSION_ASYNC_SUCCESS = "@@webRTC/PUBLISH_SCREEN_TO_WEB_RTC_SESSION_ASYNC_SUCCESS",
  PUBLISH_SCREEN_TO_WEB_RTC_SESSION_ASYNC_ERROR = "@@webRTC/PUBLISH_SCREEN_TO_WEB_RTC_SESSION_ASYNC_ERROR",

  STOP_PUBLISH_SCREEN_TO_WEB_RTC_SESSION_ASYNC_PENDING = "@@webRTC/STOP_PUBLISH_SCREEN_TO_WEB_RTC_SESSION_ASYNC_PENDING",
  STOP_PUBLISH_SCREEN_TO_WEB_RTC_SESSION_ASYNC_SUCCESS = "@@webRTC/STOP_PUBLISH_SCREEN_TO_WEB_RTC_SESSION_ASYNC_SUCCESS",
  STOP_PUBLISH_SCREEN_TO_WEB_RTC_SESSION_ASYNC_ERROR = "@@webRTC/STOP_PUBLISH_SCREEN_TO_WEB_RTC_SESSION_ASYNC_ERROR",

  UNPUBLISH_FROM_WEB_RTC_SESSION_PENDING = "@@webRTC/UNPUBLISH_FROM_WEB_RTC_SESSION_PENDING",
  UNPUBLISH_FROM_WEB_RTC_SESSION_SUCCESS = "@@webRTC/UNPUBLISH_FROM_WEB_RTC_SESSION_SUCCESS",
  UNPUBLISH_FROM_WEB_RTC_SESSION_ERROR = "@@webRTC/UNPUBLISH_FROM_WEB_RTC_SESSION_ERROR",

  UPDATE_WEB_RTC_PUBLISHER = "@@webRTC/UPDATE_WEB_RTC_PUBLISHER",

  SUBSCRIBE_TO_WEB_RTC_STREAM_ASYNC_PENDING = "@@webRTC/SUBSCRIBE_TO_WEB_RTC_STREAM_ASYNC_PENDING",
  SUBSCRIBE_TO_WEB_RTC_STREAM_ASYNC_SUCCESS = "@@webRTC/SUBSCRIBE_TO_WEB_RTC_STREAM_ASYNC_SUCCESS",
  SUBSCRIBE_TO_WEB_RTC_STREAM_ASYNC_ERROR = "@@webRTC/SUBSCRIBE_TO_WEB_RTC_STREAM_ASYNC_ERROR",

  TOGGLE_WEB_RTC_PUBLISHER_AUDIO_PENDING = "@@webRTC/TOGGLE_WEB_RTC_PUBLISHER_AUDIO_PENDING",
  TOGGLE_WEB_RTC_PUBLISHER_AUDIO_SUCCESS = "@@webRTC/TOGGLE_WEB_RTC_PUBLISHER_AUDIO_SUCCESS",
  TOGGLE_WEB_RTC_PUBLISHER_AUDIO_ERROR = "@@webRTC/TOGGLE_WEB_RTC_PUBLISHER_AUDIO_ERROR",

  TOGGLE_WEB_RTC_PUBLISHER_VIDEO_PENDING = "@@webRTC/TOGGLE_WEB_RTC_PUBLISHER_VIDEO_PENDING",
  TOGGLE_WEB_RTC_PUBLISHER_VIDEO_SUCCESS = "@@webRTC/TOGGLE_WEB_RTC_PUBLISHER_VIDEO_SUCCESS",
  TOGGLE_WEB_RTC_PUBLISHER_VIDEO_ERROR = "@@webRTC/TOGGLE_WEB_RTC_PUBLISHER_VIDEO_ERROR",

  TOGGLE_WEB_RTC_SCREEN_SHARING_IS_ON = "@@webRTC/TOGGLE_WEB_RTC_SCREEN_SHARING_IS_ON",

  ADD_WEB_RTC_STREAM = "@@webRTC/ADD_WEB_RTC_STREAM",
  REMOVE_WEB_RTC_STREAM = "@@webRTC/REMOVE_WEB_RTC_STREAM",
  UPDATE_WEB_RTC_STREAM = "@@webRTC/UPDATE_WEB_RTC_STREAM",

  UPDATE_WEB_RTC_SCREEN_STREAM = "@@webRTC/UPDATE_WEB_RTC_SCREEN_STREAM",

  GET_WEB_RTC_AUDIO_DEVICES_PENDING = "@@webRTC/GET_WEB_RTC_AUDIO_DEVICES_PENDING",
  GET_WEB_RTC_AUDIO_DEVICES_SUCCESS = "@@webRTC/GET_WEB_RTC_AUDIO_DEVICES_SUCCESS",
  GET_WEB_RTC_AUDIO_DEVICES_ERROR = "@@webRTC/GET_WEB_RTC_AUDIO_DEVICES_ERROR",

  GET_WEB_RTC_VIDEO_DEVICES_PENDING = "@@webRTC/GET_WEB_RTC_VIDEO_DEVICES_PENDING",
  GET_WEB_RTC_VIDEO_DEVICES_SUCCESS = "@@webRTC/GET_WEB_RTC_VIDEO_DEVICES_SUCCESS",
  GET_WEB_RTC_VIDEO_DEVICES_ERROR = "@@webRTC/GET_WEB_RTC_VIDEO_DEVICES_ERROR",

  SET_WEB_RTC_AUDIO_SOURCE_PENDING = "@@webRTC/SET_WEB_RTC_AUDIO_SOURCE_PENDING",
  SET_WEB_RTC_AUDIO_SOURCE_SUCCESS = "@@webRTC/SET_WEB_RTC_AUDIO_SOURCE_SUCCESS",
  SET_WEB_RTC_AUDIO_SOURCE_ERROR = "@@webRTC/SET_WEB_RTC_AUDIO_SOURCE_ERROR",

  GET_WEB_RTC_AUDIO_SOURCE_PENDING = "@@webRTC/GET_WEB_RTC_AUDIO_SOURCE_PENDING",
  GET_WEB_RTC_AUDIO_SOURCE_SUCCESS = "@@webRTC/GET_WEB_RTC_AUDIO_SOURCE_SUCCESS",
  GET_WEB_RTC_AUDIO_SOURCE_ERROR = "@@webRTC/GET_WEB_RTC_AUDIO_SOURCE_ERROR",

  CYCLE_WEB_RTC_VIDEO_SOURCE_PENDING = "@@webRTC/CYCLE_WEB_RTC_VIDEO_SOURCE_PENDING",
  CYCLE_WEB_RTC_VIDEO_SOURCE_SUCCESS = "@@webRTC/CYCLE_WEB_RTC_VIDEO_SOURCE_SUCCESS",
  CYCLE_WEB_RTC_VIDEO_SOURCE_ERROR = "@@webRTC/CYCLE_WEB_RTC_VIDEO_SOURCE_ERROR",
}

// Event Handlers
export type webRTCEvent = {
  stream?: OT.Stream
  session?: OT.Session
  publisher?: OT.Publisher
  reason?: string
  target?: OT.Session | OT.Publisher | OT.Stream
  preventDefault: () => void
}

export type webRTCStreamPropertyChangedEvent = {
  stream: OT.Stream
  changedProperty: string
  newValue: boolean
  oldValue: boolean
}

export interface sessionEventHandlers {
  streamCreated: (event: webRTCEvent) => void
  streamDestroyed: (event: webRTCEvent) => void
  streamPropertyChanged: (event: webRTCStreamPropertyChangedEvent) => void
  sessionReconnecting: (event: webRTCEvent) => void
  sessionReconnected: (event: webRTCEvent) => void
  sessionDisconnected: (event: webRTCEvent) => void
}

export interface publisherEventHandlers {
  streamCreated: (event: webRTCEvent) => void
  streamDestroyed: (event: webRTCEvent) => void
  accessDenied: (event: webRTCEvent) => void
  destroyed?: (event: webRTCEvent) => void
  mediaStopped?: (event: webRTCEvent) => void
}
