import OT, { Subscriber, Event, Publisher } from "@opentok/client"
import _ from "lodash"
import { RefObject } from "react"
import { store } from "../../app/state/store"
import { LiveMeetingActionTypes } from "../../features/liveMeeting/state/liveMeetingTypes"
import { sessionEventHandlers, publisherEventHandlers } from "../../features/webRTC/state/webRTCTypes"

type AudioLevelUpdatedEvent = Event<"audioLevelUpdated", Publisher | Subscriber> & {
  audioLevel: number
}

export const connectToSession = (apiKey: string, sessionId: string, meetingToken: string, eventHandlers: sessionEventHandlers): Promise<OT.Session> => {
  return new Promise((resolve, reject) => {
    if (OT.checkSystemRequirements() != 1) {
      const error: OT.OTError = {
        name: "Session Error",
        message: "The client does not support WebRTC"
      }
      reject(error)
    }

    const session = OT.initSession(apiKey, sessionId)

    session.on(eventHandlers)

    session.connect(meetingToken, (error: OT.OTError | undefined) => {
      if (error) reject(error)
      resolve(session)
    })
  })
}

export const disconnectFromSession = (session: OT.Session): void => {
  session.disconnect()
}

export const unpublishFromSession = (session: OT.Session, publisher: OT.Publisher): void => {
  session.unpublish(publisher)
}

export const publishToSession = (session: OT.Session, containerRef: RefObject<HTMLDivElement>, options: OT.PublisherProperties, eventHandlers: publisherEventHandlers): Promise<OT.Publisher> => {
  return new Promise((resolve, reject) => {
    if (session.capabilities.publish != 1) {
      const error: OT.OTError = {
        name: "Publish Error",
        message: "Session does not have capability to publish"
      }
      reject(error)
    }

    const element: HTMLElement = containerRef.current as HTMLElement
    const publisher = OT.initPublisher(element, options, (error: OT.OTError | undefined) => {
      if (error) reject(error)

      publisher.on(eventHandlers)

      session.publish(publisher, (error: OT.OTError | undefined) => {
        if (error) {
          reject(error)
        }
        resolve(publisher)
      })
    })
    monitorWhosIspeaking(publisher)
  })
}

export const publishScreenToSession = (session: OT.Session, containerRef: RefObject<HTMLDivElement>, options: OT.PublisherProperties, eventHandlers: publisherEventHandlers): Promise<OT.Publisher> => {
  return new Promise((resolve, reject) => {
    OT.checkScreenSharingCapability((response) => {
      if (!response.supported) {
        const error: OT.OTError = {
          name: "Publish Screen Error",
          message: "Session does not have capability to publish screen"
        }
        reject(error)
      } else {
        resolve(publishToSession(session, containerRef, options, eventHandlers))
      }
    })
  })
}

export const stopPublishScreenToSession = (session: OT.Session, publisher: OT.Publisher): OT.Publisher => {
  session.unpublish(publisher)
  publisher.destroy()
  return publisher
}

export const togglePublisherAudio = (publisher: OT.Publisher, isSharingAudio: boolean): void => {
  publisher.publishAudio(isSharingAudio)
}

export const togglePublisherVideo = (publisher: OT.Publisher, isSharingVideo: boolean): void => {
  publisher.publishVideo(isSharingVideo)
}

export const subscribeToStream = (session: OT.Session, stream: OT.Stream, containerRef: RefObject<HTMLDivElement>, options: OT.SubscriberProperties): Promise<OT.Subscriber> => {
  return new Promise((resolve, reject) => {
    const element: HTMLElement = containerRef.current as HTMLElement
    const subscriber = session.subscribe(stream, element, options, (error: OT.OTError | undefined) => {
      if (error) {
        reject(error)
      }
      resolve(subscriber)
    })
    monitorWhosIspeaking(subscriber)
  })
}

export const monitorWhosIspeaking = (target: Publisher | Subscriber) => {
  let movingAvg = null
  let speaking = false
  const handler = _.throttle((event: Event<string, object>) => {
    const _event = event as AudioLevelUpdatedEvent
    const userId = getStreamUserId(_event.target.stream)
    if (movingAvg === null || movingAvg <= _event.audioLevel) {
      movingAvg = _event.audioLevel
    } else {
      movingAvg = 0.7 * movingAvg + 0.3 * _event.audioLevel
    }
    const speakingNow = (movingAvg > 0.02)
    if (speakingNow != speaking) {
      store.dispatch({
        type: LiveMeetingActionTypes.USER_SPEAKING,
        payload: {
          userId,
          speakingNow
        }
      })
    }
    speaking = speakingNow
  }, 300)
  target.on("audioLevelUpdated", handler)
}

export const getStreamUserId = (stream: OT.Stream): string | null => {
  if (!stream) return null
  return stream.connection.data.split(",").map((item) => {
    if (item.startsWith("user_id=")) {
      return item.replace("user_id=", "")
    }
  }).join("")
}

export const getDevicesOfType = (type: string): Promise<OT.Device[]> => {
  return new Promise((resolve, reject) => {
    OT.getDevices((error: OT.OTError | undefined, devices: OT.Device[] = []) => {
      if (error) {
        reject(error)
      }
      const audioDevices: OT.Device[] = devices.filter((device) => {
        return device.kind === type
      })
      resolve(audioDevices)
    })
  })
}

export const getAudioDevices = (): Promise<OT.Device[]> => {
  return getDevicesOfType("audioInput")
}

export const setAudioSource = (publisher: OT.Publisher, deviceId: string): void => {
  publisher.setAudioSource(deviceId)
}

export const getAudioSource = async (publisher: OT.Publisher): Promise<OT.Device | undefined> => {
  const source: MediaStreamTrack = publisher.getAudioSource()
  const allAudioDevices: OT.Device[] = await getAudioDevices()
  return allAudioDevices.find(device => {
    return device.label === source.label
  })
}

export const cycleVideoSource = (publisher: OT.Publisher): Promise<{ deviceId: string }> => {
  return publisher.cycleVideo()
}
